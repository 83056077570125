export const fetchRecentCircular=(dispatcher,data)=>{

    dispatcher(recentCircularListDispatch(data))


}

export const recentCircularListDispatch=data=>{
    return{
        type:'RECENT_CIRCULAR_LIST',
        data:data
    }
}