import React, {useRef, useState} from 'react'
import Nav from "./Nav";
import Options from "./Options"
import Images from "./Images"
import Circulars from "./Circulars"
import Footer from "./Footer"
import {useDispatch, useSelector} from "react-redux";
import {validateEmail} from "../../util";
import {showToast} from "../../App";
import {login, register} from "../../action/applicant/auth";

const Homepage = props => {


    return (
        <>
            <Nav/>
            <Options applicantAuth={'applicantAuth' in props}/>
           
            <Circulars/>
            {/* <Footer/> */}
        </>
    )
}

export default Homepage;
