import React,{useState} from 'react'
import Drawer from "@material-ui/core/Drawer"
import Typography from "@material-ui/core/Typography"
import {makeStyles} from "@material-ui/core"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import ListItemText from "@material-ui/core/ListItemText"
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import Divider from '@material-ui/core/Divider';
import Button from "@material-ui/core/Button";
import {logout} from "../action/admin/auth"
import {useDispatch} from 'react-redux'
import { fetchScrutineerList } from "../action/admin/scrutineerList";
import { fetchCircularList } from '../action/admin/circular/circularList'
import { fetchPostList } from '../action/admin/post/postList'
import { fetchApplicationList } from '../action/admin/application/applicationList'
import Visibility from '@material-ui/icons/Visibility';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Collapse from '@material-ui/core/Collapse';
import SettingsIcon from '@material-ui/icons/Settings';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import img from "../assets/buet_logo.png"
const DrawerList = (props) => {


    const useStyles=makeStyles((theme) => ({
        drawer:{
            width:220
        },
        nested: {
            paddingLeft: theme.spacing(4),
          },
        paper:{
            width:220
        },
        list:{
            marginTop:80
        },
        litext:{
            marginLeft:20
        },
        text:{
            marginLeft:30,
            marginTop:8,
        },
        img:{
width:80 ,
height:80,
marginLeft:70,
marginTop:20

        }
       
     

    }))

    const dispatch=useDispatch()
    const [open,setOpen]=useState(false);
    const classes=useStyles()
    const handleClick=()=>{

        props.setState(3);
        fetchScrutineerList(dispatch)

    }
    return ( 
        <div>
            <a id="home" href="/"></a>
        <Drawer
        className={classes.drawer}
        variant="permanent"
        anchor="left"
        classes={{paper:classes.paper}}
        >
            <div>
            <img src={img} className={classes.img}/>

                <Typography
                variant="h5"
                className={classes.text}
                >
                Buet Job Portal
                    
                </Typography>

                <List className={classes.list}>

                 
                 <Divider/> 
                 <ListItem selected={props.state===2}  onClick={()=>{props.setState(2);fetchCircularList(dispatch)}} button>
                 
                 <FormatListBulletedOutlinedIcon color="primary"/><ListItemText className={classes.litext}>Manage Circular</ListItemText>
                 </ListItem>
              <Divider/>
           

              <ListItem selected={props.state===4}  onClick={()=>{props.setState(4);fetchCircularList(dispatch);fetchPostList(dispatch)}} button>
                 
                 < FormatListBulletedOutlinedIcon color="primary"/><ListItemText className={classes.litext}>Manage Post</ListItemText>
                 </ListItem>
                 <Divider/>
                 <ListItem selected={props.state===5}  onClick={()=>{props.setState(5);fetchCircularList(dispatch);fetchPostList(dispatch);fetchApplicationList(dispatch)}} button>
                 
                 <SaveAltIcon color="primary"/><ListItemText className={classes.litext}>Download Applicants(Paid)</ListItemText>
                 </ListItem>
                 <Divider/>
                    <ListItem selected={props.state===12}  onClick={()=>{props.setState(12);fetchCircularList(dispatch);fetchPostList(dispatch);fetchApplicationList(dispatch)}} button>
                 
                 <SaveAltIcon color="primary"/><ListItemText className={classes.litext}>Download Applicants(Selected)</ListItemText>
                 </ListItem>
                 <Divider/>
                 <ListItem selected={props.state===9}  onClick={()=>{props.setState(9);fetchCircularList(dispatch);fetchPostList(dispatch);fetchApplicationList(dispatch)}} button>
                 
                 < Visibility color="primary"/><ListItemText className={classes.litext}>View/Manage Applicants</ListItemText>
                 </ListItem>
                 <Divider/>
         <ListItem button onClick={()=>setOpen(!open)}>
        <ListItemIcon>
        <SettingsIcon color="primary"/>
        </ListItemIcon>
        <ListItemText primary="Settings" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <VpnKeyIcon color="primary"/>
            </ListItemIcon>
            <ListItemText primary="Password" selected={props.state===6} onClick={()=>{props.setState(6);console.log(10)}} />
          </ListItem>
          </List>
          </Collapse>

                </List>


                <center>
                                <Button startIcon={<ExitToAppOutlinedIcon/>} style={{marginTop:'10px'}} onClick={()=>{logout(dispatch);document.getElementById("home").click()}} color='primary' variant='outlined'>
                                    Sign Out
                                </Button>
                            </center>

            </div>
 
 
        </Drawer>
        
        
      
        </div>
         );
}
 
export default DrawerList;