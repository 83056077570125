import React, {createRef, useEffect, useRef} from 'react'
import {Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Certificate from "./Certificate";
import {useDispatch, useSelector} from "react-redux";
import {fetchProfile, updateProfile} from "../action/applicant/profile";
import {fetchEdu, updateEdu} from "../action/applicant/edu";
import Button from "@material-ui/core/Button";

const Education= props=>{

    const applicantEdu=useSelector(state=>state.applicantEdu)
    const dispatch=useDispatch()

    var eduRefs=[useRef(),useRef(),useRef(),useRef(),useRef(),useRef(),useRef()]


    useEffect(()=>{
        if(applicantEdu===null)
            fetchEdu(dispatch)
    },[])



    // useEffect(()=>{
    //     console.log(applicantEdu)
    // },[applicantEdu])

    const getEduData=type=>{
        var data={
            type:type,
            subject:null,
            institute:null,
            board:null,
            year:null,
            grade:null,
            file:null
        }
        applicantEdu.map(edu=>{
            if(edu.type===type)
                data=edu
        })
        return data
    }

    const isDynamicEdu=type=>{
        const preTypes=['JSC','SSC','HSC','DPL','BSC','MSC','PhD']
        return !preTypes.includes(type)
    }

    const saveClick=()=>{
        var data=[]
        eduRefs.map(eduRef=>{
            data.push(eduRef.current.getData())
        })
        updateEdu(dispatch, {
            data:data
        }).then(res=>{
            eduRefs.map(r=>{
                r.current.saveDone()
            })
        })
    }

    return(
        <div>
            {
                applicantEdu===null?(
                    <div/>
                ):(
                    <Paper style={{padding:'20px'}}>
                        <div style={{fontSize:'1.5em',marginBottom:'10px'}}>
                            Education
                        </div>
                        <div style={{position:'absolute',right:'20px',top:'73px'}}>
                            <Button
                                style={{zIndex:1000}}
                                variant='contained'
                                color={'primary'}
                                onClick={saveClick}
                            >
                                Save Changes
                            </Button>
                        </div>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Certificate data={getEduData} ref={eduRefs[0]} type={"JSC"} title={'JSC/Equivalent'}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Certificate  data={getEduData} ref={eduRefs[1]} type={"SSC"} title={'SSC/Equivalent'}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Certificate  data={getEduData} ref={eduRefs[2]} type={"HSC"} title={'HSC/Equivalent'}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Certificate  data={getEduData} ref={eduRefs[3]} type={"DPL"} title={'Diploma/Equivalent'}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Certificate  data={getEduData} ref={eduRefs[4]} type={"BSC"} title={'BSc/Equivalent'}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Certificate  data={getEduData} ref={eduRefs[5]} type={"MSC"} title={'MSc/Equivalent'}/>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Certificate  data={getEduData} ref={eduRefs[6]} type={"PhD"} title={'PhD/Equivalent'}/>
                            </Grid>



                        </Grid>
                    </Paper>
                )
            }
        </div>
    )
}

export default Education
