import axios from 'axios'
import Cookies from 'universal-cookie';
import {api_base_url} from "../../index";
import { fetchScrutineerList } from "./scrutineerList";
const cookies = new Cookies();

export const deleteScrutineer =(id,dispatcher,callBack)=>{

    dispatcher(loadingDispatch())

    axios.delete(api_base_url+'/admin/scrutiny/delete/'+id,{headers:{authorization:'Bearer '+cookies.get('admin_token')}}).then(res=>{  
       dispatcher(deleteScrutinyDispatch());
       callBack("Successfully deleted the scrutineer");
       fetchScrutineerList(dispatcher);
    }).catch(err=>{
        if(err.response!==undefined){
        switch(err.response.status){
            case 404:
                callBack('Scrutineer does not exist!')
                break
        
            case 500:
                callBack('Internal server error')
                break
            default:
                callBack('Unknown error');
                break;
        }}
        else{
            callBack('Unknown error');
        }
        dispatcher(deletionFailedDispatch());

    })


}

const loadingDispatch=()=>{
    return {
        type:'DELETION_LOADING'
    }
}
const deleteScrutinyDispatch=()=>{
     return {
        type:'SCRUTINY_DELETED'
    }
}

const deletionFailedDispatch=()=>{
    return {
        type:'SCRUTINY_NOT_DELETED'
    }
}
