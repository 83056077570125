import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Grid} from "@material-ui/core";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import {
    deleteApplication,
    downloadAdmitCard,
	downloadAdmitCardVP,
    downloadApplication,
    fetchApplications
} from "../action/applicant/application";
import {Link} from "react-router-dom";
import Button from "@material-ui/core/Button";
import {showToast} from "../App";
import { SettingsApplications } from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
  
const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    containerRoot:{
        flexGrow:1,
        padding: theme.spacing(1)
    }
}))

const Application=props=>{

    const dispatch=useDispatch()

    const classes = useStyles();

    const [applications,setApplications]=useState(null)
    const [applicationId,setApplicationId]=useState(null)
    const [deleteDialog,setDeleteDialog]=useState(false)
    const [payDialog,setpayDialog]=useState(false)

    const loadApplications=data=>{
        console.log(data)
        setApplications(data)
        console.log(data)
    }

    const fetch=()=>{
        fetchApplications(dispatch,loadApplications)
    }

    useEffect(()=>{
        fetch()
    },[])

    const handleCopy=(text)=>{
     
        var input = document.createElement('input');
        input.setAttribute('value', text);
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        showToast( "Application id is copied to your clip board");

    }

    const gotoCircular=id=>{
        props.history.push('/dashboard/circular/'+id)
        props.loadCircularMenu()
    }

    const getTimeString=timestamp=>{
        return new Date(parseInt(timestamp)).toLocaleString()
    }
    const closePayDialog=()=>{

setpayDialog(false);

    }
    const openPayDialog=()=>{

        setpayDialog(true);  
    }

    const deleteSuccess=()=>{
        showToast("Application Deleted Successfully")
        fetch()
    }

    const deleteClick=()=>{
        setDeleteDialog(false)
        deleteApplication(applicationId,dispatch,deleteSuccess)
    }

    return(
        <div className={classes.containerRoot}>
           <Dialog
        open={payDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={closePayDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Important!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
        To pay, you have to put your Application id in the Student Id field of Sonali Bank's website.So, you have to copy the Application id and proceed.Moreover, you have to select <b>Job Application Fee (JF)</b> in Fee type. Do yo want to continue to the website?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closePayDialog} color="primary">
            No
          </Button>
          <a onClick={closePayDialog} type="button" target="_blank" href="https://sbl.com.bd:7070/BUET/Fee/" color="primary">
            YES
          </a>
        </DialogActions>
      </Dialog>
         
         
         
         
            <Dialog open={deleteDialog}>
                <DialogTitle>
                    Are you sure ?
                </DialogTitle>
                <DialogActions>
                    <Button
                        color={'primary'}
                        onClick={()=>{setDeleteDialog(false)}}
                        >
                        No
                    </Button>
                    <Button
                        color={'secondary'}
                        onClick={deleteClick}
                    >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
 
            {
                applications===null?(
                    <LinearProgress/>
                ):(
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <div style={{fontSize:'1.5em'}}>
                                My Applications
                            </div>
                        </Grid>
                        {
                            applications.map(application=>{
                                return(
                                    <Grid  style={{minHeight:'100%'}} item xs={12} md={6} lg={4}>
                                        <Card style={{height:'100%'}} className={classes.root}>
                                            
                                                <div style={{fontSize:'1.5em',padding:'16px'}}>
                                                    {application.post_title}
                                                </div>
                                                <center>
                                                    {
                       
   application.ispaid?<img src={'tick.png'} style={{width:'20%'}}/>:<img src={'warning.png'} style={{width:'20%'}}/>

                            }
                                                </center>
                                                <CardContent>
                                                    <Typography style={{display:'flex'}}  gutterBottom variant="h6" component="h6">
                                                        Application Id : {application.code}
                                                        <FileCopyIcon  onClick={()=>{handleCopy(application.code)}} style={{marginLeft:"20px",marginTop:"4px",cursor:"pointer"}}/>

                                                    </Typography>
                                                    <Typography style={{display:'flex'}} variant="body2" color="textSecondary" component="p">
                                                        Circular : { <div onClick={()=>{gotoCircular(application.circular_id)}} style={{color:'#0090ff'}}>{application.circular_title}</div>}<br/>
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary" component="p">
                                                        Status : {application.status}<br/>
                                                        Updated at : {getTimeString(application.status_timestamp)}
                                                    </Typography>
                                                    <center>
                                                        <Button
                                                            variant={'outlined'}
                                                            style={{marginBottom:'10px',marginTop:'10px'}}
                                                            onClick={()=>{downloadApplication(application.application_id)}}
                                                        >
                                                            Application PDF
                                                        </Button>
                                                      
                                                        
                                                        <br/>
                                                        {
                                                            application.is_admit_card?(
                                                                <Button
                                                                    variant={'outlined'}
                                                                    color={'primary'}
                                                                    onClick={()=>{downloadAdmitCard(application.application_id)}}
                                                                >
                                                                    Admit Card - Written
                                                                </Button>
                                                            ):null
                                                        }
														{
                                                            application.is_admit_card_vp?(
                                                                <Button
                                                                    variant={'outlined'}
                                                                    color={'primary'}
                                                                    onClick={()=>{downloadAdmitCardVP(application.application_id)}}
                                                                >
                                                                    Admit Card - Practical and Viva
                                                                </Button>
                                                            ):null
                                                        }
                                                          {
                                                            !application.ispaid?  (
                                                                <Button
                                                            variant={'outlined'}
                                                            color={'primary'}
                                                            onClick={openPayDialog}
                                                        >
                                                            Pay Now
                                                        </Button>
                                                            ):null
                                                        }
                                                      

                                                    </center>
                                                </CardContent>
                                           
                                        </Card>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )
            }
        </div>
    )
}

export default Application
