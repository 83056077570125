const applicantAuthReducer=(state=0,action)=>{
    switch(action.type){
        case 'APPLICANT_SIGNED_IN':
            return 1;
        case 'APPLICANT_AUTH_LOADING':
            return 0;
        case 'APPLICANT_SIGNED_OUT':
            return -1;
        default:
            return state;
    }
}

export default applicantAuthReducer
