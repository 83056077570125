import React, {useEffect, useRef, useState} from 'react'
import {Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import Address from "./Address";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {useDispatch, useSelector} from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import {updateProfile} from "../action/applicant/profile";
import {FilePicker} from "react-file-picker";
import {uploadFile} from "../action/applicant/edu";
import {showToast} from "../App";
import PublishIcon from "@material-ui/icons/Publish";
import { Typography } from '@material-ui/core';
const EditProfile=props=>{

    const applicantProfile=useSelector(state=>state.applicantProfile)
    const dispatch=useDispatch()

    const [signatureFile,setSignatureFile]=useState(null)
    const [signatureLink,setSignatureLink]=useState(applicantProfile.signature===null?'':applicantProfile.signature)

    const [additionalFile,setAdditionalFile]=useState(null)
    const [additionalLink,setAdditionalLink]=useState(applicantProfile.additional_documents===null?'':applicantProfile.additional_documents)

    const [nidFile,setNidFile]=useState(null)
    const [nidLink,setNidLink]=useState(applicantProfile.nid===null?'':applicantProfile.nid)

    const [uploading,setUploading]=useState(false)

    const nameRef=useRef()
    const fullNameEnRef=useRef()
    const fullNameBnRef=useRef()
    const dobRef=useRef()
    const fNameRef=useRef()
    const mNameRef=useRef()
    const mobileRef=useRef()
    const emailRef=useRef()
    const natRef=useRef()
    const occRef=useRef()
    const genderRef=useRef()
    const relRef=useRef()
    const marRef=useRef()
    const nidRef=useRef()
    const trainingRef=useRef()
    const preAddrRef=useRef()
    const perAddrRef=useRef()


    const saveClick=()=>{
        var profile={
            bc_no:'',
            nid:nidLink,
            signature:signatureLink,
            additional_documents:additionalLink,
            birth_date:dobRef.current.value,
            email:emailRef.current.value,
            father_name:fNameRef.current.value,
            gender:genderRef.current.value,
            image:applicantProfile.image,
            marital_status:marRef.current.value,
            mother_name:mNameRef.current.value,
            name:nameRef.current.value,
            name_bn:fullNameBnRef.current.value,
            name_en:fullNameEnRef.current.value,
            nationality:natRef.current.value,
            nid_no:nidRef.current.value,
            training:trainingRef.current.value,
            occupation:occRef.current.value,
            permanent_address:perAddrRef.current.getData(),
            phone:mobileRef.current.value,
            present_address:preAddrRef.current.getData(),
            religion:relRef.current.value
        }
        console.log(profile)
        updateProfile(dispatch,profile)
    }

    return(
        <div>
            {
                applicantProfile===null?(
                    
                    <div/>
                ):(
                    <Paper style={{'padding':'20px'}}>
                        <div style={{fontSize:'1.5em',marginBottom:'10px'}}>
                           <b> Edit Profile </b>
                           {/* <span style={{marginLeft:"150 px"}}> Email</span>  */}
                        
                           <Typography variant="h6" align="center" style={{ fontSize:"18px",marginTop:"13px",maxWidth: '100%' }}>
        Email : {applicantProfile.email===""?"Not given":applicantProfile.email }
      </Typography>
                        </div>
                       
                        <div style={{position:'absolute',right:'20px',top:'73px'}}>
                            <Button
                                variant='contained'
                                color={'primary'}
                                onClick={saveClick}
                                disabled={uploading}
                                style={{zIndex:1000}}
                            >
                                {uploading?'Wait...':'Save Changes'}
                            </Button>
                        </div>
                        <Grid container spacing={2} >
                            <Grid style={{display:'none'}} item xs={12} md={4}>
                                <TextField
                                    required
                                    defaultValue={applicantProfile.name}
                                    inputRef={nameRef}
                                    variant={'outlined'}
                                    label={'User Name'}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    defaultValue={applicantProfile.name_en}
                                    inputRef={fullNameEnRef}
                                    variant={'outlined'}
                                    label={'Full Name (English)'}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    defaultValue={applicantProfile.name_bn}
                                    inputRef={fullNameBnRef}
                                    variant={'outlined'}
                                    label={'Full Name (বাংলা)'}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    defaultValue={applicantProfile.birth_date}
                                    inputRef={dobRef}
                                    type={'date'}
                                    variant={'outlined'}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">Date of birth : </InputAdornment>,
                                    }}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    defaultValue={applicantProfile.father_name}
                                    inputRef={fNameRef}
                                    variant={'outlined'}
                                    label={'Father\'s Name'}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    defaultValue={applicantProfile.mother_name}
                                    inputRef={mNameRef}
                                    variant={'outlined'}
                                    label={'Mother\'s Name'}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    defaultValue={applicantProfile.phone}
                                    inputRef={mobileRef}
                                    variant={'outlined'}
                                    label={'Mobile Number'}
                                    fullWidth/>
                            </Grid>
                            <Grid style={{display:'none'}} item xs={12} md={4}>
                                <TextField
                                    defaultValue={applicantProfile.email}
                                    inputRef={emailRef}
                                    variant={'outlined'}
                                    label={'Email Address'}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    defaultValue={applicantProfile.nationality}
                                    inputRef={natRef}
                                    variant={'outlined'}
                                    label={'Nationality'}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    defaultValue={applicantProfile.occupation}
                                    inputRef={occRef}
                                    variant={'outlined'}
                                    label={'Occupation'}
                                    fullWidth/>
                            </Grid>

                            <Grid item xs={6} md={4}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id="demo-simple-select-outlined-label">Gender</InputLabel>
                                    <Select
                                        inputRef={genderRef}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        defaultValue={applicantProfile.gender}
                                        label="Age">
                                        <MenuItem value={0}>Select Gender</MenuItem>
                                        <MenuItem value={1}>Male</MenuItem>
                                        <MenuItem value={2}>Female</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6} md={4}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id="demo-simple-select-outlined-label">Religion</InputLabel>
                                    <Select
                                        inputRef={relRef}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        defaultValue={applicantProfile.religion}
                                        label="Age">
                                        <MenuItem value={0}>Select Religion</MenuItem>
                                        <MenuItem value={1}>Islam</MenuItem>
                                        <MenuItem value={2}>Hindu</MenuItem>
                                        <MenuItem value={3}>Buddhist</MenuItem>
                                        <MenuItem value={4}>Christ</MenuItem>
                                        <MenuItem value={5}>Other</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <FormControl fullWidth variant="outlined">
                                    <InputLabel id="demo-simple-select-outlined-label">Marital Status</InputLabel>
                                    <Select
                                        inputRef={marRef}
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        defaultValue={applicantProfile.marital_status}
                                        label="Age">
                                        <MenuItem value={0}>Select Marital Status</MenuItem>
                                        <MenuItem value={1}>Unmarried</MenuItem>
                                        <MenuItem value={2}>Married</MenuItem>
                                        <MenuItem value={3}>Divorced</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    defaultValue={applicantProfile.nid_no}
                                    inputRef={nidRef}
                                    variant={'outlined'}
                                    label={'NID No'}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <TextField
                                    defaultValue={applicantProfile.training}
                                    inputRef={trainingRef}
                                    rows={2}
                                    multiline
                                    variant={'outlined'}
                                    label={'Training (if any)'}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <center>
                                    {
                                        applicantProfile.additional_documents!==null && applicantProfile.additional_documents.length>0?(
                                            <div style={{marginBottom:'5px'}}>
                                                <a target="_blank" href={applicantProfile.additional_documents}>
                                                    <u>Currently Uploaded File</u>
                                                </a>
                                            </div>
                                        ):(
                                            <div/>
                                        )
                                    }
                                    <FilePicker
                                        maxSize={5}
                                        extensions={['pdf']}
                                        onChange={file =>{
                                            setUploading(true)
                                            uploadFile(file).then(url=>{
                                                setAdditionalLink(url)
                                                setUploading(false)
                                            })
                                            setAdditionalFile(file)
                                        }}
                                        onError={errMsg => {
                                            showToast(errMsg)
                                        }}
                                    >
                                        <Button
                                            variant={'outlined'}
                                            color='primary'
                                            disabled={uploading}
                                            startIcon={<PublishIcon/>}>
                                            Additional Documents(pdf)
                                        </Button>
                                    </FilePicker>
                                    {
                                        additionalFile!==null?(
                                            <div style={{marginTop:'5px'}}>
                                                {additionalFile.name} <u style={{color:'#bb0000',cursor:'pointer'}} onClick={()=>{setAdditionalFile(null);setAdditionalLink(null);}}>remove</u>
                                            </div>
                                        ):(
                                            <div/>
                                        )
                                    }
                                </center>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <center>
                                    {
                                        applicantProfile.signature!==null && applicantProfile.signature.length>0?(
                                            <div style={{marginBottom:'5px'}}>
                                                <a target="_blank" href={applicantProfile.signature}>
                                                    <u>Currently Uploaded File</u>
                                                </a>
                                            </div>
                                        ):(
                                            <div/>
                                        )
                                    }
                                    <FilePicker
                                        extensions={['jpg','png','jpeg']}
                                        onChange={file =>{
                                            setUploading(true)
                                            uploadFile(file).then(url=>{
                                                setSignatureLink(url)
                                                setUploading(false)
                                            })
                                            setSignatureFile(file)
                                        }}
                                        onError={errMsg => {
                                            showToast(errMsg)
                                        }}
                                    >
                                        <Button
                                            variant={'outlined'}
                                            color='primary'
                                            disabled={uploading}
                                            startIcon={<PublishIcon/>}>
                                            Upload Signature
                                        </Button>
                                    </FilePicker>
                                    {
                                        signatureFile!==null?(
                                            <div style={{marginTop:'5px'}}>
                                                {signatureFile.name} <u style={{color:'#bb0000',cursor:'pointer'}} onClick={()=>{setSignatureFile(null);setSignatureLink(null);}}>remove</u>
                                            </div>
                                        ):(
                                            <div/>
                                        )
                                    }
                                </center>
                            </Grid>
                            <Grid item xs={6} md={4}>
                                <center>
                                    {
                                        applicantProfile.nid!==null && applicantProfile.nid.length>0?(
                                            <div style={{marginBottom:'5px'}}>
                                                <a target="_blank" href={applicantProfile.nid}>
                                                    <u>Currently Uploaded File</u>
                                                </a>
                                            </div>
                                        ):(
                                            <div/>
                                        )
                                    }
                                    <FilePicker
                                        extensions={['pdf']}
                                        onChange={file =>{
                                            setUploading(true)
                                            uploadFile(file).then(url=>{
                                                setNidLink(url)
                                                setUploading(false)
                                            })
                                            setNidFile(file)
                                        }}
                                        onError={errMsg => {
                                            showToast(errMsg)
                                        }}
                                    >
                                        <Button
                                            variant={'outlined'}
                                            color='primary'
                                            disabled={uploading}
                                            startIcon={<PublishIcon/>}>
                                            Upload NID
                                        </Button>
                                    </FilePicker>
                                    {
                                        nidFile!==null?(
                                            <div style={{marginTop:'5px'}}>
                                                {nidFile.name} <u style={{color:'#bb0000',cursor:'pointer'}} onClick={()=>{setNidFile(null);setNidLink(null);}}>remove</u>
                                            </div>
                                        ):(
                                            <div/>
                                        )
                                    }
                                </center>
                            </Grid>
                            <Grid item xs={0} md={8}/>
                            <Grid item xs={12} md={6}>
                                <Address data={applicantProfile.permanent_address} ref={perAddrRef} title={'Permanent Address : '}/>
                            </Grid>
                            <Grid item xs={0} md={6}/>
                            <Grid item xs={12} md={6}>
                                <Address data={applicantProfile.present_address} ref={preAddrRef} title={'Present Address : '}/>
                            </Grid>
                            <Grid item xs={0} md={6}/>
                        </Grid>
                    </Paper>
                )
            }
        </div>
    )
}

export default EditProfile
