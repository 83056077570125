import "./Options.css"
import {makeStyles, ModalManager} from "@material-ui/core"
import React, {useEffect, useRef, useState} from 'react'
import {useHistory} from "react-router-dom"

import {setLoading} from "../../App";


import Button from "@material-ui/core/Button";
import {login} from "../../action/admin/auth"
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import {showToast} from "../../App";
import {useSelector, useDispatch} from 'react-redux'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {validateEmail} from "../../util";
import {
    confirmForgotEmail,
    loginApplicant,
    register,
    registerApplicant,
    requestForgotEmail
} from "../../action/applicant/auth";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EmailIcon from "@material-ui/icons/Email";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import Divider from "@material-ui/core/Divider";
import HomeIcon from "@material-ui/icons/Home";
import Cookies from "universal-cookie";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const cookies = new Cookies();
const useStyles = makeStyles({


    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"

    },
    cont: {
        width: "100%",

        margin: "30"
    },

    login: {},
    form: {
        marginTop: 60,
        marginBottom: 60,

        display: "flex",
        flexDirection: "column"
    }

})


export const Options = props => {

    const adminAuth = useSelector(state => state.adminAuth)
    const history = useHistory();
    const [authDialogOpen, setAuthDialogOpen] = useState(props.applicantAuth)


    const applicantAuth = useSelector(state => state.applicantAuth)
    const dispatch = useDispatch()

    const emailRef = useRef();
    const passwordRef = useRef();
    const nameRef = useRef();
    const repasswordRef = useRef();

    const [forgotRequestDialog,setForgotRequestDialog]=useState(false)
    const [forgotConfirmDialof,setForgotConfirmDialog]=useState(false)

    const [forgotEmail,setForgotEmail]=useState(null)

    const [regCaptcha, setRegCaptcha] = useState('')
    const [loginCaptcha, setLoginCaptcha] = useState('')
    const [modal, setModal] = useState(false);
    const [verifyDialog, setVerifyDialog] = useState(false)


    const [forgotLoading,setForgotLoading]=useState(false)

    const forgotRequestEmailRef=useRef()
    const forgotConfirmCodeRef=useRef()
    const forgotConfirmPassRef=useRef()
    const forgotConfirmRePassRef=useRef()

    const forgotConfirmClick=async ()=>{
        const code=forgotConfirmCodeRef.current.value.trim()
        const pass=forgotConfirmPassRef.current.value.trim()
        const repass=forgotConfirmRePassRef.current.value.trim()

        if(code.length===0 || pass.length===0 || repass.length===0)
            showToast("Fields cannot be empty")
        else if(pass!==repass)
            showToast("Please enter the new password correctly")
        else{
            setForgotLoading(true)

            var responseData=await confirmForgotEmail(forgotEmail,code,pass)
            if(responseData){
                showToast("New password updated successfully, login using your new password")
                setForgotConfirmDialog(false)
            }else{
                showToast("Code is not valid or connectivity problem.")
            }

            setForgotLoading(false)
        }
    }

    const forgotRequestClick=async ()=>{
        const email=forgotRequestEmailRef.current.value.trim()
        if(email.length===0)
            showToast("Please enter email address")
        else{
            setForgotLoading(true)
            var responseData=await requestForgotEmail(email)
            if(responseData){
                setForgotEmail(email)
                setForgotRequestDialog(false)
                setForgotConfirmDialog(true)
            }else{
                showToast('This email is not register or connectivity problem.')
            }
            setForgotLoading(false)
        }
    }


    function onRegCaptchaChange(value) {
        setRegCaptcha(value)
    }

    function onLoginCaptchaChange(value) {
        setLoginCaptcha(value)
    }

    const [dialogState, setDialogState] = useState(2)

    const validatePhone = phone => {
        // if(phone.length!==11)
        //     return false
        // if(phone.substr(0,2)!=='01')
        //     return false
        return true
    }

    const clearTextFields = () => {
        emailRef.current.value = ""
        nameRef.current.value = ""
        passwordRef.current.value = ""
        repasswordRef.current.value = ""
    }

    const loginClick = () => {
        const email = emailRef.current.value
        const password = passwordRef.current.value
        if (!validateEmail(email))
            showToast('Please enter a valid email address')
        else if (password.length < 6)
            showToast('Password length must be greater or equals to 6')
        else {
            loginApplicant({
                login: email,
                password: password,
                type: 'applicant',
                captcha: loginCaptcha
            }, dispatch)
        }
    }

    const regSuccess = () => {
        showToast('Registration successful ! Now verify your new account')
        setDialogState(2)
        setVerifyDialog(true)
    }

    const regClick = () => {
        const email = emailRef.current.value
        const password = passwordRef.current.value
        const name = nameRef.current.value
        const repass = repasswordRef.current.value
        if (name.length === 0)
            showToast('Please enter a name')
        else if (!validateEmail(email))
            showToast('Please enter a valid email address')
        else if (password.length < 6)
            showToast('Password length must be greater or equals to 6')
        else if (password !== repass)
            showToast('Please re enter password correctly')
        else {
            registerApplicant({
                name: name,
                password: password,
                login: email,
                captcha: regCaptcha
            }, dispatch, regSuccess)
        }
    }

    const homeClick = () => {
        setAuthDialogOpen(false)
    }

    const userRef = useRef()
    const passRef = useRef()
    const [adminState, setAdminState] = useState(false);


    const classes = useStyles()
    const adminClick = () => {


        setAdminState(true)

    }
    useEffect(() => {
        if (cookies.get('admin_token') === undefined || cookies.get('admin_token') === null) {
            setModal(true)

        } else if (adminState) {
            document.getElementById('admin').click();
        }

        if (adminAuth == 1) {

            document.getElementById('admin').click();
        }
    }, [adminState, adminAuth])

    const hamburgerClick=()=>{

const id=document.querySelector('#circ');
if(id.style.marginTop=="215px")
id.style.marginTop="100px"
else {
    id.style.marginTop="215px"  
}
const ulist=document.querySelector('.ulist');

  ulist.classList.toggle('show')
  console.log('clicked')

  }
 

   const handleClick=()=>{
  
       
          var phone=userRef.current.value
          var password=passRef.current.value
          if(phone.length ===0 || password.length===0)
              showToast('enter phone number and password')
  
             login( {
                  login: phone,
                  password: password,
                  type:'admin'
                },dispatch,showToast)
                
  if(adminAuth==-1){
  
  setAdminState(false);
  }
  
  
  
  
      }

    return (
        <>

            <div className={classes.root}>
                <div>
                    <Dialog open={authDialogOpen} aria-labelledby="form-dialog-title">
                        {
                            applicantAuth === 0 ? (
                                <LinearProgress/>
                            ) : (
                                <div/>
                            )
                        }
                        <DialogTitle id="form-dialog-title">
                            {
                                dialogState === 1 ? (<div>Register</div>) : (dialogState === 2 ? (<div>Login</div>) : (
                                    <div>Enter OTP</div>))
                            }
                        </DialogTitle>
                        <DialogContent>
                            {
                                dialogState == 1 ? (
                                    <div>
                                        <TextField
                                            inputRef={nameRef}
                                            style={{marginTop: '12px'}}
                                            margin="dense"
                                            label="Name"
                                            variant="outlined"
                                            autoComplete='off'
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <AccountCircleIcon color='primary'/>
                                                    </InputAdornment>
                                                ),
                                                style: {
                                                    padding: 2
                                                }
                                            }}
                                        /><br/></div>
                                ) : (
                                    <div/>
                                )
                            }
                            {
                                dialogState < 3 || dialogState === 4 ? (
                                    <TextField

                                        inputRef={emailRef}
                                        style={{marginTop: '12px'}}
                                        margin="dense"
                                        label="Email Address"
                                        variant="outlined"
                                        autoComplete='off'
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EmailIcon color='primary'/>
                                                </InputAdornment>
                                            ),
                                            style: {
                                                padding: 2
                                            },
                                            autocomplete: 'off',
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                    />
                                ) : (
                                    <div/>
                                )
                            }
                            <br/>

                            {
                                dialogState < 3 ? (
                                    <TextField

                                        inputRef={passwordRef}
                                        style={{marginTop: '12px'}}
                                        margin="dense"
                                        label="Password"
                                        type="password"
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LockIcon color='primary'/>
                                                </InputAdornment>
                                            ),
                                            style: {
                                                padding: 2
                                            },
                                            autocomplete: 'new-password',
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                    />
                                ) : (
                                    <div/>
                                )
                            }

                            <br/>

                            {
                                dialogState == 1 ? (
                                    <TextField

                                        inputRef={repasswordRef}
                                        style={{marginTop: '12px'}}
                                        margin="dense"
                                        label="Retype Password"
                                        type="password"
                                        variant="outlined"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <LockOpenIcon color='primary'/>
                                                </InputAdornment>
                                            ),
                                            style: {
                                                padding: 2
                                            },
                                            autocomplete: 'new-password',
                                            form: {
                                                autocomplete: 'off',
                                            },
                                        }}
                                    />
                                ) : (
                                    <div/>
                                )
                            }
                            {
                                dialogState == 2 ? (
                                    <Button onClick={()=>{setForgotRequestDialog(true)}} disabled={applicantAuth === 0} color={'primary'}>
                                        Forgot Password ?
                                    </Button>
                                ) : (
                                    <div/>
                                )
                            }

                            <Divider style={{marginTop: '5px'}}/>

                            {
                                dialogState == 1 ? (
                                    <div>
                                        <center>

                                            {/*<ReCAPTCHA
                                        sitekey="6LejnMIcAAAAAK7JEhofX7c-fedw58BI-AnTYz2u"
                                        onChange={onRegCaptchaChange}
                                    />*/}
                                            <Button disabled={applicantAuth === 0} onClick={regClick}
                                                    style={{marginTop: '8px'}} variant="outlined" color="primary">
                                                Register
                                            </Button>
                                        </center>
                                        <center style={{marginTop: '8px'}}>or Already Have An Account ?</center>
                                        <center><Button disabled={applicantAuth === 0} onClick={() => {
                                            setDialogState(2)
                                        }} style={{marginTop: '8px', marginBottom: '8px'}} variant="outlined"
                                                        color="secondary">
                                            Login to existing account
                                        </Button></center>
                                    </div>
                                ) : dialogState == 2 ?
                                    (
                                        <div>
                                            <center>
                                                {/*<ReCAPTCHA
                                        sitekey="6LejnMIcAAAAAK7JEhofX7c-fedw58BI-AnTYz2u"
                                        onChange={onLoginCaptchaChange}
                                    />*/}
                                                <Button disabled={applicantAuth === 0} onClick={loginClick}
                                                        style={{marginTop: '8px'}} variant="outlined" color="primary">
                                                    Login
                                                </Button>
                                            </center>
                                            <center style={{marginTop: '8px'}}>or Need An Account ?</center>
                                            <center><Button disabled={applicantAuth === 0} onClick={() => {
                                                setDialogState(1)
                                            }} style={{marginTop: '8px', marginBottom: '8px'}} variant="outlined"
                                                            color="secondary">
                                                Create A New Account
                                            </Button></center>
                                        </div>
                                    ) : (
                                        <div/>
                                    )
                            }

                            <Divider style={{marginBottom: '5px', marginTop: '5px'}}/>

                            <center>
                                <center>
                                    <Button disabled={applicantAuth === 0} onClick={homeClick} startIcon={<HomeIcon/>}
                                            style={{marginTop: '8px', marginBottom: '8px'}} variant="outlined">
                                        Close
                                    </Button>
                                </center>
                            </center>

                        </DialogContent>

                    </Dialog>

                    <Dialog open={verifyDialog} aria-labelledby="form-dialog-title" onClose={() => {
                        setVerifyDialog(false)
                    }}>
                        <DialogTitle>
                            Email Verification
                        </DialogTitle>
                        <DialogContent>
                            Please check your inbox (also spam), we've sent a verification link there, after successful
                            verification, you can log in to your newly created account.
                        </DialogContent>
                        <DialogActions>
                            <Button color={'primary'} onClick={() => {
                                setVerifyDialog(false)
                            }}>Close</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={forgotRequestDialog}>
                        <DialogTitle>
                            Forgot Password ?
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        inputRef={forgotRequestEmailRef}
                                        variant={'outlined'}
                                        label={'Email Address'}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color={'secondary'}
                                disabled={forgotLoading}
                                onClick={()=>{setForgotRequestDialog(false)}}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={forgotLoading}
                                color={'primary'}
                                onClick={forgotRequestClick}
                            >
                                Next
                            </Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={forgotConfirmDialof}>
                        <DialogTitle>
                            Verification Code
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant={'body'}>
                                        A verification code is sent to your email address, please submit the code below to update your password.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        inputRef={forgotConfirmCodeRef}
                                        variant={'outlined'}
                                        label={'Verification Code'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        inputRef={forgotConfirmPassRef}
                                        variant={'outlined'}
                                        type={'password'}
                                        label={'New Password'}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        type={'password'}
                                        inputRef={forgotConfirmRePassRef}
                                        variant={'outlined'}
                                        label={'Retypr New Password'}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                color={'secondary'}
                                disabled={forgotLoading}
                                onClick={()=>{setForgotConfirmDialog(false)}}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={forgotLoading}
                                color={'primary'}
                                onClick={forgotConfirmClick}
                            >
                                Submit
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
                <a id="admin" href="/admin"> </a>
                <Dialog open={adminState && modal} onClose={() => {
                    setAdminState(false);
                    setModal(false)
                }}>

                    {
                        adminAuth === 0 ? <LinearProgress/> : null
                    }
                    <DialogTitle>Login</DialogTitle>
                    <DialogContent className={classes.root}>
                        <TextField
                            inputRef={userRef}
                            style={{marginTop: '20px'}}
                            autoFocus
                            margin="dense"
                            label="User Id"
                            type="text"
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <PersonIcon color='primary'/>
                                    </InputAdornment>
                                ),
                                style: {
                                    padding: 2
                                }
                            }}
                        />
                        <TextField
                            inputRef={passRef}
                            style={{marginTop: '10px', marginBottom: '20px'}}
                            autoFocus
                            margin="dense"
                            label="Password"
                            type="password"
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <LockIcon color='primary'/>
                                    </InputAdornment>
                                ),
                                style: {
                                    padding: 2
                                }
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={adminAuth === 0} onClick={handleClick} color="primary">
                            Login
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>

            <div id="bt-top-menus" class="bt-is-visible bt-horizontal" aria-hidden="false">
                <div class="bt-main-nav">
                    <nav class="bt-nav-menu bt-nav-menu-reverse" aria-label="Main Menu">
                        <div class="hamburger">

                            <img class="himg" onClick={hamburgerClick} style={{margin: "13px", cursor: "pointer"}}
                                 src="https://buet-edu-1.s3.amazonaws.com/auto_upload/bWqV1RhStaNRdpJF2nhPlcqUwIt2/1650562558513.png"/>
                            <div class="ulist">
                                <p><a href="/" aria-haspopup="true" aria-expanded="true" aria-current="page">Home</a>
                                </p>
                                <hr/>
                                <p><a href="/dashboard" aria-haspopup="true" aria-expanded="true"
                                      aria-current="page">Dashboard</a></p>
                                <hr/>
                                <p><a href="/dashboard" aria-haspopup="true" aria-expanded="true" aria-current="page">Applicant
                                    Login</a></p>
                                <hr/>
                                <p><a href="#" aria-haspopup="true" aria-expanded="true" aria-current="page"
                                      onClick={adminClick}>Admin Login</a></p>
                                <hr/>
                            </div>

                        </div>

                        <ul id="bt-main-nav" class="">
                            <li id="menu-item-33"
                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-12 current_page_item menu-item-has-children bt-dropdown menu-item-33 bt-is-active">
                                <a href="/" aria-haspopup="true" aria-expanded="true" aria-current="page">Home</a>

                            </li>
                            <li id="menu-item-32"
                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children bt-dropdown menu-item-32">
                                <a href="/dashboard" aria-haspopup="true" aria-expanded="false">Dashboard</a>

                            </li>
                            <li id="menu-item-34"
                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children bt-dropdown menu-item-34">
                                <a href="/dashboard" aria-haspopup="true" aria-expanded="false">Applicant Login</a>

                            </li>
                            <li id="menu-item-1458"
                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children bt-dropdown menu-item-1458">
                                <a href='#' aria-haspopup="true" aria-expanded="false" onClick={adminClick}>Admin
                                    Login</a>

                            </li>
  <li id="menu-item-1459"
                                class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children bt-dropdown menu-item-1459">
                                <a href='https://recruitment.buet.ac.bd/auto_uploads/guideline.pdf' target='_blank' aria-haspopup="true" aria-expanded="false" >Application GuideLine</a>

                            </li>

                        </ul>
                    </nav>
                </div>


            </div>

        </>
    )
}

export default Options;
