import React, { useState,useRef,useEffect } from 'react'
import { makeStyles } from "@material-ui/core"
import Navbar from "./Navbar"
import PostForm from "./Forms/PostForm"
import UpdatePostForm from "./Forms/UpdatePostForm"

import Button from "@material-ui/core/Button";
import DrawerList from "./DrawerList"
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from '@material-ui/icons/Lock';
import PhoneIcon from '@material-ui/icons/Phone';
import { showToast } from "../App";
import ScrutineerList from "./Lists/ScrutineerList"
import CircularList from "./Lists/CircularList"
import PostList from "./Lists/PostList"
import DownloadApplicantList from "./Lists/DownloadApplicants"
import ApplicantsList from "./Lists/ApplicantsList"

import {useSelector,useDispatch} from 'react-redux'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import {login} from "../action/admin/auth"
import {checkAuth} from "../action/admin/auth";
import ResetPass from "./ResetPass"
import { fetchCircularList } from '../action/admin/circular/circularList'

const useStyles = makeStyles({


    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"

    },
    cont: {
        width: "100%",

        margin: "30"
    },
    
    login: {

    },
    form:{
        marginTop: 60,
        marginBottom: 60,

        display: "flex",
        flexDirection:"column"
    }

})
const AdminHome = props => {
    
    const [state,setState]=useState(1);
    const [searchText,setSearchText]=useState('');
    const [circularId,setCircularId]=useState('')
    const [term,setTerm]=useState('')
    const [data,setData]=useState({})
    const [lastVisitedCircular,setLastVisitedCircular]=useState({})
    const adminAuth=useSelector(state=>state.adminAuth)
    const scrutineerList =useSelector(state=>state.scrutineerList);
    const circularList =useSelector(state=>state.circularList);
    const postList =useSelector(state=>state.postList);
    const recentCircular=useSelector(state=>state.recentCircular);

const applicantList=useSelector(state=>state.adminApplicationList)
    const dispatch=useDispatch()
    
    const userRef=useRef()
    const passRef=useRef()

   
    useEffect(()=>{ 
      

        checkAuth(dispatch);
       
     
    },[searchText])  

    const classes = useStyles()

   const isEmptyObject=(obj)=> {
        for (var property in obj) {
            if (obj.hasOwnProperty(property)) {
                return false;
            }
        }
    
        return true;
    }
    const handleClick=()=>{

     
        var phone=userRef.current.value
        var password=passRef.current.value
        if(phone.length ===0 || password.length===0)
            showToast('enter phone number and password')

           login( {
                login: phone,
                password: password,
                type:'admin'
              },dispatch,showToast)
              
    } 
    return (
  
<div className={classes.root}>
{
    (false) ?
    
    
(
 <Dialog open={true}>

    {
adminAuth===0? <LinearProgress/> :null
    }
    <DialogTitle>Login</DialogTitle>
    <DialogContent className={classes.root}>
        <TextField
            inputRef={userRef}
            style={{marginTop:'20px'}}
            autoFocus
            margin="dense"
            label="Phone no"
            type="text"
            variant="outlined"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <PhoneIcon color='primary'/>
                    </InputAdornment>
                ),
                style: {
                    padding: 2
                }
            }}
        />
        <TextField
            inputRef={passRef}
            style={{marginTop:'10px',marginBottom:'20px'}}
            autoFocus
            margin="dense"
            label="Password"
            type="password"
            variant="outlined"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <LockIcon color='primary'/>
                    </InputAdornment>
                ),
                style: {
                    padding: 2
                }
            }}
        />
    </DialogContent>
    <DialogActions>
        <Button disabled={adminAuth===0}  onClick={handleClick}  color="primary">
            Login
        </Button>
    </DialogActions>
</Dialog>):
(
<div >
   
<div className={classes.cont}><DrawerList setState={setState} state={state}/><Navbar text="Admin"  setSearchText={setSearchText} /></div>
 {
   state===2?(
    
        circularList===null?(
         <LinearProgress style={{marginTop:63}}/>):<CircularList text={searchText}/>

   )
   :(  
state===3?(
    scrutineerList===null? <LinearProgress style={{marginTop:63}}/>:(

<ScrutineerList text={searchText}/>
     
    )) :(state===4?(postList===null || circularList===null?<LinearProgress style={{marginTop:63}}/>:(<PostList lastCircular={circularList[circularList.length-1]}  text={searchText} setState={setState} setCircularId={setCircularId} setData={setData}/>)):
    state===5?(applicantList===null || circularList===null || postList===null?<LinearProgress style={{marginTop:63}}/>:(<DownloadApplicantList lastCircular={circularList[circularList.length-1]} postList={postList} text={searchText} type="paid" />)):state===6?(<ResetPass/>):state===7?(<PostForm setState={setState} circularId={circularId}  />):state===8?(<UpdatePostForm post={data}  setState={setState} />):state===9?(applicantList===null || circularList===null || postList===null?<LinearProgress style={{marginTop:63}}/>:(<ApplicantsList lastCircular={circularList[circularList.length-1]} postList={postList} text={searchText}/>)):  state===12?(applicantList===null || circularList===null || postList===null?<LinearProgress style={{marginTop:63}}/>:(<DownloadApplicantList lastCircular={circularList[circularList.length-1]} postList={postList} text={searchText} type="selected" />)):null))


} 

</div>

)
}



</div>
    );
}

export default AdminHome;
