import React,{useState,useEffect} from "react"
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from "@material-ui/core/Grid"
import {useSelector,useDispatch} from 'react-redux'
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import Tooltip from '@material-ui/core/Tooltip';
import DetailsModalBody from "../modals/DetailsModalBody";
import {fetchRecentCircular} from "../../action/admin/circular/recentCircular"
import Button from "@material-ui/core/Button";
import Modal from '@material-ui/core/Modal';
import PostModalBody from "../modals/PostModalBody";
import DeleteIcon from '@material-ui/icons/Delete';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton'
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { deletePost } from "../../action/admin/post/deletePost";
import LinearProgress from "@material-ui/core/LinearProgress";
import { setLoading, showToast } from "../../App";
import CircularProgress from '@material-ui/core/CircularProgress';
import DetailsIcon from '@material-ui/icons/Details';
import Visibility from '@material-ui/icons/Visibility';
import axios from 'axios'
import Cookies from 'universal-cookie';

import {api_base_url} from "../../index";




const useStyles = makeStyles((theme) => ({
    root: {
    display:"flex",
    marginLeft:220,
    height:150,
    marginTop:60,
    marginBottom:50
  
    
    },
    circularProgress: {
      marginLeft: 0,
      marginRight: theme.spacing.unit,
       color:'white'
    },
  
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      card:{
        width:'85%',
        
        marginLeft:60,
        marginBottom:30,
        marginTop:20,
        '&:hover': {
          backgroundColor: '#87CEFA',
        },
      },
    formControl: {
        margin:theme.spacing(3),
        minWidth: 120,
    
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
}))
const PostList = (props) => {

  const cookies = new Cookies();
    const classes=useStyles()
    const circularList=useSelector(state=>state.circularList)
    const postList=useSelector(state=>state.postList)
    const deletionState=useSelector(state=>state.adminPostDelete)
   
    const [open,setOpen]=useState(false)
    const [data,setData]=useState([])
    const [openEditModal,setOpenEditModal]=useState(false)
    const [openDialog, setOpenDialog] = useState(false);
    const dispatch=useDispatch()
    const recentCircular=useSelector(state=>state.recentCircular);
     const [loading,setLoading]=useState(false)
    const [deleteId,setId]=useState(0);
    const [renderedData,setRenderedData]=useState([])
const [sortedData,setSortedData]=useState(recentCircular?recentCircular instanceof Array?recentCircular[0]:recentCircular:props.lastCircular)
    const [openDetailsModal,setOpenDetailsModal]=useState(false)

    const [val,setVal]=useState(recentCircular?recentCircular instanceof Array?recentCircular[0].circular_id:recentCircular.circular_id:props.lastCircular.circular_id);

    
    let circularPosts=[]
const handleChange=(e)=>{
   setVal(e.target.value);
const circular=circularList.filter(circular=>circular.circular_id==e.target.value);

fetchRecentCircular(dispatch,circular);
setRenderedData([])
}
const viewPostsClick=()=>{
  setLoading(true)
  // let data=postList.filter(post=>{
  //   if(post.title.toLowerCase().includes(props.text.toLowerCase())){
  //     return post;
  //   }
  // })
  axios.get(api_base_url+'/admin/circular/posts/'+val,{headers:{authorization:'Bearer '+cookies.get('admin_token')}}).then(res=>{  
   

setRenderedData(res.data.data)
console.log(res.data.data)
if(res.data.data.length===0)
showToast("There is no post under this circular")
setLoading(false)
 }).catch(err=>{
     if(err.response!==undefined){
     switch(err.response.status){
        
     
         case 500:
             showToast('Internal server error')
             break
         default:
             showToast('Unknown error');
             break;
     }}
     else{
         showToast('Connectivity Problem');
     }
     
     setLoading(false)
 })


}

const deleteClick=(id)=>{

setId(id)
setOpenDialog(true)

}
const yesClick=()=>{
console.log(deleteId)
deletePost(deleteId,dispatch,showToast)
setOpenDialog(false)
let d=renderedData.filter(post=>post.post_id!==deleteId)
setRenderedData(d)
}
useEffect(()=>{
  let data=postList.filter(post=>{
    if(post.title.toLowerCase().includes(props.text.toLowerCase())){
      return post;
    }
  })
  setRenderedData(data)




 },[props.text]) 

useEffect(()=>{
 
  fetchRecentCircular(dispatch,recentCircular?recentCircular instanceof Array?recentCircular[0]:recentCircular:props.lastCircular); 

  


 },[postList]) 
 useEffect(()=>{
 
  //fetchRecentCircular(dispatch,recentCircular?recentCircular instanceof Array?recentCircular[0]:recentCircular:props.lastCircular); 

  setRenderedData([])


 },[])
 
    return ( 
        <div className={classes.root}>
          <Grid container >
          <Grid item xs={12} md={4}>
<h1 style={{marginLeft:20,fontWeight:"bold",marginTop:20}}>Posts</h1>
              </Grid>
      <Grid item xs={12} md={4} >
     
   <div style={{display:"flex",width:"545px"}}>
     <h2 style={{width:"200px",marginTop:"23px",fontWeight:"bold",fontSize:"22px"}}>Choose a Circular</h2>
        <FormControl variant="outlined" className={classes.formControl}  >
        <InputLabel id="demo-simple-select-outlined-label">Circular</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          
         
          id="demo-simple-select-outlined"
          value={val}
          
          
          onChange={handleChange}
           
          
          
  
          labelWidth={60}
        >
             <MenuItem value="" disabled>
  <em>{sortedData.title}</em>
</MenuItem>
        
            {
 

                circularList && circularList.map(circular=>{
                  
         
             return (

                <MenuItem  key={circular.circular_id} value={circular.circular_id}>{circular.title}</MenuItem>
           

             )


                })
            }
       
        
        </Select>
      </FormControl>
    </div>
          </Grid> 
         
        <Grid item xs={12} md={4} >
        <Button  onClick={()=>{props.setState(7); props.setCircularId(val);}} startIcon={<ControlPointIcon/>} style={{marginTop:'34px',marginLeft:"100px"}}  color='primary' variant='outlined'>
                                    Create
                                </Button>
        </Grid>
<Grid item xs={12}>
<Button onClick={viewPostsClick} startIcon={<Visibility/>} style={{marginTop:'20px',marginLeft:"38%"}}  color='primary' variant='contained'>
                             
                             
                             
                             
 
       {loading?<CircularProgress className={classes.circularProgress} size={20}/>:null}
      {loading?'Fetching...':'View Posts'}

                                </Button>

  </Grid>
{

renderedData && renderedData.filter(post=>{

  if(val==='') return post
  return post.circular_id===val
}).map(post=>{

return (
  <Grid key={post.post_id} item xs={12} md={12} >
    <Card   className={classes.card} >
    <CardHeader
       
      title={post.title}
      
    />
    <div style={{display:"block",marginLeft:20 }}>
    <div >  Exam Venue : {post.venue} </div>
    <div style={{marginTop:10}} > Exam Date: {post.date.split(';')[1]} </div>
 <div style={{marginTop:10}} > Code: {post.code} </div>
  
    </div>

      
<CardContent className={classes.content}>
<Tooltip title="Details">
<IconButton aria-label="details" onClick={()=>{setData(post);setOpenDetailsModal(true)}}>
 <DetailsIcon color="primary"/>
 </IconButton>
</Tooltip>
<Tooltip title="Edit">
<IconButton aria-label="edit" onClick={()=>{console.log(post);props.setData(post);props.setState(8);} }  >
 <EditIcon color="primary"/>
 </IconButton>
</Tooltip>
<Tooltip title="Delete">
<IconButton aria-label="delete"  onClick={()=>deleteClick(post.post_id)} >
 <DeleteIcon color="primary"/>
 </IconButton>
</Tooltip>



</CardContent>
    </Card>
 
</Grid>
)

  })
}

          
           </Grid>
     
<Modal
className={classes.modal}
 open={open}
 onClose={()=>setOpen(false)}
 aria-labelledby="createPost-modal"
 aria-describedby="createPost-modal"
>

<PostModalBody text ={'Create'} method={setOpen} val={val} />
</Modal>  
<Modal
className={classes.modal}
 open={openDetailsModal}
 onClose={()=>setOpenDetailsModal(false)}
 aria-labelledby="details-modal"
 aria-describedby="details-modal"
>

<DetailsModalBody method={setOpenDetailsModal} post={data} />
</Modal>  
<Modal
className={classes.modal}
 open={openEditModal}
 onClose={()=>setOpenEditModal(false)}
 aria-labelledby="editPost-modal"
 aria-describedby="editPost-modal"
>

<PostModalBody text ={'Edit'} method={setOpenEditModal} val={val} post={data}/>
</Modal>  

<Dialog
        open={openDialog}
        onClose={()=>setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {deletionState===0?<LinearProgress/>:null}
        <DialogTitle id="alert-dialog-title">{"Do you want to delete this post?"}</DialogTitle>
      
        <DialogActions>
          <Button onClick={()=>setOpenDialog(false)} color="primary">
            No
          </Button>
          <Button onClick={yesClick} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

        </div> 
     );
}
 
export default PostList;