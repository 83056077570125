import applicantAuthReducer from "./applicant/auth";
import adminAuthReducer from "./admin/auth";
import adminScrutinyCreationReducer from "./admin/createScrutiny"
import {combineReducers} from 'redux'
import {applicantProfileReducer} from "./applicant/profile";
import scrutineerListReducer from "./admin/scrutineerList";
import scrutinyAuthReducer from "./scrutiny/auth";
import adminScrutinyDeletionReducer from "./admin/deleteScrutineer"
import scrutinyUpdatePasswordReducer from "./scrutiny/updatePass"
import scrutineerDataReducer from "./scrutiny/scrutineerData"
import {scrutineerProfileReducer} from "./scrutiny/profile";
import {applicantEduReducer} from "./applicant/edu";
import adminCircularCreationReducer from "./admin/circular/createCircular"
import adminCircularUpdateReducer from "./admin/circular/updateCircular"
import circularListReducer from "./admin/circular/circularList"
import adminCircularDeletionReducer from "./admin/circular/deleteCircular";
import adminPostCreationReducer from "./admin/post/createPost"
import postListReducer from "./admin/post/postList"
import adminPostUpdateReducer from "./admin/post/updatePost";
import adminPostDeletionReducer from "./admin/post/deletePost";
import adminApplicationListReducer from "./admin/application/applicationList";
import adminPasswordResetReducer from "./admin/resetPass"
import recentCircularListReducer from "./admin/circular/recentCircular"
import adminSelectApplicantsReducer from "./admin/application/selectApplicants";

const allReducers=combineReducers({
    applicantAuth:applicantAuthReducer,
    applicantProfile:applicantProfileReducer,
    applicantEdu:applicantEduReducer,
    adminAuth:adminAuthReducer,
    adminScrutinyCreation:adminScrutinyCreationReducer,
    scrutineerList:scrutineerListReducer,
    scrutineerAuth:scrutinyAuthReducer,
    scrutineerDelete:adminScrutinyDeletionReducer,
    scrutineerUpdatePassword:scrutinyUpdatePasswordReducer,
    scrutineerData:scrutineerDataReducer,
    scrutineerProfile:scrutineerProfileReducer,
    adminCircularCreation:adminCircularCreationReducer,
    circularList:circularListReducer,
    adminCircularUpdate:adminCircularUpdateReducer,
    circularDelete:adminCircularDeletionReducer,
    adminPostCreation:adminPostCreationReducer,
    postList:postListReducer,
    adminPostUpdate:adminPostUpdateReducer,
    adminPostDelete:adminPostDeletionReducer,
    adminApplicationList:adminApplicationListReducer,
    adminPasswordReset:adminPasswordResetReducer,
    recentCircular:recentCircularListReducer,
    selectApplicants:adminSelectApplicantsReducer,

})

export default allReducers
