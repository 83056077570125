import axios from 'axios'
import Cookies from 'universal-cookie';
import {api_base_url} from "../../../index";

import { fetchPostList } from './postList';
const cookies = new Cookies();

export const deletePost =(id,dispatcher,callBack)=>{

    dispatcher(loadingDispatch())

    axios.delete(api_base_url+'/admin/publish/post/'+id,{headers:{authorization:'Bearer '+cookies.get('admin_token')}}).then(res=>{  
       dispatcher(deletePostDispatch());
       callBack("Successfully deleted the post");
       fetchPostList(dispatcher)

    }).catch(err=>{
        if(err.response!==undefined){
        switch(err.response.status){
            case 404:
                callBack('Post does not exist!')
                break
        
            case 500:
                callBack('Internal server error')
                break
            default:
                callBack('Unknown error');
                break;
        }}
        else{
            callBack('Connectivity problem');
        }
        dispatcher(deletionFailedDispatch());

    })


}

const loadingDispatch=()=>{
    return {
        type:'POST_DELETION_LOADING'
    }
}
const deletePostDispatch=()=>{
     return {
        type:'POST_DELETED'
    }
}

const deletionFailedDispatch=()=>{
    return {
        type:'POST_NOT_DELETED'
    }
}
