import React, {createRef, useEffect, useRef, useState} from 'react'
import {Grid} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {useDispatch, useSelector} from "react-redux";
import {apply, fetchCircular, fetchPosts} from "../action/applicant/application";
import LinearProgress from "@material-ui/core/LinearProgress";
//import Typography from "@material-ui/core/Typography";
import { getDistricts } from '../util';
import { getSubDistricts } from '../util';
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import WorkIcon from '@material-ui/icons/Work';
import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import SendIcon from '@material-ui/icons/Send';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
//import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {setLoading, showToast} from "../App";
import CloseIcon from '@material-ui/icons/Close';
import uuid from 'react-uuid'
import InputAdornment from "@material-ui/core/InputAdornment";
import {ref} from "yup";
import {FilePicker} from "react-file-picker";
import {uploadFile} from "../action/applicant/edu";
import PublishIcon from "@material-ui/icons/Publish";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    containerRoot:{
        flexGrow:1,
        padding: theme.spacing(1)
    }
}))

const Circular=props=>{

    const dispatch=useDispatch()

    const applicantProfile=useSelector(state=>state.applicantProfile)

    const applicantEdu=useSelector(state=>state.applicantEdu)

    const classes = useStyles();

    const circular_id=props.match.params.circular_id

    const experienceRef=useRef()
    const extraQualificationRef=useRef()
    const quotaTextRef=useRef()
   
    const [circular,setCircular]=useState(null)
    const [posts,setPosts]=useState(null)
    const [applyDialog,setApplyDialog]=useState(false)
    const [quotaValue, setQuotaValue] = React.useState('0');
    const [postId,setPostId]=useState(null);
   
    
    const handleQuotaChange = (event) => {
        setQuotaValue(event.target.value);
    };
 
    const loadPosts=data=>{
        console.log(data)
        setPosts(data)
    }

    const loadCircular=data=>{
        setCircular(data)
        fetchPosts(circular_id,dispatch,loadPosts)
    }

    useEffect(()=>{
        fetchCircular(circular_id,dispatch,loadCircular)
    },[])

    const [validityDialog,setValidityDialog]=useState(false)


    const isValidEdu=()=>{
        var result=false
        if(applicantEdu==null || applicantEdu.length===0)
            return false
        else{
            var result=true
            var validEduCount=0
            applicantEdu.map(e=>{
                if(e.type.length!==0){
                    var fieldCount=0
                    if(!(e.institute===null || e.institute.trim().length===0))
                        fieldCount++
                    if(!(e.grade===null || e.grade.trim().length===0))
                        fieldCount++
                    if(!(e.year===null || e.year===0))
                        fieldCount++
                    if(!(e.file===null || e.file.trim().length===0))
                        fieldCount++
                    if(!(e.board===null || e.board.trim().length===0))
                        fieldCount++
                    if(!(e.subject===null || e.subject.trim().length===0))
                        fieldCount++
                    result=result&&(fieldCount===0 || fieldCount===6)
                    if(fieldCount===6)validEduCount++
                }
            })
            result=result&&(validEduCount>0)
        }
        return result
    }


    const isValidProfile=()=>{
        return true
        if(applicantProfile.name_en===null || applicantProfile.name_en.length===0){
            showToast('Full Name (Bangla) is missing.')
            return false
        }
        if(applicantProfile.name_bn===null || applicantProfile.name_bn.length===0){
            showToast('Full Name (Bangla) is missing.')
            return false
        }
        if(applicantProfile.image===null || applicantProfile.image.length===0){
            showToast('Your photo is missing.')
            return false
        }
        if(applicantProfile.father_name===null || applicantProfile.father_name.length===0){
            showToast('Father\'s Name is missing.')
            return false
        }
        if(applicantProfile.phone===null || applicantProfile.phone.length===0){
            showToast('Phone Number is missing.')
            return false
        }
        if(applicantProfile.nationality===null || applicantProfile.nationality.length===0){
            showToast('Nationality is missing.')
            return false
        }
        if(applicantProfile.gender===null || applicantProfile.gender===0){
            showToast('Gender is missing.')
            return false
        }
        if(applicantProfile.nid_no===null || applicantProfile.nid_no.length===0){
            showToast('NID No is missing.')
            return false
        }
        if(applicantProfile.nid===null || applicantProfile.nid.length===0){
            showToast('NID scan pdf is missing.')
            return false
        }
        if(applicantProfile.present_address.dis===null || applicantProfile.present_address.dis===0){
            showToast('Present Address District is missing.')
            return false
        }
        if(applicantProfile.permanent_address.dis===null || applicantProfile.permanent_address.dis===0){
            showToast('Permanent Address District is missing.')
            return false
        }
        if(!isValidEdu()){
            showToast('Fill up full information (partial information of any educational qualification is not allowed) including certificate pdf attachment of all (at least 1) of your educational qualifications')
            return false
        }
        return true
    }

    const applyDialogClick=id=>{
        console.log(applicantProfile)
        if(isValidProfile()) {
            setPostId(id)
            setApplyDialog(true)
        }else{
            setValidityDialog(true)
        }
    }

    const applySuccess=()=>{
        setApplyDialog(false)
        showToast('Successfully Applied')
    }

    const applyClick=()=>{
        var data={
            post_id:postId,
            experiences:experienceRef.current.value,
            extra_qualifications:extraQualificationRef.current.value,
            quota_option:quotaValue,
            quota_string:''
        }
        if(quotaValue==='5')
            data['quota_string']=quotaTextRef.current.value
        apply(data,dispatch,applySuccess)
    }


    return(
        <div className={classes.containerRoot}>
            <Dialog open={validityDialog} onClose={()=>{setValidityDialog(false)}}>
                <DialogTitle>
                    Before you apply
                </DialogTitle>
                <DialogContent>
                    To apply to a post, your must fill up below fields in your profile, you can edit your profile from the <b><span style={{color:'red'}}>Edit Profile</span></b> option.
                    <br/>1. <b>Full Name (in English)</b>
                    <br/>2. <b>Full Name (in Bangla)</b>
                    <br/>3. <b>Your Photo</b>
                    <br/>4. <b>Father's Name</b>
                    <br/>5. <b>Mobile Number</b>
                    <br/>6. <b>Nationality</b>
                    <br/>7. <b>Gender</b>
                    <br/>8. <b>NID No</b>
                    <br/>9. <b>Present and Permanent Address</b>
                    <br/>10. <b>Scanned NID pdf</b>
                    <br/>11. <b>Signature image</b>

                    <br/><br/><b>You must enter full information of at least one educational qualification from the <span style={{color:'red'}}>My Education</span> option.</b>
                </DialogContent>
                <DialogActions>
                    <Button
                        color={'secondary'}
                        onClick={()=>{setValidityDialog(false)}}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            {
                !applyDialog?(
                    <div>
                        {
                            circular!==null && posts!==null?(
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <div style={{display:'flex',cursor:'pointer'}} onClick={()=>{props.history.push('/dashboard')}}>
                                            <ArrowBackIcon/>
                                            <div style={{fontSize:'1.4em',marginLeft:'10px'}}>
                                                All Circulars
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Paper style={{padding:'10px'}}>
                                            <div style={{fontSize:'1.3em'}}>
                                                {circular.title}
                                            </div>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                Published on : {circular.published}<br/>
                                                Application deadline : {circular.deadline}
                                            </Typography>
                                            <center>
                                                <img src={circular.image} style={{maxWidth:'100%',height:'auto',marginTop:'10px'}}/>
                                            </center>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <div style={{fontSize:'1.3em'}}>
                                                    Available Posts
                                                </div>
                                            </Grid>
                                            {
                                                posts.map(post=>{
                                                    return(
                                                        <Grid item xs={12}>
                                                            <Paper style={{padding:'20px '}}>

                                                                <Typography variant="h6" color="textPrimary" component="h6">
                                                                    <WorkIcon style={{marginRight:'10px'}}/>{post.title}
                                                                </Typography>
                                                                <Typography style={{marginTop:'10px'}} variant="body" color="textPrimary" component="body">
                                                                    {post.circular_text}
                                                                </Typography>
                                                                {
                                                                    post.isapplied?(
                                                                        <Button
                                                                            style={{marginTop:'16px'}}
                                                                            variant={'outlined'}

                                                                            startIcon={<CheckCircleIcon/>}>
                                                                            Already Applied
                                                                        </Button>
                                                                    ):(
                                                                        <Button
                                                                            style={{marginTop:'16px'}}
                                                                            variant={'outlined'}
                                                                            onClick={()=>{applyDialogClick(post.post_id)}}
                                                                            startIcon={<CheckCircleIcon/>}
                                                                            color={'primary'}>
                                                                            Apply Now
                                                                        </Button>
                                                                    )
                                                                }


                                                            </Paper>
                                                        </Grid>
                                                    )
                                                })
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ):(
                                <LinearProgress/>
                            )
                        }
                    </div>
                ):(
                    <ApplicationForm setValidityDialog={setValidityDialog} setPosts={setPosts} posts={posts} postId={postId} close={()=>{setApplyDialog(false)}}/>
                )
            }
        </div>
    )
}

const ApplicationForm=props=>{

    const classes = useStyles();
    const applicantProfile=useSelector(state=>state.applicantProfile)
    const applicantEdu=useSelector(state=>state.applicantEdu)
  
    //const [experiences,setExperiences]=useState(0)
    const [experiencesRef,setExperiencesRef]=useState([[createRef(),createRef(),createRef(),createRef()]])
    const [qualiRef,setQualiRef]=useState([createRef()])
    const [openDialog, setOpenDialog] = useState(false);
    useEffect(()=>{
       console.log(getDistricts().filter(d=>parseInt(d.id)==6))
    },[])

    const [experienceFile,setExperienceFile]=useState(null)
    const [experienceFileLink,setExperienceFileLink]=useState(null)
    const [qualiFile,setQualiFile]=useState(null)
    const [qualiFileLink,setQualiFileLink]=useState(null)
    const quotaTextRef=useRef()
    const [exp,setExp]=useState([])
    const [qualis,setQual]=useState([])
    const [quotaValue, setQuotaValue] = React.useState('0');
    const yesClick=()=>{

        console.log("yes")
           }
    const handleQuotaChange = (event) => {
        setQuotaValue(event.target.value);
    };

const handleOpen=()=>{
    var experiences=[]
    var qualis=[]
    experiencesRef.map(refSet=>{
        experiences.push({
           organization:refSet[0].current.value ,
           post:refSet[1].current.value ,
           start_date:refSet[2].current.value ,
           end_date:refSet[3].current.value
        })
    })
    qualiRef.map(ref=>{
        qualis.push(ref.current.value)
    })
  

    setExp(experiences);
    setQual(qualis);
    setOpenDialog(true);


}
    const  submit=()=>{
        var experiences=[]
        var qualis=[]
        experiencesRef.map(refSet=>{
            experiences.push({
               organization:refSet[0].current.value ,
               post:refSet[1].current.value ,
               start_date:refSet[2].current.value ,
               end_date:refSet[3].current.value
            })
        })
        qualiRef.map(ref=>{
            qualis.push(ref.current.value)
        })

        var data={
            post_id:props.postId,
            experience_file:experienceFileLink,
            extra_qualification_file:qualiFileLink,
            experiences:experiences,
            qualifications:qualis,
            quota_option:quotaValue,
            quota_string:''
        }
        if(quotaValue==='5')
            data['quota_string']=quotaTextRef.current.value
        //apply(data,dispatch,applySuccess)
        apply(data).then(b=>{
            if(b){
                showToast('Successfully Applied')
                var arr=props.posts
                arr.map((a,i)=>{
                    if(a.post_id==props.postId)
                        arr[i].isapplied=true
                })
                props.setPosts(arr)
                props.close()

            }
        }).catch(err=>{
            setLoading(false)
            if(err.response.status===400)
                props.setValidityDialog(true)
            else
                showToast('Connectvity problem')
        })
    }


    return(
        <div className={classes.containerRoot}>
            <Grid container spacing={1}>

                <Grid item xs={12} md={8} style={{display:'flex',alignItems:'center'}}>
                    <div style={{display:'flex',cursor:'pointer'}} onClick={()=>{props.close()}}>
                        <CloseIcon/>
                        <div style={{fontSize:'1.4em',marginLeft:'10px'}}>
                            Exit
                        </div>
                    </div>
                    <Button
                        color={'primary'}
                        variant={'outlined'}
                        style={{marginLeft:'auto'}}
                        onClick={handleOpen}
                        >
                        Submit Application
                    </Button>
                    
                </Grid>
                <Grid style={{marginTop:'10px'}}  md={8} item xs={12}>
                    <div style={{padding:'0',margin:'0',fontSize:'1.3em'}}>
                        <u>Experience Section</u>
                    </div>
                </Grid>
                <Grid style={{marginTop:'10px'}}  md={8} item xs={12}>
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        onClick={()=>{
                            setExperiencesRef([...experiencesRef,[createRef(),createRef(),createRef(),createRef()]]);
                        }}
                        >
                        + Add Experience
                    </Button>
                    <Button

                        onClick={()=>{
                            const array = [...experiencesRef];
                            array.splice(array.length-1, 1);
                            setExperiencesRef(array);
                        }}
                        color='secondary'
                        variant={'outlined'}
                        style={{marginLeft:'10px'}}>
                        - Remove Experience
                    </Button>
                </Grid>
                <Grid  style={{marginTop:'10px'}} item xs={12} md={8}>
                    <Grid container spacing={1}>
                        {
                            experiencesRef.map((refSet,i)=>{
                                return(
                                    <Grid style={{border:'solid',borderColor:'#e0e0e0'}} item xs={12} md={12} xl={12}>
                                        <div style={{display:'flex',marginBottom:'4px'}}>
                                            <div>
                                                <b>Experience - {(i+1)}</b>
                                            </div>

                                        </div>
                                        <TextField
                                            inputRef={refSet[0]}
                                            fullWidth
                                            label={'Organization'}
                                            variant={'outlined'}/>
                                        <TextField
                                            inputRef={refSet[1]}
                                            fullWidth
                                            style={{marginTop:'8px'}}
                                            label={'Post'}
                                            variant={'outlined'}/>
                                        <TextField
                                            style={{marginTop:'8px',width:'50%'}}
                                            inputRef={refSet[2]}
                                            type={'date'}
                                            variant={'outlined'}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">Start Date</InputAdornment>,
                                            }}
                                            />
                                        <TextField
                                            inputRef={refSet[3]}
                                            style={{marginTop:'8px',width:'49%',marginLeft:'1%'}}
                                            type={'date'}
                                            variant={'outlined'}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">End Date</InputAdornment>,
                                            }}
                                            fullWidth/>
                                    </Grid>
                                )
                            })

                        }
                        <Grid item xs={12}>
                            <FilePicker
                                extensions={['pdf']}
                                onChange={file =>{
                                    setExperienceFile(file)
                                    uploadFile(file).then(url=>{
                                        setExperienceFileLink(url)
                                    })
                                }}
                                onError={errMsg => {
                                    showToast(errMsg)
                                }}
                            >
                                <Button
                                    variant={'outlined'}
                                    color='primary'
                                    startIcon={<PublishIcon/>}>
                                    Upload Pdf of Experiences
                                </Button>
                            </FilePicker>
                            {
                                experienceFile!==null?(
                                    <div style={{marginTop:'5px'}}>
                                        {experienceFile.name} <u style={{color:'#bb0000',cursor:'pointer'}} onClick={()=>{setExperienceFile(null);setExperienceFileLink(null);}}>remove</u>
                                    </div>
                                ):(
                                    <div/>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid style={{marginTop:'10px'}}  md={8} item xs={12}>
                    <div style={{padding:'0',margin:'0',fontSize:'1.3em'}}>
                        <u>Extra Qualification Section :</u>
                    </div>
                </Grid>
                <Grid  style={{marginTop:'10px'}}  md={8} item xs={12}>
                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        onClick={()=>{
                            setQualiRef([...qualiRef,createRef()]);
                        }}
                    >
                        + Add Qualification
                    </Button>
                    <Button
                        onClick={()=>{
                            const array = [...qualiRef];
                            array.splice(array.length-1, 1);
                            setQualiRef(array);
                        }}
                        color='secondary'
                        variant={'outlined'}
                        style={{marginLeft:'10px'}}>
                        - Remove Qualification
                    </Button>
                </Grid>
                <Grid  style={{marginTop:'10px'}} md={8} item xs={12}>
                    <Grid container spacing={1}>
                        {
                            qualiRef.map((ref,i)=>{
                                return(
                                    <Grid style={{border:'solid',borderColor:'#e0e0e0'}} item xs={12} >
                                        <TextField
                                            multiline
                                            rows={3}
                                            inputRef={ref}
                                            fullWidth
                                            label={'Qualification - '+(i+1)}
                                            variant={'outlined'}/>
                                    </Grid>
                                )
                            })

                        }
                        <Grid item xs={12}>
                            <FilePicker
                                extensions={['pdf']}
                                onChange={file =>{
                                    setQualiFile(file)
                                    uploadFile(file).then(url=>{
                                        setQualiFileLink(url)
                                    })
                                }}
                                onError={errMsg => {
                                    showToast(errMsg)
                                }}
                            >
                                <Button
                                    variant={'outlined'}
                                    color='primary'
                                    startIcon={<PublishIcon/>}>
                                    Upload Pdf of Extra Qualifications
                                </Button>
                            </FilePicker>
                            {
                                qualiFile!==null?(
                                    <div style={{marginTop:'5px'}}>
                                        {qualiFile.name} <u style={{color:'#bb0000',cursor:'pointer'}} onClick={()=>{setQualiFile(null);setQualiFileLink(null);}}>remove</u>
                                    </div>
                                ):(
                                    <div/>
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>

                {/*<Grid  style={{marginTop:'10px'}}  md={8} item xs={12}>*/}
                {/*    <FormControl style={{marginTop:'10px'}} component="fieldset" fullWidth>*/}
                {/*        <FormLabel component="legend">Quota (If any)</FormLabel>*/}
                {/*        <RadioGroup aria-label="quota" name="quota1" value={quotaValue} onChange={handleQuotaChange}>*/}
                {/*            <FormControlLabel value={'0'} control={<Radio />} label="Not Applicable" />*/}
                {/*            <FormControlLabel value={'1'} control={<Radio />} label="Freedom Fighter Related" />*/}
                {/*            <FormControlLabel value={'2'} control={<Radio />} label="Orphan / Physically Handicapped" />*/}
                {/*            <FormControlLabel value={'3'} control={<Radio />} label="Ethnic Quota" />*/}
                {/*            <FormControlLabel value={'4'} control={<Radio />} label="Ansar/ Village Protection" />*/}
                {/*            <FormControlLabel value={'5'} control={<Radio />} label="Other" />*/}
                {/*        </RadioGroup>*/}
                {/*    </FormControl>*/}
                {/*</Grid>*/}
                {/*<Grid  style={{marginTop:'10px'}} md={8} item xs={12}>*/}
                {/*    {*/}
                {/*        quotaValue==='5'?(*/}
                {/*            <TextField*/}
                {/*                inputRef={quotaTextRef}*/}
                {/*                fullWidth*/}
                {/*                variant={'outlined'}*/}
                {/*                label={'Specify Quota'}*/}
                {/*            />*/}
                {/*        ):(*/}
                {/*            <div/>*/}
                {/*        )*/}
                {/*    }*/}
                {/*</Grid>*/}

            </Grid>
         
            <Dialog
            fullScreen
        open={openDialog}
        onClose={()=>setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={()=>setOpenDialog(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Preview
            </Typography>
          
          </Toolbar>
        </AppBar>
        <List >
          <ListItem  style={{marginRight:"100px"}}>
            <ListItemText primary="Phone ringtone" secondary="Titania" />
          </ListItem>
          <Divider />
          <ListItem >
        <b>  1. Name (in English) : </b> {applicantProfile.name}
         
          
          </ListItem>
          <Divider />
          <ListItem >
        
     <b>  Name (in Bangla) : </b> {applicantProfile.name_bn}
         
          </ListItem>
          <Divider />
          <ListItem >
        
       <b> 2. Gender  : </b> {applicantProfile.gender==0?"Not given":applicantProfile.gender==1?'Male':'Female'}
           
            </ListItem>
            <Divider />
            <ListItem >
        
        <b>3. Father's name  : </b> {applicantProfile.father_name}
           
            </ListItem>
            <Divider />
            <ListItem >
        
        <b>4. Mother's name  : </b> {applicantProfile.mother_name}
           
            </ListItem>
            <Divider />
            <ListItem >
        
        <b>5. Occupation  : </b> {applicantProfile.occupation}
           
            </ListItem>
            <Divider />
            <ListItem >
        
        <b>6. Present Address : </b> {applicantProfile.present_address.house+","+applicantProfile.present_address.area+","+ applicantProfile.present_address.post+",post code: "+applicantProfile.present_address.post_code+","+applicantProfile.present_address.union+","} {applicantProfile.present_address.dis!==null && applicantProfile.present_address.dis>=0 && applicantProfile.present_address.sub_dis!==null && applicantProfile.present_address.sub_dis>0?getSubDistricts(applicantProfile.present_address.dis)[applicantProfile.present_address.sub_dis-1].name:""} ,{applicantProfile.present_address.dis!==null && applicantProfile.present_address.dis>0?getDistricts()[applicantProfile.present_address.dis-1].name:""}
           
           
            </ListItem>
            <Divider />
            <ListItem >
        
        <b>7. Permanent Address  : </b> {applicantProfile.permanent_address.house+","+applicantProfile.permanent_address.area+","+ applicantProfile.permanent_address.post+",post code: "+applicantProfile.permanent_address.post_code+","+applicantProfile.permanent_address.union+","}{applicantProfile.permanent_address.dis!=null &&applicantProfile.permanent_address.dis>0 && applicantProfile.permanent_address.sub_dis!=null&& applicantProfile.permanent_address.sub_dis>0?getSubDistricts(applicantProfile.permanent_address.dis)[applicantProfile.permanent_address.sub_dis-1].name:""+","} ,{applicantProfile.permanent_address.dis>0?getDistricts()[applicantProfile.permanent_address.dis-1].name:""}
           
            </ListItem>
            <Divider />
            <ListItem >
        
        <b>8. Mobile No  : </b> {applicantProfile.phone}
           
            </ListItem>
            <Divider />
            <ListItem >
        
        <b>9. Email : </b> {applicantProfile.email}
           
            </ListItem>
            <Divider />
            <ListItem >
        
        <b>10. Dob  : </b> {applicantProfile.birth_date}
           
            </ListItem>
            <Divider />
            <ListItem >
        
        <b>11. Nationality  : </b> {applicantProfile.nationality}
           
            </ListItem>
            <Divider /> 
            <ListItem >
        
        <b>12. NID  : </b> {applicantProfile.nid_no}
           
            </ListItem>
            <Divider />
            <ListItem >
        
       
        
        <b>13. Religion  : </b> {applicantProfile.religion==0?"Not given":applicantProfile.religion==1?'Islam':applicantProfile.religion==2?'Hindu':applicantProfile.religion==3?'Buddha':applicantProfile.religion==4?'Christian':'Others'}
           
            </ListItem>
            <Divider /> 
            <ListItem >
        
        <b>14.Marital Status  : </b> {applicantProfile.marital_status==0?"Not Given":applicantProfile.marital_status==1?'UnMarried':' Married'}
           
            </ListItem>
            <Divider /> 

            <ListItem >
        
        <b> 
        15 .Education:
      </b>
        <br/> <br/></ListItem >
        <ListItem >
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell component="th">Name of Exam</TableCell>
            <TableCell  component="th">Institute</TableCell>
            <TableCell component="th" >Year of Passing</TableCell>
            <TableCell component="th">Subject</TableCell>
            <TableCell component="th">Division Class/Grade</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {applicantEdu && applicantEdu.map((data) => (
            <TableRow
              key={data.edu_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >

   <TableCell component="th" scope="row">
                {data.type}
              </TableCell>
              <TableCell component="th" scope="row">
                {data.institute}
              </TableCell>
              <TableCell component="th" scope="row">
                {data.year}
              </TableCell>
              <TableCell component="th" scope="row">
                {data.subject}
              </TableCell>
              <TableCell component="th" scope="row">
                {data.grade}
              </TableCell>

              </TableRow>   
             ))}
             </TableBody>
        </Table>
        </TableContainer>

            </ListItem>
            <Divider /> 
            <ListItem >
        
        <b>16.Experience : </b>  </ListItem>
        <ListItem >
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell component="th">Organization</TableCell>
            <TableCell  component="th">Name of the Post</TableCell>
            <TableCell component="th" >Start Date</TableCell>
            <TableCell component="th">End Date</TableCell>
               </TableRow>
        </TableHead>
        <TableBody>
          {exp.length>0 && exp.map((data) => (
            <TableRow
           
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >

   <TableCell component="th" scope="row">
             {data.organization}
              </TableCell>
              <TableCell component="th" scope="row">
               {data.post}
              </TableCell>
              <TableCell component="th" scope="row">
              {data.start_date}
              </TableCell>
              <TableCell component="th" scope="row">
               {data.end_date}
              </TableCell>
            
              </TableRow>   
             ))}
             </TableBody>


        </Table></TableContainer>

            </ListItem>
            <Divider /> 
            <ListItem >
        
        <b>18.Extra qualifications : </b></ListItem >
        <ListItem >
        <ul style={{marginLeft:"60px",marginTop:"30px"}}>
{
    qualis && qualis.map((q,i)=>(
<div key={i}>
{q!==""?<li>{q}</li>:null}

</div>

    ))
}    </ul>       
            </ListItem>
            <Divider /> 
                
                  
            
        </List>
 
        <Button style={{position:'fixed',bottom:'10px',right:'10px',width:'150px'}}
                        color={'primary'}
                        variant={'contained'}
                        endIcon={<SendIcon/>}
                        onClick={submit}>
                       Proceed

                    </Button>  
      

      </Dialog>
        </div>
    )
}

export default Circular
