import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
const margin =10
const useStyles = makeStyles((theme) => ({


    paper: {
        position: 'absolute',
        width: 708,
        
        backgroundColor: '#add8e6',
        border: '1px solid #000',
       
        
        borderRadius:20,
        padding: theme.spacing(2, 4, 3),
      },
      heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
      },
}))
const DetailsModalBody = (props) => {
    const classes=useStyles()
    console.log(props.post)
    return (

        <div className={classes.paper} >
           <Accordion style={{marginTop:margin}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Title</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
           {props.post.title}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{marginTop:margin}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.heading}>Circular Text</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {props.post.circular_text}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{marginTop:margin}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className={classes.heading}>Exam Date</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {props.post.date}
          </Typography>
        </AccordionDetails>
      </Accordion >
       <Accordion style={{marginTop:margin}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4a-content"
          id="panel4a-header"
        >
          <Typography className={classes.heading}>Exam Venue</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {props.post.venue}
          </Typography>
        </AccordionDetails>
      </Accordion>
      {/* <Accordion style={{marginTop:margin}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5a-content"
          id="panel5a-header"
        >
          <Typography className={classes.heading}>Education Required</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
    {props.post.edu_req}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{marginTop:margin}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel6a-content"
          id="panel6a-header"
        >
          <Typography className={classes.heading}>Experience required</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {props.post.exp_req}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{marginTop:margin}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel7a-content"
          id="panel7a-header"
        >
          <Typography className={classes.heading}>Restrictions</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          {props.post.restriction}
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion style={{marginTop:margin}}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel8a-content"
          id="panel8a-header"
        >
          <Typography className={classes.heading}>Extra Info</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
         {props.post.extra_info}
          </Typography>
        </AccordionDetails>
      </Accordion> */}
      
       
      </div>


     );
}
 
export default DetailsModalBody;