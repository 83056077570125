import React,{useRef,useEffect,useState} from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import { showToast } from '../App';
import DialogActions from "@material-ui/core/DialogActions";
import {useSelector,useDispatch} from 'react-redux'
import {fetchScrutineerData} from "../action/scrutiny/scrutineerData"
import { makeStyles } from "@material-ui/core"
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import {schema} from "./validation/updatePasswordValidation"
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import { Alert, AlertTitle } from '@material-ui/lab';
import {updatePass} from "../action/scrutiny/updatePassword"
import {logout} from "../action/scrutiny/auth"
import Profile from "./Profile"

const useStyles = makeStyles({


    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"

    },

})

const UpdatePass = (props) => {

    const passRef=useRef();
    const confirmPassRef=useRef();
    const oldPassRef=useRef();
    const emailRef=useRef();
    
    const classes = useStyles()
    const scrutineerData=useSelector(state=>state.scrutineerData);
    const loadingState=useSelector(state=>state.scrutineerUpdatePassword)
    const dispatch=useDispatch()
   const [showPass,setShowPass]=useState(false)
   const [showConfirmPass,setShowConfirmPass]=useState(false);

  useEffect(()=>{

fetchScrutineerData(dispatch);

    },[])

    const  updateClick=()=>{
      const email=emailRef.current.value;
      const old_password=oldPassRef.current.value;
      const new_password=passRef.current.value;
      const confirm_password=confirmPassRef.current.value
   if(!email || !old_password || !new_password || !confirm_password){

    showToast("All fields are required");
   }
   else if(new_password !== confirm_password){
     showToast("Password didn't match")
   }
   else{
    schema.validate({

email:email,
old_password:old_password,
new_password:new_password,
confirm_password:confirm_password

    }).then((data)=>{


      updatePass({email:email,
        old_password:old_password,
        new_password:new_password,},dispatch);

      
    }).catch(err=>{

      showToast(err.errors[0])
    })
  }



    }


    return ( 
    <div>
{scrutineerData && scrutineerData.verified===false?(

<>
   <Alert severity="info">
        <AlertTitle>Info</AlertTitle>
        This is an important info — <strong>Please update your password to verify the account!</strong>
      </Alert>

   <Dialog open={true}>

         {
loadingState ===0? <LinearProgress/> :null
    } 

        <DialogTitle>Update Password</DialogTitle>
    <DialogContent className={classes.root}>
    <TextField
            inputRef={emailRef}
            style={{marginTop:'20px'}}
            autoFocus
            margin="dense"
            label="Email"
            type="email"
            variant="outlined"
          
        />
    <FormControl  variant="outlined" style={{marginTop:20}}>
          <InputLabel htmlFor="outlined-adornment-password">Old Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            inputRef={oldPassRef}
            type='password'
            
          
            labelWidth={110}
          />
            </FormControl>
      <FormControl  variant="outlined" style={{marginTop:20}}>
          <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            inputRef={passRef}
            type={showPass? 'text' : 'password'}
            
            
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>setShowPass(!showPass)}
                  
                  edge="end"
                >
                  {showPass ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={110}
          />
            </FormControl>
       <FormControl  variant="outlined" style={{marginTop:20}}>
          <InputLabel htmlFor="outlined-adornment-confirm-password">Confirm Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-confirm-password"
            inputRef={confirmPassRef}
            type={showConfirmPass? 'text' : 'password'}
            
            
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={()=>setShowConfirmPass(!showConfirmPass)}
                  
                  edge="end"
                >
                  {showConfirmPass ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={130}
          />
            </FormControl>
    </DialogContent>
    <DialogActions>
        <Button  color="primary" onClick={updateClick}>
            Update
        </Button>
    </DialogActions>
          </Dialog></>

):scrutineerData && scrutineerData.verified===true?<Profile/>:null}
    </div>
    
     );
}
 
export default UpdatePass;