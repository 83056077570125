
import React, {useEffect,useRef,useState} from 'react'
import buet from "../../assets/buet_logo.png"

import "./Nav.css"




export const Nav = () => {

   
    return (
        <>
       
      

        <header class="bt-header bt-has-search">

            <div class="bt-header-container">
                <div class="bt-header-crest-title">
                    <div class="bt-header-crest">
                        <a href="/" aria-hidden="true" tabindex="-1">

                        </a>
                    </div>

                    <div class="bt-title-tagline">
                        <img className="logo" src={buet}/>
                        <h1 id="site-title" class="bt-site-title bt-red-title ">
                            <a href="/" rel="home">BUET JOB PORTAL</a>
                        </h1>


                        
                    </div>
                </div>
               
            </div>
        </header> </>
    )
}
export default Nav;
