const scrutineerProfileReducer=(state=null,action)=>{
    switch(action.type){
        case 'SCRUTINEER_PROFILE_UPDATE':
            return action.data;
        default:
            return state;
    }
}

export {scrutineerProfileReducer}
