import React from 'react'
import {BrowserRouter, Route} from "react-router-dom";
import CircularList from "./CircularList";
import Circular from "./Circular";




const Circulars=props=>{

    return(
        <div>
            <BrowserRouter history={props.history}>
                <Route path = "/dashboard" exact component={CircularList}/>
                <Route path = "/dashboard/circular/:circular_id" component={Circular}/>
            </BrowserRouter>
        </div>
    )
}

export default Circulars
