import React, {useRef} from 'react'
import Grid from "@material-ui/core/Grid";
import {Button, Paper, TextField} from "@material-ui/core";
import {showToast} from "../App";
import {validateEmail} from "../util";
import {changePassword, register} from "../action/applicant/auth";
import {useDispatch} from "react-redux";

const ChangePassword=props=>{

    const dispatch=useDispatch()

    const oldPassRef=useRef()
    const passRef=useRef()
    const rePassRef=useRef()

    const btnClick=()=>{
        const oldPass=oldPassRef.current.value
        const pass=passRef.current.value
        const repass=rePassRef.current.value
        if(oldPass.length<6)
            showToast('Enter old password correctly')
        else if (pass.length<6)
            showToast('Password length must be greater or equals to 6')
        else if(pass!==repass)
            showToast('Please re enter new password correctly')
        else{
            changePassword({
                old_password:oldPass,
                new_password:pass
            },dispatch)
        }
    }

    return(
        <Paper style={{padding:'10px',paddingTop:'20px'}}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                    <TextField
                        type={'password'}
                        inputRef={oldPassRef}
                        variant={'outlined'}
                        fullWidth
                        label={'Old Password'}/>
                </Grid>
                <Grid item xs={0} md={8}/>
                <Grid item xs={12} md={4}>
                    <TextField
                        type={'password'}
                        inputRef={passRef}
                        variant={'outlined'}
                        fullWidth
                        label={'New Password'}/>
                </Grid>
                <Grid item xs={0} md={8}/>
                <Grid item xs={12} md={4}>
                    <TextField
                        type={'password'}
                        inputRef={rePassRef}
                        variant={'outlined'}
                        fullWidth
                        label={'Retype New Password'}/>
                </Grid>
                <Grid item xs={0} md={8}/>
                <Grid item xs={12} md={4}>
                    <center>
                        <Button
                            onClick={btnClick}
                            variant={'outlined'}
                            color={'primary'}>
                            Change Password
                        </Button>
                    </center>
                </Grid>
                <Grid item xs={0} md={8}/>
            </Grid>
        </Paper>
    )
}

export default ChangePassword