import axios from 'axios'
import Cookies from 'universal-cookie';
import {api_base_url} from "../../index";
import {logout} from "./auth";
import {setLoading, showToast} from "../../App";

const cookies = new Cookies();

export const fetchCirculars=(dispatcher,callBack)=>{
    setLoading(true)
    axios.get(api_base_url+'/applicant/circular/list').then(res=>{
        setLoading(false)
        callBack(res.data)
    }).catch(err=>{
        setLoading(false)
        if(err!==undefined && err.response!==undefined && err.response.status!==undefined && (err.response.status===401 || err.response.status===403)){
            logout(dispatcher)
        }else{
            showToast('Connectvity problem')
        }
    })
}

export const fetchCircular=(circular_id,dispatcher,callBack)=>{
    setLoading(true)
    axios.get(api_base_url+'/applicant/circular/'+circular_id).then(res=>{
        setLoading(false)
        callBack(res.data.data)
    }).catch(err=>{
        setLoading(false)
        if(err!==undefined && err.response!==undefined && err.response.status!==undefined && (err.response.status===401 || err.response.status===403)){
            logout(dispatcher)
        }else{
            showToast('Connectvity problem')
        }
    })
}

export const fetchPosts=(circular_id,dispatcher,callBack)=>{
    setLoading(true)
    axios.get(api_base_url+'/applicant/posts/'+circular_id,{headers:{authorization:'Bearer '+cookies.get('token')}}).then(res=>{
        setLoading(false)
        callBack(res.data.data)
    }).catch(err=>{
        setLoading(false)
        if(err!==undefined && err.response!==undefined && err.response.status!==undefined && (err.response.status===401 || err.response.status===403)){
            logout(dispatcher)
        }else{
            showToast('Connectvity problem')
        }
    })
}

export const apply=(data)=>{
    console.log(data)
    setLoading(true)
    return axios.post(api_base_url+'/applicant/application/apply',data,{headers:{authorization:'Bearer '+cookies.get('token')}}).then(res=>{
        setLoading(false)
        return true
    })
}

export const fetchApplications=(dispatcher,callBack)=>{
    setLoading(true)
    axios.get(api_base_url+'/applicant/application/list',{headers:{authorization:'Bearer '+cookies.get('token')}}).then(res=>{
        setLoading(false)
        callBack(res.data.list)
    }).catch(err=>{
        setLoading(false)
        if(err!==undefined && err.response!==undefined && err.response.status!==undefined && (err.response.status===401 || err.response.status===403)){
            logout(dispatcher)
        }else{
            showToast('Connectvity problem')
        }
    })
}

export const deleteApplication=(applicationId,dispatcher,callBack)=>{
    setLoading(true)
    axios.delete(api_base_url+'/applicant/application/delete/'+applicationId,{headers:{authorization:'Bearer '+cookies.get('token')}}).then(res=>{
        setLoading(false)
        callBack()
    }).catch(err=>{
        setLoading(false)
        if(err!==undefined && err.response!==undefined && err.response.status!==undefined && (err.response.status===401 || err.response.status===403)){
            logout(dispatcher)
        }else{
            showToast('Connectvity problem')
        }
    })
}

export const downloadAdmitCard=applicationId=>{
    setLoading(true)
    axios.get(`${api_base_url}/applicant/application/admitcard/${applicationId}`,
    {
                headers:{
                    'Content-Type': 'multipart/form-data',
                    authorization:'Bearer '+cookies.get('token')
                },
                responseType: 'arraybuffer',
            }
    ).then(res=>{
        setLoading(false)
        showToast('Admit Card Downloading...')
        const url = window.URL.createObjectURL(new Blob([res.data], {type: "application/pdf"}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'admit_card.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch(err=>{
        setLoading(false)
        showToast('Error Occurred')
    })
}

export const downloadAdmitCardVP=applicationId=>{
    setLoading(true)
    axios.get(`${api_base_url}/applicant/application/admitcardvp/${applicationId}`,
    {
                headers:{
                    'Content-Type': 'multipart/form-data',
                    authorization:'Bearer '+cookies.get('token')
                },
                responseType: 'arraybuffer',
            }
    ).then(res=>{
        setLoading(false)
        showToast('Admit Card Downloading...')
        const url = window.URL.createObjectURL(new Blob([res.data], {type: "application/pdf"}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'admit_card.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch(err=>{
        setLoading(false)
        showToast('Error Occurred')
    })
}

export const downloadApplication=applicationId=>{
    setLoading(true)
    axios.get(`${api_base_url}/applicant/application/pdf/${applicationId}`,
        {
            headers:{
                'Content-Type': 'multipart/form-data',
                authorization:'Bearer '+cookies.get('token')
            },
            responseType: 'arraybuffer',
        }
    ).then(res=>{
        setLoading(false)
        showToast('Application pdf Downloading...')
        const url = window.URL.createObjectURL(new Blob([res.data], {type: "application/pdf"}));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'application.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
    }).catch(err=>{
        setLoading(false)
        showToast('Error Occurred')
    })
}

export const fetchReport1MaxExample=()=>{
    setLoading(true)
    return axios.get(api_base_url+'/report1/max_example',{
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        responseType: 'arraybuffer',
    }).then(res => {
        setLoading(false)
        if(res.status===200) {
            showToast('example file downloaded');
            const url = window.URL.createObjectURL(new Blob([res.data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'max_example.csv'); //or any other extension
            document.body.appendChild(link);
            link.click();
            return res
        }else{
            showToast('error occurred');
        }
    }).catch(err=>{
        setLoading(false)
        showToast('error occurred')
    })
}

