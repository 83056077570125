import React, { useState, useEffect } from "react"
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux'
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid"
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Cookies from 'universal-cookie';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import html2pdf from 'html2pdf.js'
import { api_base_url } from "../../index"
import { showToast } from "../../App"
import axios from "axios"
import fileSaver from "file-saver"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    marginLeft: 220,
    height: 150,
    marginTop: 60,
    marginBottom: 50


  },
  circularProgress: {
    marginLeft: 0,
    marginRight: theme.spacing.unit,
    color: 'white'
  },
  header: {
    '& .super-app-theme--header': {

      fontWeight: "bold",
      fontSize: "20px",


    }

  },
  formControl: {
    margin: theme.spacing(3),
    minWidth: 120,

  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))
const DownloadApplicantList = (props) => {


  const cookies = new Cookies();
  const circularList = useSelector(state => state.circularList)
  const postList = useSelector(state => state.postList)
  const [circular_id, setCircularId] = useState(props.lastCircular.circular_id)

  const [post_id, setPostId] = useState('')
  const [renderedData, setRenderedData] = useState('')
  const applicationList = useSelector(state => state.adminApplicationList)
  const [recentCircular, setRecentcircular] = useState(props.lastCircular)
  const [recentPost, setRecentPost] = useState({})
  const [appPdf, setAppPdf] = useState([])
  const [loading, setLoading] = useState(false)
  const [openDialog, setOpenDialog] = useState(false)
  const classes = useStyles()



  const [pdfListLoading,setPdfListLoading]=useState(false)

  const appClick = () => {
    console.log(recentPost);

    const method = "POST";
    const mydata = {
      post_id: post_id,


    }
    let url = api_base_url + '/admin/applications/summaryDataOfPaid';

    // console.log(mydata)

    setPdfListLoading(true)

    axios.request({ url, method, data: mydata, headers: { authorization: 'Bearer ' + cookies.get('admin_token') } }).then(res => {

      setAppPdf(res.data.data.applicants)
      setOpenDialog(true)
      setPdfListLoading(false)
      // console.log(post_id)
      // console.log(appPdf);

    }).catch(e => {
      
      setPdfListLoading(false)
      console.log(e)})



    

  }

  useEffect(() => {

    const c_id = recentCircular.circular_id;
    let posts = props.postList.filter(post => post.circular_id === c_id);
    setRecentPost(posts[posts.length - 1])
    setPostId(posts[posts.length - 1].post_id)

    let data = applicationList.filter(app => {
      if (app.applicant_name === null) return app;
      if (app.applicant_name.toLowerCase().includes(props.text.toLowerCase())) {
        return app;
      }
    })
    setRenderedData(data)

    const method = "POST";
    const mydata = {
      post_id: post_id,


    }
    let url = api_base_url + '/admin/applications/summaryDataOfPaid';

    console.log(mydata)

    axios.request({ url, method, data: mydata, headers: { authorization: 'Bearer ' + cookies.get('admin_token') } }).then(res => {

      setAppPdf(res.data.data.applicants)
console.log(res.data.data.applicants)

    }).catch(e => console.log(e))
  }, [applicationList])

  const handleClick = () => {
    setLoading(true)
    //        setLoading(true)
    //         const mydata={
    //        post_id:post_id,


    //         }
    // let url=""
    //         if(props.type=="paid")
    //  url=api_base_url+'/admin/applications/reportOfPaid';
    //  else if(props.type=="selected"){
    //   url=api_base_url+'/admin/applications/reportSelected';
    //  }
    const method = "POST";
    //         console.log(mydata)

    //       axios.request({url,method,data:mydata,responseType: 'arraybuffer',headers:{authorization:'Bearer '+cookies.get('admin_token')}}).then(res=>{  

    //      console.log(res)
    //    const blob = new Blob([res.data], {type: "octet/stream"});
    //      fileSaver.saveAs(blob,"applicant_details.zip")

    //      setLoading(false)
    //     }).catch(err=>{
    //        setLoading(false) 
    //       console.log(err)
    //         if(err.response!==undefined){
    //         switch(err.response.status){


    //             case 500:
    //                 showToast('Internal server error')
    //                 break
    //             default:
    //                 showToast('Unknown error');
    //                 break;
    //         }}

    //         else{
    //             showToast('Unknown error');
    //         }}


    //         )

    const mydata = {
      post_id: post_id,


    }
    let url = api_base_url + '/admin/applications/summaryDataOfPaid';

    axios.request({ url, method, data: mydata, headers: { authorization: 'Bearer ' + cookies.get('admin_token') } }).then(res => {

      console.log(res.data)
      var options = {
        margin: 1,
        filename: `${post_id}_summary`,
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2, useCORS: true },
        pagebreak: { mode: 'avoid-all', before: '#un' },
        // pagebreak: {
        //   mode: ['avoid-all', 'css', 'legacy']
        //  },
        jsPDF: { unit: 'in', format: 'a4', orientation: 'p' }
      };

      // Use html2pdf function to generate PDF from entire HTML page

      var element;
      element = `<html><head>
       <meta charset="UTF-8">
          <style>
  td {
    font-size: 12px; /* set the font size for all table data elements */
  }
  p {
    font-size: 12px; /* set the font size for all table data elements */
  }
table{

      transform:translateY(80px);
}  
  img{
    width:75px;
    height:75px;
    transform:translateY(-70px);
  }


.header {
  transform:translateY(70px);
 
}
.header h3{

  font-size:16px;
  color:black;

}
.d{
  margin-left:150px;
  margin-top:30px;
}
td{
  padding:3px;
}
  td p,.t-data{
    transform:translateY(70px);
  }

</style>
          </head><body>
          <div class="header">
          <h3 class="d">পদের নাম : ${res.data.data.post_name}</h3>
          <h3 class="d">আবেদনের শেষ তারিখ  : ২১/০৩/২০২৩ </h3>
          <h3 class="d"> আবেদনকারীর সংখ্যা  : ${res.data.data.total_applicant}</h3>
          </div>
          
          <div class="table-container">
          
          
          
          <table border=1 style="font-size:10px;margin:0">
          <thead>
          <tr>
            <th><div class="t-data">Serial</div></th>
            <th><div class="t-data">Applicant ID</div></th>
            <th><div class="t-data">Applicant Image</div></th>
            <th><div class="t-data">Applicant Details</div></th>
            <th><div class="t-data">Education</div></th>
          </tr>
        </thead>
        <tbody>
          
         `


      res.data.data.applicants.forEach((e, i) => {
        const list = e.educations.map((fruit) => {
          return `<p>${fruit.grade != '' ? fruit.type + ':' + fruit.grade : ''}</p>`;
        });

        const formattedList = list.join('');

        element += (e.serial_id) % 4 == 0 ? `<tr id="un">
        
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        </tr>
        <tr>
        `
      
        
        
        : `<tr>`


        element += `<td><div class="t-data">${e.serial_id}</div></td>
<td><div class="t-data">${e.code}</div></td>
<td><div class="t-data"><img src='data:image/jpeg;base64,${e.imagedata}'>
</div></td>

<td>
<p>Name: ${e.name}</p>
<p>Gender: ${e.gender}</p>
<p>Father's name: ${e.father_name}</p>
<p>Mother's name: ${e.mother_name}</p>
<p>Occupation: ${e.current_occupation}</p>
<p>Mobile No: ${e.mobile}</p>
<p>Email: ${e.email}</p>
<p>DOB: ${e.birth_date}</p>
<p>Nationality: ${e.nationality}</p>
<p>NID: ${e.nid}</p>
</td>

<td>${formattedList}</td>
</tr>
`
      });

      element += `<tr id="un">
    <td></td>  
    <td></td> 
    <td></td> 
    <td></td> 
    <td></td> 
      </tr></tbody></table>
          </div>
       
          </body></html>`

      html2pdf().set(options).from(element).save().then(e => {
        setLoading(false)
      })



    }).catch(err => {
      setLoading(false)
      console.log(err)
      if (err.response !== undefined) {
        switch (err.response.status) {


          case 500:
            showToast('Internal server error')
            break
          default:
            showToast('Unknown error');
            break;
        }
      }

      else {
        showToast('Unknown error');
      }
    }


    )

  }
  return (

    <div className={classes.root} >

      <Grid container  >
        <Grid item xs={12} md={12}  >
          <h2 style={{ marginLeft: "34%", marginTop: "20px" }}>{props.type == "paid" ? "Download Applicants (Paid)" : "Download Applicants(Selected)"}</h2>
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{ display: "flex", marginTop: "20px", marginLeft: "25px" }}>
            <h2 style={{ marginTop: "22px", fontSize: "24px" }}>Choose a Circular :</h2>
            <FormControl variant="outlined" className={classes.formControl}  >

              <InputLabel id="demo-simple-select-outlined-label">Circular</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={circular_id}
                onChange={(e) => {
                  setCircularId(e.target.value);

                  let posts = postList.filter(post => post.circular_id === e.target.value)
                  if (posts.length !== 0) {
                    setRecentPost(posts[posts.length - 1])

                    setPostId(posts[posts.length - 1].post_id)
                  }
                }}



                labelWidth={60}
              >
                <MenuItem value="" disabled>
                  <em>{recentCircular.title}</em>
                </MenuItem>
                {
                  circularList && circularList.map(circular => {
                    return (
                      <MenuItem key={circular.circular_id} value={circular.circular_id}>
                        {circular.title}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <div style={{ display: "flex", marginTop: "20px", marginLeft: "45px" }}>
            <h2 style={{ marginTop: "22px", fontSize: "24px" }}>Choose a Post :</h2>
            <FormControl variant="outlined" className={classes.formControl}  >
              <InputLabel id="demo-simple-select-outlined-label">Post</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"

                value={post_id}
                onChange={(e) => { setPostId(e.target.value) }}



                labelWidth={60}
              >
                <MenuItem value="" disabled>
                  <em>{recentPost.title}</em>
                </MenuItem>
                {
                  postList && postList.filter(post => {
                    if (circular_id === '') {
                      return post
                    }
                    return post.circular_id === circular_id;
                  }).map(post => {
                    return (
                      <MenuItem key={post.post_id} value={post.post_id}>
                        {post.title}
                      </MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
          </div>
        </Grid>
        <Grid item xs={12}>
          <center>
                <a href={`http://103.94.135.201:8000/${post_id}`} target='_blank'>
                    <Button
                      color='primary'
                      variant='contained'>
                        All Applications and Summary
                      </Button>
                </a>
          </center>
        </Grid>
        {/* <Grid item xs={12} md={12}>
          <Button disabled={loading} onClick={handleClick} startIcon={<SaveAltIcon />} variant="contained" color="primary" style={{ marginLeft: "37%", marginTop: "50px", marginBottom: "10px" }} >

            {loading ? <CircularProgress className={classes.circularProgress} size={20} /> : null}
            {loading ? 'Downloading..' : 'Generate Summary Pdf'}

          </Button><br/>
          <Button disabled={pdfListLoading} onClick={appClick} variant="contained" color="primary" style={{ marginLeft: "37%", marginTop: "20px", marginBottom: "45px" }} >
          {pdfListLoading ? <CircularProgress className={classes.circularProgress} size={20} /> : null} All Applications
          </Button>
        </Grid> */}
      </Grid>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogTitle >{"Application Pdf"}</DialogTitle>


        <DialogContent>
          {appPdf && appPdf.map(a => (

            <div>
              <div style={{ display: "flex", paddingLeft: "20px" }}>
                <p style={{ marginRight: "20px" }}> {a.serial_id}.{a.name} </p> &nbsp;
                <a style={{ marginLeft: "auto" }} href={a.application_file} download target="_blank">Application PDF</a>
              </div>
              <br />
            </div>




          ))}


        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setOpenDialog(false) }}>Cancel</Button>

        </DialogActions>
      </Dialog>
    </div>
  )


}

export default DownloadApplicantList;