import axios from 'axios'
import Cookies from 'universal-cookie';
import {api_base_url} from "../../index";
import {logout} from "./auth"
import {showToast} from "../../App";

const cookies = new Cookies();

export const fetchScrutineerList=(dispatcher)=>{
    axios.get(api_base_url+'/admin/scrutiny/list',{headers:{authorization:'Bearer '+cookies.get('admin_token')}}).then(res=>{
        dispatcher(scrutineerListDispatch(res.data.list))
    }).catch(err=>{
        if(err.response!==undefined){
        switch(err.response.status){
         

    
            case 500:
                showToast('Internal server error')
                break
            default:
                showToast('Unknown error');
                break;
        }
    }else{
        showToast('Unknown error');  
        
    }
        
    })
}
export const scrutineerListDispatch=data=>{
    return{
        type:'SCRUTINEER_LIST',
        data:data
    }
}
