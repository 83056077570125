import axios from 'axios'
import Cookies from 'universal-cookie';
import { api_base_url } from "../../index";
import { showToast } from "../../App";
import {logout} from "./auth"
const cookies = new Cookies();

export const updatePass = (data, dispatcher) => {

    dispatcher(loadingDispatch())
    axios.put(api_base_url + '/scrutiny/profile/changepassword', data,{headers:{authorization:'Bearer '+cookies.get('scrutiny_token')}}).then(res => {


        showToast('password updated successfully');
        dispatcher(passwordUpdatedDispatch());

        setTimeout(()=>{logout(dispatcher)},3000)

       




    }).catch(err => {
        if (err.response !== undefined) {
            switch (err.response.status) {
                case 404:
                    showToast('Scrutineer not found')
                    break
                case 401:
                    showToast('Invalid  password')
                    break
                case 500:
                    showToast('Internal server error')
                    break
                default:
                    showToast('unknown error');
                    break;
            }
        } else {
            showToast('unknown error');
        }
        dispatcher(passwordNotUpdatedDispatch());
    })
}




const passwordUpdatedDispatch = () => {

    
    return {
        type: 'SCRUTINY_PASSWORD_UPDATED'
    }
}
const passwordNotUpdatedDispatch = () => {
    return {
        type: 'SCRUTINY_PASSWORD_NOT_UPDATED'
    }
}
const loadingDispatch = () => {
    return {
        type: 'SCRUTINY_PASSWORD_UPDATE_LOADING'
    }
}
