import React, {useRef, useEffect, useState} from 'react'
import Button from "@material-ui/core/Button";
import {logout} from "../action/scrutiny/auth"
import {useDispatch, useSelector} from 'react-redux'
import {fetchProfile} from "../action/scrutiny/profile";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useTheme from "@material-ui/core/styles/useTheme";
import LinearProgress from "@material-ui/core/LinearProgress";
import HomeIcon from "@material-ui/icons/Home";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ProfileImage from "../scrutiny/ProfileImage";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import ListItemText from "@material-ui/core/ListItemText";
import SchoolIcon from "@material-ui/icons/School";
import OfflineBoltIcon from "@material-ui/icons/OfflineBolt";
import DescriptionIcon from "@material-ui/icons/Description";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import LockIcon from "@material-ui/icons/Lock";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import WorkIcon from "@material-ui/icons/Work";
import Typography from "@material-ui/core/Typography";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import EditProfile from "../scrutiny/EditProfile";
import Education from "../applicant/Education";



const drawerWidth = 250;


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    stepperRoot: {
        width: '100%',
    },
    canvasPaper: {
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
}));

const Profile = (props) => {

    const scrutineerProfile=useSelector(state=>state.scrutineerProfile)
    const dispatch=useDispatch();

    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [menu,setMenu]=useState(1)

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    useEffect(()=>{
        if(scrutineerProfile===null)
            fetchProfile(dispatch)
    },[])

    const drawer = (
        <div style={{padding:'10px'}}>
            {
                scrutineerProfile===null?(
                    <LinearProgress/>
                ):(
                    <div>
                        <center>
                            <div>
                                <Button
                                    variant={'outlined'}
                                    onClick={()=>logout(dispatch)}
                                    startIcon={<ExitToAppIcon/>}
                                    color={'secondary'}>
                                    Logout
                                </Button>
                            </div>
                        </center>
                        <ProfileImage/>
                        <Divider style={{marginTop:'10px'}}/>
                        <List>
                            <ListItem selected={menu==1} onClick={()=>{setMenu(1)}} button>
                                <ListItemIcon><AccountBoxIcon /> </ListItemIcon>
                                <ListItemText primary={'My Profile'} />
                            </ListItem>
                        </List>
                        <Divider/>
                        <div style={{bottom:0,position:'absolute'}}>
                            <List>
                                <a style={{textDecoration:'none',color:'#222222'}} href={'https://www.buet.ac.bd/'}>

                                    <ListItem button>
                                        <ListItemIcon><img src={'buet_logo.png'} style={{width:'30px'}}/> </ListItemIcon>
                                        <ListItemText primary={'BUET Website'} />
                                    </ListItem>
                                </a>

                            </List>
                        </div>
                    </div>
                )
            }
        </div>
    );

    return(
        <div className={classes.root}>
            <CssBaseline />
            <AppBar  style={{backgroundColor:'#0090ff',color:'#ffffff'}}  className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>

                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                    >
                        <WorkIcon />
                    </IconButton>

                    <Typography variant="h6" noWrap>
                        Scrutineer Console
                    </Typography>
                    <div  style={{position:'absolute',right:'10px',display:'flex'}}>
                        <IconButton
                            onClick={handleClick}
                            color="inherit"
                        >
                            <MoreVertIcon/>
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={()=>{setMenu(1);handleClose();}}>My profile</MenuItem>
                            <MenuItem onClick={()=>logout(dispatch)}>Logout</MenuItem>
                        </Menu>
                    </div>

                </Toolbar>
                <Divider/>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {
                    menu===1?(
                        <EditProfile/>
                    ):(
                        <div/>
                    )
                }
            </main>
        </div>
    )



    return (
        <div>

    <p>This is profile page</p>
    <Button  color="primary" onClick={()=>logout(dispatch)}>
           Sign Out
        </Button>

    </div> );
}
 
export default Profile;
