const scrutinyUpdatePasswordReducer=(state=-1,action)=>{
    switch(action.type){
        case 'SCRUTINY_PASSWORD_UPDATED':
            return 1;
        case 'SCRUTINY_PASSWORD_UPDATE_LOADING':
            return 0;
        case 'SCRUTINY_PASSWORD_NOT_UPDATED':
            return -1;
        default:
            return state;
    }
}

export default scrutinyUpdatePasswordReducer