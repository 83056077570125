const adminCircularDeletionReducer=(state=-1,action)=>{
    switch(action.type){
        case 'CIRCULAR_DELETED':
            return 1;
        case 'CIRCULAR_DELETION_LOADING':
            return 0;
        case 'CIRCULAR_NOT_DELETED':
            return -1;
        default:
            return state;
    }
}

export default adminCircularDeletionReducer
