import React from 'react'
import "./Images.css"
const  Images= () => {
  return (
  <> <div className="container">






    <div class="uw-outer-row row-3 has_text_block lightest-gray-background cs-pad-top"><div class="uw-inner-row"><div class="uw-column one-column"><div class="uw-pe uw-pe-text_block"><p  style={{textAlign: "center"}}><em>The Department of Computer Sciences is committed to the ongoing work of ensuring an inclusive, equitable, and diverse educational, research, and career environment.</em></p>
<p class="text-center" style={{textAlign: "center"}}><a class="uwcs-btn uwcs-btn-blue-round" href="/"><span class="uwcs-btn-text">Learn about our diversity and inclusion efforts</span></a></p>
</div></div></div></div>
</div>

    </> 
  )
}

export default Images;