import * as yup from 'yup';
import { setLocale } from 'yup';
setLocale({
    mixed: {
      default: 'Validation error',
    },
    string: {
      min: 'Password must be of at least 6 characters ',
    },
  });
export const  schema = yup.object().shape({
  
  

  password: yup.string().min(6).required(),


});

