import React from 'react'
import buet from "../../assets/buet_logo.png"
import "./Footer.css"
const Footer = () => {
  return (
    <>
    <div className="footer">

<div className="inner_container">

<div className="logo_footer">
<img className="logo_buet" src={buet}></img>
<h3 className="header">Buet Job Portal</h3>   
</div>
<div className="links">

<h3 className="header">Quick Links</h3>
<a href="https://www.buet.ac.bd" className="list">BUET</a><br/>
<a href="https://www.buet.ac.bd/web/#/administration" className="list">Administration</a><br/>
<a href="https://iict.buet.ac.bd/" className="list">BUET IICT</a><br/>
</div>

<div className="contact">

<h3 className="header">Contacts</h3>
<a href="/" className="list">Palashi, Dhaka 1205, Bangladesh</a><br/>
<a href="/" className="list">Email diriict@iict.buet.ac.bd</a><br/>
<a href="/" className="list">Phone 880-2-9665602</a><br/>
<a href="/" className="list">Fax 880-2-9665602</a><br/>
</div>
</div>
    
    <div className="lowest_part">

<h3  className="feedback_test_1">	&copy; All rights reserved, BUET</h3>
    
    </div>
    
    </div>
    
    
    </>
  )
}

export default Footer