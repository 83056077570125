const circularListReducer=(state=null,action)=>{
    switch(action.type){
        case 'CIRCULAR_LIST':
            return action.data;
        default:
            return state;
    }
}

export default circularListReducer;
