const adminCircularCreationReducer=(state=-1,action)=>{
    switch(action.type){
        case 'CIRCULAR_CREATED':
            return 1;
        case 'CIRCULAR_CREATION_LOADING':
            return 0;
        case 'CIRCULAR_NOT_CREATED':
            return -1;
        default:
            return state;
    }
}

export default adminCircularCreationReducer
