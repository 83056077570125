import React,{useState,useEffect} from "react"
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid"
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {useSelector,useDispatch} from 'react-redux'
import Button from '@material-ui/core/Button';
import { DataGrid } from '@mui/x-data-grid';
import Visibility from '@material-ui/icons/Visibility';
import Cookies from 'universal-cookie';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import {api_base_url} from "../../index"

import axios from "axios"
import fileSaver from "file-saver"
import CircularProgress from '@material-ui/core/CircularProgress';
import {showToast} from "../../App" 
import {selectApplicants} from "../../action/admin/application/selectApplicant"

const useStyles = makeStyles((theme) => ({
    root: { 
    display:"flex",
    marginLeft:220,
    height:150,
    marginTop:60,
    marginBottom:50
  
    
    },
    circularProgress: {
      marginLeft: 0,
      marginRight: theme.spacing.unit,
       color:'white'
    },
  
    header:{
      '& .super-app-theme--header': {
      
        fontWeight:"bold",
        fontSize:"20px",
      

      }

    },
    formControl: {
        margin:theme.spacing(3),
        minWidth: 120,
    
      },
      selectEmpty: {
        marginTop: theme.spacing(2),
      },
      card:{
        width:'85%',
        
        marginLeft:60,
        marginBottom:30,
        marginTop:20,
        '&:hover': {
          backgroundColor: '#87CEFA',
        },
      }
}))



const ApplicantsList = (props) => {
  const cookies = new Cookies();

    const circularList=useSelector(state=>state.circularList)
    const postList=useSelector(state=>state.postList)
    const [circular_id,setCircularId]=useState(props.lastCircular.circular_id)
const loading=useSelector(state=>state.selectApplicants)
    const [post_id,setPostId]=useState('')
    const [renderedData,setRenderedData]=useState('')
    const applicationList=useSelector(state=>state.adminApplicationList)
   const [recentCircular,setRecentcircular]=useState(props.lastCircular)
   const [recentPost,setRecentPost]=useState({}) 
   const [appId,setAppId]=useState([])
   const [selectedId,setSelectedId]=useState([])
   const [appData,setAppData]=useState([])
   const[Loading,setLoading]=useState(false)
   const [openDialog, setOpenDialog] = useState(false);
const [selectedSerials,setSelectedSerials]=useState([])
const [isScrutinized,setIsScrutinized]=useState(false)
   const classes=useStyles()
   const dispatch=useDispatch()


  

  
const columns = [
  { field: 'id',   headerClassName: 'super-app-theme--header', headerName: 'ID', width: 150 ,   hide: true},
  

  { field: 'serial',   headerClassName: 'super-app-theme--header', headerName: 'Serial', width: 130 },

 
  {
    field: 'name',
    headerClassName: 'super-app-theme--header',
    headerName: 'Name',
    width: 180,
    
    editable: false,
  },
  {
    field: 'email',
    headerClassName: 'super-app-theme--header',
    headerName: 'Email',
    width: 200,
    
    editable: false,
  },
  {
    field: 'phone',
    headerClassName: 'super-app-theme--header',
    headerName: 'Phone',
    width: 180,
    
    editable: false,
  },
  { field: 'selectionStatus',   headerClassName: 'super-app-theme--header', headerName: ' Status', width: 150 },
  {
    field: 'dob',
    headerClassName: 'super-app-theme--header',
    headerName: 'DOB',
    width: 150,
    
    editable: false,
  },
];

   useEffect(()=>{
  
const c_id=recentCircular.circular_id;
let posts=props.postList.filter(post=>post.circular_id===c_id);
setRecentPost(posts[posts.length-1])
setPostId(posts[posts.length-1].post_id)

      let data=applicationList.filter(app=>{
        if(app.applicant_name===null) return app;
        if(app.applicant_name.toLowerCase().includes(props.text.toLowerCase())){
          return app;
        }
      })
      setAppData(data)
     },[applicationList]) 
     useEffect(()=>{
  
      
      
     
      
            let data=applicationList.filter(app=>{
              if(app.applicant_name===null) return app;
              if(app.applicant_name.toLowerCase().includes(props.text.toLowerCase())){
                return app;
              }
            })
            setRenderedData(data)
           },[props.text]) 
           useEffect(()=>{
  
      
            setRenderedData([])
           },[]) 

     const handleClick=()=>{
     
const url=api_base_url+'/admin/post/applications/'+post_id;

  setLoading(true)
     axios.get(url,{headers:{authorization:'Bearer '+cookies.get('admin_token')}}).then(res=>{  
    
   
    setRenderedData(res.data.data)
    setLoading(false);
    if(res.data.data&& res.data.data.length===0){
      showToast("There is no applicant under this post");
    }
    window.scrollTo(0,document.body.scrollHeight);
 
  let apps=res.data.data.filter(app=>app.is_admit_card===true)
  apps.length>0? setIsScrutinized(true):setIsScrutinized(false)

   }).catch(err=>{
    setLoading(false);
       if(err.response!==undefined){
       switch(err.response.status){
         
       
           case 500:
               showToast('Internal server error')
               break
           default:
               showToast('Unknown error');
               break;
       }}
      
       else{
           showToast('Connectivity Problem');
       }})
      
     }

const yesClick=()=>{
  setOpenDialog(false)
  const data={
    selected:selectedId
  };
  selectApplicants(data,dispatch);
 }

  


     const selectionChange=()=>{
      
      console.log(isScrutinized)
if(selectedId.length===0){
  showToast("You have to select at least one applicant")
}
else{

  let selectedSerial=[];
  selectedId.forEach(id=>{

let array=renderedData && renderedData.filter(app=>app.application_id===id)
array.forEach(a=>{
  selectedSerial.push(a.serial)
})

  })
  setSelectedSerials(selectedSerial)
  setOpenDialog(true)
      

         }
        }
              let rows=[]
    return ( 

    
    <div className={classes.root}>
  <Grid container >
          <Grid item xs={12} md={12}>
<h1 style={{marginLeft:"26vw",marginTop:"30px"}}>View/Manage Applicants</h1>
              </Grid>
            
              <Grid item xs={12} md={6}>
                <div style={{display:"flex",paddingLeft:"37px"}}>
                  <h2 style={{marginTop:"22px",fontSize:"24px"}}>Choose a Circular</h2>
              <FormControl variant="outlined" className={classes.formControl}  >
        <InputLabel id="demo-simple-select-outlined-label">Circular</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={circular_id}
          onChange={(e)=>{setCircularId(e.target.value);
             setRenderedData([])
            let posts=postList.filter(post=>post.circular_id===e.target.value)
if(posts.length!==0){
        setRecentPost(posts[posts.length-1])
          
        setPostId(posts[posts.length-1].post_id)
}
          }}
         
          
  
          labelWidth={60}
        >
             <MenuItem value="" disabled>
              <em>{recentCircular.title}</em>
          </MenuItem>
          {
              circularList && circularList.map(circular=>{
                  return (
                    <MenuItem key ={circular.circular_id} value={circular.circular_id}>
                    {circular.title}
                </MenuItem>   
                  )
              })
          }
          </Select>
      </FormControl></div>
              </Grid>
              <Grid item xs={12} md={6}>
                <div style={{display:"flex",paddingLeft:"92px"}}>
                  <h2 style={{marginTop:"22px",fontSize:"24px"}}>Choose a Post</h2>
              <FormControl variant="outlined" className={classes.formControl}  >
        <InputLabel id="demo-simple-select-outlined-label">Post</InputLabel>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          
          value={post_id}
          onChange={(e)=>{setPostId(e.target.value);   setRenderedData([]);console.log(e.target.value)}}
          
          
  
          labelWidth={60}
        >
             <MenuItem value="" disabled>
              <em>{recentPost.title}</em>
          </MenuItem>
          {
              postList && postList.filter(post=>{
                  if(circular_id===''){
                      return post
                   }
                  return post.circular_id===circular_id;}).map(post=>{
                  return (
                    <MenuItem key ={post.post_id} value={post.post_id}>
                    {post.title}
                </MenuItem>   
                  )
              })
          }
          </Select>
      </FormControl></div>
              </Grid>
              <Grid item xs={12} md={12}>
              <Button startIcon={<Visibility/>} onClick={handleClick} variant="contained" color="primary" style={{ marginTop: "40px",marginLeft: "34vw",marginBottom:"20px"}} >


       {Loading?<CircularProgress className={classes.circularProgress} size={20}/>:null}
      {Loading?'Fetching...':'View Applicants'}

</Button>
                </Grid>
              {renderedData && renderedData.filter(app=>{
                console.log(app);
if(circular_id==='' && post_id===''){
  return app
}
else if(circular_id!=='' && post_id==''){
  return app.circular_id===circular_id
}
else if(circular_id!=='' && post_id!==''){
  return app.circular_id===circular_id && app.post_id===post_id
}
else{
  return app.post_id===post_id
}

              }).map((app,idx)=>{
                 
  

rows.push({id:app.application_id,serial:app.serial,name:app.applicant_name,email:app.email,phone:app.phone,selectionStatus:app.is_admit_card?'Selected':"",dob:app.birth_date})
  

})}
<Grid item xs={12} md={12}>
 <div style={{ margin:'auto',marginTop:'20px',height: 400, width: '95%' }} className={classes.header}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        checkboxSelection
        disableSelectionOnClick
        onSelectionModelChange={itm => setSelectedId(itm)}
      />
    </div>
</Grid>

  <Grid item xs={12} md={12}>
<Button onClick={selectionChange}  disabled={isScrutinized} variant="contained" color="primary" style={{ marginLeft:"30px", marginTop: "40px",marginBottom: "45px"}} >


{loading==0?<CircularProgress className={classes.circularProgress} size={20}/>:null}
      {loading==0?'Updating...':' Update Selection'}
  
  
</Button>

 
</Grid>
</Grid>

<Dialog
        open={openDialog}
        onClose={()=>setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        
        <DialogTitle id="alert-dialog-title">Your have selected serial no :
       {
          selectedSerials &&  selectedSerials.map((serial,i)=>(<b> {serial} {i<selectedSerials.length-1?<>,</>:<> . </>} </b>))
       } 
       Are you sure you want to select these?</DialogTitle>
      
        <DialogActions>
          <Button onClick={()=>setOpenDialog(false)} color="primary">
            No
          </Button>
          <Button onClick={yesClick} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>




    </div> );
}
 
export default ApplicantsList;