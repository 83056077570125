const applicantEduReducer=(state=null,action)=>{
    switch(action.type){
        case 'APPLICANT_EDU_UPDATE':
            return action.data;
        default:
            return state;
    }
}

export {applicantEduReducer}
