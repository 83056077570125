const adminPostCreationReducer=(state=-1,action)=>{
    switch(action.type){
        case 'POST_CREATED':
            return 1;
        case 'POST_CREATION_LOADING':
            return 0;
        case 'POST_NOT_CREATED':
            return -1;
        default:
            return state;
    }
}

export default adminPostCreationReducer
