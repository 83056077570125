const adminPasswordResetReducer=(state=-1,action)=>{
    switch(action.type){
        case 'PASSWORD_RESET':
            return 1;
        case 'PASSWORD_RESET_LOADING':
            return 0;
        case 'PASSWORD_NOT_RESET':
            return -1;
        default:
            return state;
    }
}

export default adminPasswordResetReducer;
