import axios from 'axios'
import Cookies from 'universal-cookie';
import {api_base_url} from "../../index";
const cookies = new Cookies();

const COOKIE_AGE=31536000

export const checkAuth=(dispatcher)=>{
    if(cookies.get('admin_token')===undefined || cookies.get('admin_token')===null)
        dispatcher(logoutDispatch())
    else
        dispatcher(loginDispatch())
}

export const login=(data,dispatcher,callBack)=>{

    dispatcher(loadingDispatch())
    axios.post(api_base_url+'/admin/auth/login',data).then(res=>{  
        cookies.set('admin_token',res.data.access_token,{ path: '/', maxAge: COOKIE_AGE }) //setting token
        checkAuth(dispatcher)
    }).catch(err=>{
console.log(err);
        if(err.response!==undefined){
        switch(err.response.status){
            case 404:
                callBack('No admin exists with this phone number')
                break
            case 401:
                callBack('Invalid phone no or password')
                break
            case 500:
                callBack('Internal server error')
                break
            default:
                callBack('unknown error');
                break;
        }
    }else{
        callBack('unknown error');  
    }
        checkAuth(dispatcher)
    })
}



export const logout=(dispatcher)=>{
    cookies.remove('admin_token',{ path: '/' })
    checkAuth(dispatcher)
}

const loginDispatch=()=>{
    return {
        type:'ADMIN_SIGNED_IN'
    }
}
const logoutDispatch=()=>{
    return {
        type:'ADMIN_SIGNED_OUT'
    }
}
const loadingDispatch=()=>{
    return {
        type:'ADMIN_AUTH_LOADING'
    }
}
