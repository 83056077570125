const scrutinyAuthReducer=(state=0,action)=>{
    switch(action.type){
        case 'SCRUTINY_SIGNED_IN':
            return 1;
        case 'SCRUTINY_AUTH_LOADING':
            return 0;
        case 'SCRUTINY_SIGNED_OUT':
            return -1;
        default:
            return state;
    }
}

export default scrutinyAuthReducer
