import React, {useEffect, useState} from 'react'
import HomeAppBar from "./HomeAppBar";
import {Button} from "@material-ui/core";
import {logout} from "../action/applicant/auth";
import {useDispatch, useSelector} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import WorkIcon from '@material-ui/icons/Work';
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import useTheme from "@material-ui/core/styles/useTheme";
import MenuIcon from '@material-ui/icons/Menu';
import deepPurple from "@material-ui/core/colors/deepPurple";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import EditIcon from '@material-ui/icons/Edit';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import DescriptionIcon from '@material-ui/icons/Description';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import LockIcon from '@material-ui/icons/Lock';
import {fetchProfile} from "../action/applicant/profile";
import LinearProgress from "@material-ui/core/LinearProgress";
import {setLoading, showToast} from "../App";
import ProfileImage from "./ProfileImage";
import EditProfile from "./EditProfile";
import SchoolIcon from '@material-ui/icons/School';
import Education from "./Education";
import Circulars from "./Circulars";
import Application from "./Application";
import ChangePassword from "./ChangePassword";
import {fetchEdu} from "../action/applicant/edu";

const drawerWidth = 250;


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    content: {
        flexGrow: 1
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    stepperRoot: {
        width: '100%',
    },
    canvasPaper: {
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
}));

const Console=props=>{

    var tmpMenu=1
    if(props.history.location.pathname.split('/')[2]==='circular')tmpMenu=3

    const applicantProfile=useSelector(state=>state.applicantProfile)
    const applicantEdu=useSelector(state=>state.applicantEdu)

    const dispatch=useDispatch()

    const { window } = props;
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [menu,setMenu]=useState(tmpMenu)

    useEffect(()=>{
        if(applicantProfile===null)
            fetchProfile(dispatch)
        if(applicantEdu===null)
            fetchEdu(dispatch)
    },[])

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    const homeClick=()=>{
        props.history.push('/')
    }

    const logoutClick=()=>{
        logout(dispatch)
    }

    const loadCircularMenu=()=>{
        setMenu(3)
    }

    const drawer = (
        <div style={{padding:'10px'}}>
            {
                applicantProfile===null?(
                    <LinearProgress/>
                ):(
                    <div>
                        <center>
                            <div>
                                <Button
                                    variant={'outlined'}
                                    startIcon={<HomeIcon/>}
                                    onClick={homeClick}
                                    color={'primary'}>
                                    Home
                                </Button>
                                <Button
                                    style={{marginLeft:'5px'}}
                                    variant={'outlined'}
                                    onClick={logoutClick}
                                    startIcon={<ExitToAppIcon/>}
                                    color={'secondary'}>
                                    Logout
                                </Button>
                            </div>
                        </center>
                        <ProfileImage/>
                        <Divider style={{marginTop:'10px'}}/>
                        <List>
                            <ListItem selected={menu==1} onClick={()=>{setMenu(1);props.history.push('/dashboard')}} button>
                                <ListItemIcon><AccountBoxIcon /> </ListItemIcon>
                                <ListItemText primary={'My Profile'} />
                            </ListItem>
                            <ListItem selected={menu==2} onClick={()=>{setMenu(2);props.history.push('/dashboard')}} button>
                                <ListItemIcon><SchoolIcon /> </ListItemIcon>
                                <ListItemText primary={'My Education'} />
                            </ListItem>
                            <ListItem selected={menu==3} onClick={()=>{setMenu(3);props.history.push('/dashboard')}} button>
                                <ListItemIcon><OfflineBoltIcon /> </ListItemIcon>
                                <ListItemText primary={'Circulars'} />
                            </ListItem>
                            <ListItem selected={menu==4} onClick={()=>{setMenu(4);props.history.push('/dashboard')}} button>
                                <ListItemIcon><DescriptionIcon /> </ListItemIcon>
                                <ListItemText primary={'My Applications'} />
                            </ListItem>
                            <ListItem selected={menu==5} onClick={()=>{setMenu(5);props.history.push('/dashboard')}} button>
                                <ListItemIcon><LockIcon /> </ListItemIcon>
                                <ListItemText primary={'Change Password'} />
                            </ListItem>
                            {/*<ListItem selected={menu==5} onClick={()=>{setMenu(5);props.history.push('/dashboard')}} button>
                                <ListItemIcon><AssignmentTurnedInIcon /> </ListItemIcon>
                                <ListItemText primary={'Admit Card'} />
                            </ListItem>
                            <ListItem selected={menu==6} onClick={()=>{setMenu(6);props.history.push('/dashboard')}} button>
                                <ListItemIcon><LockIcon /> </ListItemIcon>
                                <ListItemText primary={'My Account'} />
                            </ListItem>*/}
                        </List>
                        <Divider/>
                        <div style={{bottom:0,position:'absolute'}}>
                            <List>
                                <a style={{textDecoration:'none',color:'#222222'}} href={'https://www.buet.ac.bd/'}>

                                    <ListItem button>
                                        <ListItemIcon><img src={'buet_logo.png'} style={{width:'30px'}}/> </ListItemIcon>
                                        <ListItemText primary={'BUET Website'} />
                                    </ListItem>
                                </a>

                            </List>
                        </div>
                    </div>
                )
            }
        </div>
    );

    return(
        <div className={classes.root}>
            <CssBaseline />
            <AppBar  style={{backgroundColor:'#D92A2A',color:'#ffffff'}}  className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>

                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={homeClick}
                    >
                        <WorkIcon />
                    </IconButton>


                    <div  style={{position:'absolute',right:'10px',display:'flex'}}>
                        <IconButton
                            onClick={handleClick}
                            color="inherit"
                        >
                            <MoreVertIcon/>
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={homeClick}>Home</MenuItem>
                            <MenuItem onClick={()=>{setMenu(1);handleClose();}}>My profile</MenuItem>
                            <MenuItem onClick={()=>{setMenu(5)}}>Change Password</MenuItem>
                            <MenuItem onClick={logoutClick}>Logout</MenuItem>
                        </Menu>
                    </div>

                </Toolbar>
                <Divider/>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                skdd
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {
                    applicantProfile===null?(
                        <LinearProgress/>
                    ):(
                        <div>
                            {
                                menu===1?(
                                    <EditProfile/>
                                ):(
                                    menu===2?(
                                        <Education/>
                                    ):(
                                        menu===3?(
                                            <Circulars history={props.history}/>
                                        ):(
                                            menu===4?(
                                                <Application loadCircularMenu={loadCircularMenu} history={props.history}/>
                                            ):(
                                                menu===5?(
                                                    <ChangePassword/>
                                                ):(
                                                    <div/>
                                                )
                                            )
                                        )
                                    )
                                )
                            }
                        </div>
                    )
                }
            </main>
        </div>
    )

    return (
        <div>
            <Button
                variant={'contained'}
                onClick={homeClick}>
                Home
            </Button>
            <br/><br/>
            <Button
                variant={'contained'}
                onClick={logoutClick}>
                Logout
            </Button>
        </div>
    );
}


export default Console
