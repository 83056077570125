import axios from 'axios'
import Cookies from 'universal-cookie';
import {api_base_url} from "../../index";
import {logout} from "./auth";
import {setLoading, showToast} from "../../App";
import {updateProfile} from "./profile";


const cookies = new Cookies();

export const fetchEdu=(dispatcher)=>{
    setLoading(true)
    axios.get(api_base_url+'/applicant/edu',{headers:{authorization:'Bearer '+cookies.get('token')}}).then(res=>{
        setLoading(false)
        dispatcher(eduDispatch(res.data))
        console.log(res.data)
    }).catch(err=>{
        setLoading(false)
        if(err!==undefined && err.response!==undefined && err.response.status!==undefined && (err.response.status===401 || err.response.status===403)){
            logout(dispatcher)
        }else{
            showToast('Connectvity problem')
        }
    })
}

export const updateEdu=(dispatcher,data)=>{
    setLoading(true)
    console.log(data)
    return axios.put(api_base_url+'/applicant/edu',data,{headers:{authorization:'Bearer '+cookies.get('token')}}).then(res=>{
        setLoading(false)
        dispatcher(eduDispatch(data.data))
        showToast('Educational Qualifications Updated Successfully')
        return
    }).catch(err=>{
        setLoading(false)
        if(err!==undefined && err.response!==undefined && err.response.status!==undefined && (err.response.status===401 || err.response.status===403)){
            logout(dispatcher)
        }else{
            console.log(err)
            showToast('Connectvity problem')
        }
        return
    })
}

// export const uploadImage=(dispatcher,file,profile)=>{
//     setLoading(true)
//     const data = new FormData()
//     data.append('file', file, file.name)
//     axios.post(api_base_url+'/file/upload',data,{
//         headers: {
//             'Content-Type': 'multipart/form-data',
//             authorization:'Bearer '+cookies.get('token')
//         }
//     }).then(res => {
//         setLoading(false)
//         var updatedProfile={...profile}
//         updatedProfile['image']=res.data.url
//         updateProfile(dispatcher,updatedProfile)
//         showToast('Image updated successfully');
//     }).catch(err=>{
//         setLoading(false)
//         if(err!==undefined && err.response!==undefined && err.response.status!==undefined && (err.response.status===401 || err.response.status===403)){
//             logout(dispatcher)
//         }else{
//             showToast('Connectvity problem')
//         }
//     })
// }

export const eduDispatch=data=>{
    return{
        type:'APPLICANT_EDU_UPDATE',
        data:data
    }
}

export const uploadFile=file=>{
    const data = new FormData()
    data.append('file', file, file.name)
    return axios.post(api_base_url+'/file/upload',data,{
        headers: {
            'Content-Type': 'multipart/form-data',
            authorization:'Bearer '+cookies.get('token')
        }
    }).then(res => {
        return res.data.url
    }).catch(err=>{
        setLoading(false)
        showToast('Connectvity problem')
    })
}
