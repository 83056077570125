// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyD7CniLRVfq0TjDxZO46rlQ_DFSp1rzmx4",
    authDomain: "bjpfeedback-98731.firebaseapp.com",
    projectId: "bjpfeedback-98731",
    storageBucket: "bjpfeedback-98731.appspot.com",
    messagingSenderId: "393636312779",
    appId: "1:393636312779:web:af67bc64dcc799dd814d4e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);