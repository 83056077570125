const applicationListReducer=(state=null,action)=>{
    switch(action.type){
        case 'APPLICATION_LIST':
            return action.data;
        default:
            return state;
    }
}

export default applicationListReducer;
