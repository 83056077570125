import React,{useRef,useEffect} from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from '@material-ui/icons/Lock';
import EmailIcon from '@material-ui/icons/Email';
import TextField from "@material-ui/core/TextField";
import {schema} from "./validation/loginValidation"
import { makeStyles } from "@material-ui/core"
import { showToast } from '../App';
import DialogActions from "@material-ui/core/DialogActions";
import {useSelector,useDispatch} from 'react-redux'
import {login,checkAuth} from "../action/scrutiny/auth"
import LinearProgress from "@material-ui/core/LinearProgress";
import UpdatePass from "./UpdatePass"
import {logout} from "../action/scrutiny/auth"
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';

const useStyles = makeStyles({


    root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"

    },

})

const ScrutinyHome=props=>{


    const classes = useStyles()
    const emailRef=useRef()
    const passRef=useRef()
    const scrutineerAuth=useSelector(state=>state.scrutineerAuth)
    const dispatch=useDispatch()

    
    useEffect(()=>{
      
        checkAuth(dispatch);
     
    },[])  

    const loginClick=()=>{
 
        const emailVal=emailRef.current.value;
        const passVal=passRef.current.value;

        if(!emailVal|| ! passVal){
      showToast("All fields are required")
        }
        else{
        schema.validate({
        
    
            email: emailVal,
            password:passVal
     
              }).then((data)=>{
                login( {
                    login: data.email,
                    password: data.password,
                    type:'scrutiny'
                  },dispatch,showToast)

              })
              .catch(function (err) {
            
        
               showToast(err.errors[0]);
               
         });
        }

    }
    return (
        <div className={classes.root}> 
{

(  (scrutineerAuth===0 || scrutineerAuth===-1 ))?   (

        <Dialog open={true}>

        {
scrutineerAuth===0? <LinearProgress/> :null
    }

        <DialogTitle>Login</DialogTitle>
    <DialogContent className={classes.root}>
        <TextField
            inputRef={emailRef}
            style={{marginTop:'20px'}}
            autoFocus
            margin="dense"
            label="Email"
            type="email"
            variant="outlined"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <EmailIcon color='primary'/>
                    </InputAdornment>
                ),
                style: {
                    padding: 2
                }
            }}
        />
        <TextField
            inputRef={passRef}
            style={{marginTop:'10px',marginBottom:'20px'}}
            autoFocus
            margin="dense"
            label="Password"
            type="password"
            variant="outlined"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <LockIcon color='primary'/>
                    </InputAdornment>
                ),
                style: {
                    padding: 2
                }
            }}
        />
    </DialogContent>
    <DialogActions>
        <Button  color="primary" onClick={loginClick}>
            Login
        </Button>
    </DialogActions>
            </Dialog>

 ):(<UpdatePass/>)

}


        </div>
    );
}

export default ScrutinyHome;
