import React, {useRef, useState} from 'react'
import { useHistory } from "react-router-dom";
import {BrowserRouter,Route} from 'react-router-dom';
import ApplicantHome from "./applicant/Home";
import AdminHome from "./admin/Home";
import ScrutinyHome from "./scrutiny/Home";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Loader from "react-loader-spinner";
import Verification from "./applicant/Verification";
import EmailIcon from '@material-ui/icons/Email';
import {Button, DialogActions, DialogTitle, Fab, TextField} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import firebase from 'firebase/app';
import './config/firebase_config'
import { getFirestore,collection,addDoc} from "firebase/firestore"


var showToast
var setLoading

function App() {

    const db = getFirestore();

    const [feedbackDialog,setFeedbackDialog]=useState(false)

    const fNameRef=useRef()
    const fFRef=useRef()


    const history=useHistory()
    const [loading,setL]=useState(false)
    setLoading=setL
    showToast=message=>{
        toast.dark(message, {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const feedbackClick=async ()=>{
        const name=fNameRef.current.value.trim()
        const feedback=fFRef.current.value.trim()
        if(name.length===0)
            showToast('Please provide your name')
        else{
            if(feedback.length===0)
                showToast('Please provide your feedback')
            else{
                setLoading(true)
                try{
                    await addDoc(collection(db, "feedbacks"), {
                        name: name,
                        feedback: feedback,
                        timestamp:Date.now()
                    });
                    showToast('Feedback Submitted Successfully...')
                    setFeedbackDialog(false)
                }catch (e) {
                    console.log(e)
                    showToast('Error Occurred')
                }finally {
                    setLoading(false)
                }
            }
        }
    }

    return (
        <div>
            <Dialog open={loading}>
                <DialogContent>
                    <Loader
                        type="Oval"
                        color="#00BFFF"
                        height={100}
                        width={100}
                    />
                </DialogContent>
            </Dialog>
            <Dialog open={feedbackDialog}>
                <DialogTitle>
                    Feedback
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                variant={'outlined'}
                                label={'Your Name'}
                                inputRef={fNameRef}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant={'outlined'}
                                label={'Feedback'}
                                multiline
                                rows={7}
                                inputRef={fFRef}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color={'secondary'}
                        onClick={()=>{setFeedbackDialog(false)}}
                        >
                        Cancel
                    </Button>
                    <Button
                        color={'primary'}
                        onClick={feedbackClick}
                        >
                        Send Feedback
                    </Button>
                </DialogActions>
            </Dialog>
            <ToastContainer
                position="bottom-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <BrowserRouter history={history}>
                <Route path = "/" component={ApplicantHome}/>
                <Route path = "/admin" exact component={AdminHome}/>
                <Route path = "/scrutiny" exact component={ScrutinyHome}/>
                <Route path = "/verify/:token" exact component={Verification}/>
            </BrowserRouter>
            <Fab onClick={()=>{setFeedbackDialog(true)}} style={{position:'fixed',right:'20px',bottom:'20px'}} color="primary" aria-label="add">
                <EmailIcon />
            </Fab>
        </div>
    );
}

export default App;
export {showToast,setLoading}
