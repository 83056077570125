import React, { useState,useRef,useEffect } from 'react'
import { makeStyles } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"

import Button from "@material-ui/core/Button";
import DrawerList from "./DrawerList"
import InputAdornment from "@material-ui/core/InputAdornment";
import { showToast } from "../App";
import TextField from "@material-ui/core/TextField";
import {useSelector,useDispatch} from 'react-redux'
import {schema} from "./validation/passwordValidation"
import { reset } from '../action/admin/resetPass';
import CircularProgress from '@material-ui/core/CircularProgress';
const useStyles = makeStyles((theme)=>({


    root: {
        
            display:"flex",
            marginLeft:220,
            height:150,
            marginTop:60,
            marginBottom:50
          
            
        

    },
    circularProgress: {
        marginLeft: 0,
        marginRight: theme.spacing.unit,
         color:'white'
      },
    textField:{
        width:'85%',
       marginTop:30,
       marginLeft:40
       
   },
    cont: {
        width: "100%",

        margin: "30"
    },
    
   

}))
const ResetPass = props => {
    
   

    const currentPassRef=useRef();
    const newPassRef=useRef();
    const newRePassRef=useRef();
    const  loading=useSelector(state=>state.adminPasswordReset);
    const dispatch=useDispatch()


    const classes = useStyles()

  const handleSubmit=()=>{

    let cp=currentPassRef.current.value;
    let np=newPassRef.current.value;
    let nrp=newRePassRef.current.value;

    if(np!==nrp){
        showToast("Password didn't match")
    }
    else{

schema.validate({
    password:np
}).then(data=>{
reset({old_password:cp,new_password:np},dispatch,showToast)

}).catch(err=>{
    showToast(err.errors[0])
})

    }


  }

   
    return (
        
 <div className={classes.root}>
     <Grid container >
          <Grid item xs={12} >

          <h2 style={{marginLeft:50}}>Reset Password</h2>

          </Grid>
    
         

         <Grid item xs={12} md={6}>
     <TextField
      
      className={classes.textField}
      inputRef={currentPassRef}
      variant="outlined"
      type="password"
      
      label="Current Password"
      fullWidth
      required

     
      
      
      />
      </Grid>
      <Grid item xs={12} md={6}>

       <TextField
      
      className={classes.textField}
      inputRef={newPassRef}
      variant="outlined"
      type="password"
      
      label="New Password"
      fullWidth
      required

     
      
      
      />
      </Grid>
      <Grid item xs={12} md={6}>
       <TextField
      
      className={classes.textField}
      inputRef={newRePassRef}
      variant="outlined"
      type="password"
      
      label="Retype New Password"
      fullWidth
      required

     
      
      
      />
       </Grid>
       <Grid item xs={12} md={6}>       
       <Button onClick={handleSubmit} style={{marginTop:50,marginLeft: 45}} className={classes.button} variant="contained" color="primary">
       {loading==0?<CircularProgress className={classes.circularProgress} size={20}/>:null}
       {loading==0?'Loading...':'Save Changes'}

 
</Button>
</Grid>
</Grid>
</div>
    );
}

export default ResetPass;
