import axios from 'axios'
import Cookies from 'universal-cookie';
import {api_base_url} from "../../index";


const cookies = new Cookies();

export const reset=(data,dispatcher,callBack)=>{

    dispatcher(loadingDispatch())

    axios.put(api_base_url+'/admin/profile/changepassword',data,{headers:{authorization:'Bearer '+cookies.get('admin_token')}}).then(res=>{  
       dispatcher(passwordResetDispatch());
       callBack("password reset successful");
       

    }).catch(err=>{
        if(err.response!==undefined){
        switch(err.response.status){
            case 401:
                callBack('Invalid password')
                break
            case 404:
                callBack('User not found')
                break
        
            case 500:
                callBack('Internal server error')
                break
            default:
                callBack('Unknown error');
                break;
        }}
        else{
            callBack('Unknown error');
        }
        dispatcher(passwordResetFailedDispatch());

    })


}

const loadingDispatch=()=>{
    return {
        type:'PASSWORD_RESET_LOADING'
    }
}
const passwordResetDispatch=()=>{
     return {
        type:'PASSWORD_RESET'
    }
}

const passwordResetFailedDispatch=()=>{
    return {
        type:'PASSWORD_NOT_RESET'
    }
}
