import * as yup from 'yup';
import { setLocale } from 'yup';
setLocale({
    mixed: {
      default: 'Validation error',
    },
  
  });
export const  schema = yup.object().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),

  department:yup.string().required(),
  designation:yup.string().required()

});

