import React, {useEffect, useState} from 'react'
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import {fetchCirculars} from "../action/applicant/application";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
import './circular.css'

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    containerRoot:{
        flexGrow:1,
        padding: theme.spacing(1)
    }
}))

const CircularList=props=>{

    const classes = useStyles()

    const dispatch=useDispatch()
    const [circulars,setCirculars]=useState(null)

    const loadCirculars=body=>{
        setCirculars(body.data)
    }

    useEffect(()=>{
        fetchCirculars(dispatch,loadCirculars)
    },[])

    return(
        <div className={classes.containerRoot}>
            {
                circulars===null?(
                    <LinearProgress/>
                ):(
                    <Grid direction='row' alignItems="stretch" container spacing={1}>
                        {
                            circulars.map(circular=>{
                                return(
                                    <Grid  style={{minHeight:'100%'}} item xs={6} md={3} lg={2}>
                                        <Link color="inherit" to={'/dashboard/circular/'+circular.circular_id}>
                                            <Card style={{height:'100%'}} className={classes.root}>
                                                <CardActionArea>
                                                    <center>
                                                        <img src={'buet_logo.png'} style={{width:'60%',padding:'10%',marginBottom:'-10%'}}/>
                                                    </center>
                                                    <CardContent>
                                                        <Typography gutterBottom variant="h5" component="h2">
                                                            {circular.title}
                                                        </Typography>
                                                        <Typography variant="body2" color="textSecondary" component="p">
                                                            Published on : {circular.published}<br/>
                                                            Application deadline : {circular.deadline}
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </Link>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                )
            }
        </div>
    )
}

export default CircularList
