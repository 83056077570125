import React, { useState,useRef } from 'react'
import Button from '@material-ui/core/Button';
import { TextField } from "@material-ui/core";
import {create} from "../../action/admin/post/createPost"
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid"
import { update } from "../../action/admin/post/updatePost";
import {useSelector,useDispatch} from 'react-redux'
import { showToast } from "../../App";
import CircularProgress from '@material-ui/core/CircularProgress';
import {fetchRecentCircular} from "../../action/admin/circular/recentCircular"
import LinearProgress from "@material-ui/core/LinearProgress";
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import InputAdornment from "@material-ui/core/InputAdornment";
import { FormControlLabel, Switch } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display:"flex",
        marginLeft:220,
        height:150,
        marginTop:60,
        marginBottom:50
      
        
        },
        header:{
paddingLeft:40,
marginTop:30
        },
        circularProgress: {
            marginLeft: 0,
            marginRight: theme.spacing.unit,
             color:'white'
          },
        
    textField:{
        width:'85%',
          marginTop:40,
          marginLeft:40,
          
          
      },
      dateField:{
        width:'75%',
          marginTop:40,
          marginLeft:40,
          
          
      },
     
}))

const PostForm = (props) => {
    const classes=useStyles()
    const dispatch=useDispatch();
    const  loading=useSelector(state=>state.adminPostCreation);
    const  recentCircular=useSelector(state=>state.recentCircular);
    const  update_loading=useSelector(state=>state.adminPostUpdate);
    const circularList =useSelector(state=>state.circularList);
    const [liveState,setLiveState]=useState(true);
    const titleRef=useRef()
    const circularTextRef=useRef()
    const salaryScaleRef=useRef()
    const quantityRef=useRef()
    const eduReqRef=useRef()
    const expReqRef=useRef()
    const restrictionRef=useRef()
    const extraRef=useRef()
    const examDateRef=useRef()
    const examVenueRef=useRef()
    const feeRef=useRef()
   const timeRef=useRef();
   const codeRef=useRef();
   const days=["রবিবার","সোমবার ","মঙ্গলবার","বুধবার ","বৃহস্পতিবার ","শুক্রবার","শনিবার"]

var finalEnlishToBanglaNumber={'0':'০','1':'১','2':'২','3':'৩','4':'৪','5':'৫','6':'৬','7':'৭','8':'৮','9':'৯'};
 
  String.prototype.getDigitBanglaFromEnglish = function () {
    var str = this;
    for (var x in finalEnlishToBanglaNumber) {
        str = str.replace(new RegExp(x, 'g'), finalEnlishToBanglaNumber[x]);
    }
    return str;
};

const handleLiveChange=()=>{

let v=liveState;
setLiveState(!v);

}
    const submitClick=()=>{
if(!examDateRef.current.value || !titleRef.current.value || !examVenueRef.current.value  || ! codeRef.current.value ){
showToast("You can't keep the fields empty ")
}
else if(codeRef.current.value.length !=3){
showToast("Code must be of length 3 ")

}

else{

let date=new Date(examDateRef.current.value);
var day = date.getUTCDay();
var dayNo=String(date.getUTCDate());
var month=String(date.getMonth()+1);
var f_year=String(date.getUTCFullYear());
var b_date=dayNo.getDigitBanglaFromEnglish()+"/"+month.getDigitBanglaFromEnglish()+ "/"+f_year.getDigitBanglaFromEnglish();
const c_date=examDateRef.current.value+";"+ b_date+","+days[day]+","+timeRef.current.value;
console.log(date);      
      const data={
          title:titleRef.current.value,
          
          circular_id:props.circularId,
          circular_text:circularTextRef.current.value,
          salary_scale:"",
          quantity:"",
          edu_req:"",
          exp_req:"",
          restriction:"",
          extra_info:"",
          date:c_date,
          venue:examVenueRef.current.value,
          fee:feeRef.current.value,
         code:codeRef.current.value,
         isLive:liveState
          
      }
      
  
       create(data,dispatch,showToast);

}
      }
    const resetClick=()=>{
        titleRef.current.value=''
        circularTextRef.current.value=''
        // salaryScaleRef.current.value=''
        // quantityRef.current.value=''
        // eduReqRef.current.value=''
        // expReqRef.current.value=''
        // restrictionRef.current.value=''
        examDateRef.current.value=''
        examVenueRef.current.value=''
         feeRef.current.value='' 
        
        //extraRef.current.value=''
console.log(recentCircular)
      }

if(recentCircular===null){
 return  <LinearProgress style={{marginTop:63}}/>
}
return (
<div className={classes.root}>


<Grid container >
<Grid item xs={12} > <h2 className={classes.header}>Create Post Under Circular - {recentCircular instanceof Array?recentCircular[0].title:recentCircular!=null?recentCircular.title:null}</h2></Grid>

<Grid item  xs={12} md={6} > 
      <TextField
      inputRef={titleRef}
      className={classes.textField}
      variant="outlined"
      label="Title"
      fullWidth
  
      
      
      
      />
      </Grid>
      
{/*        
      <Grid item  xs={12} md={6}> 
      <TextField
       inputRef={quantityRef}
      className={classes.textField}
      variant="outlined"
      
      label="Quantity"
    
      fullWidth
      
      
      />
      
      </Grid> */}
      
      
      {/* <Grid item  xs={12} md={6}> 
      <TextField
        multiline
        rows={5}
        rowsMax={5}
       inputRef={eduReqRef}
      className={classes.textField}
      variant="outlined"
      label="Education Required"
    
      fullWidth
      
      
      /> 
      
      </Grid>*/}

      {/* <Grid item  xs={12} md={6}> 
      <TextField
        multiline
        rows={5}
        rowsMax={5}
       inputRef={expReqRef}
      className={classes.textField}
      variant="outlined"
      label="Experience Required"
    
      fullWidth
      
      
      />
      
      </Grid> */}
      {/* <Grid item  xs={12} md={6}> 
      <TextField
        multiline
        rows={5}
        rowsMax={5}
       inputRef={restrictionRef}
      className={classes.textField}
      variant="outlined"
      label="Restrictions"
    
      fullWidth
      
      
      />
      
      </Grid> */}
      {/* <Grid item  xs={12} md={6}> 
      <TextField
        multiline
        rows={5}
        rowsMax={5}
       inputRef={salaryScaleRef}
      className={classes.textField}
      variant="outlined"
      label="Salary Scale"
    
      fullWidth
      
      
      />
      
      </Grid> */}

{/*       
<Grid item  xs={12} md={6} > 
      <TextField
      multiline
      rows={5}
      rowsMax={5}
       inputRef={extraRef}
      className={classes.textField}
      variant="outlined"
      label="Extra Info"
      fullWidth
  
      
      
      
      />
      </Grid> */}
     
      <Grid item  xs={12} md={6} >  
      <TextField
                                
                                type={'date'}
                                inputRef={examDateRef}
                             
                                defaultValue=""
                                variant={'outlined'}
                                className={classes.textField}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start" style={{marginTop:"20px"}}>Exam Date : </InputAdornment>,
                                }}
                                fullWidth/></Grid>
         <Grid item  xs={12} md={6}> 
      <TextField
       multiline
       rows={5}
       rowsMax={5}
       inputRef={examVenueRef}
      className={classes.textField}
      variant="outlined"
      
      label="Exam Venue"
    
      fullWidth
      
      
      />
      
      </Grid>
       <Grid item  xs={12} md={6}> 
      <TextField
        multiline
        rows={5}
        rowsMax={5}
       inputRef={circularTextRef}
      className={classes.textField}
      variant="outlined"
      label="Circular Text"
    
      fullWidth
      
      
      />
      
      </Grid>
      
     <Grid item  xs={12} md={6} > 
      <TextField
      inputRef={feeRef}
      className={classes.textField}
      variant="outlined"
      label="Fee"
      fullWidth
  
      
      
      
      />
    </Grid>
  <Grid item  xs={12} md={6} > 
      <TextField
      inputRef={codeRef}
      className={classes.textField}
      variant="outlined"
      label="Code"
      fullWidth
  
      
      
      
      />
    </Grid>


<Grid item  xs={12} md={12} > 
      <TextField
      inputRef={timeRef}
     className={classes.textField}
      variant="outlined"
      label="Time"
      fullWidth
  
      
      
      
      />
    </Grid>
    
<Grid item  xs={12} md={12} > 

<FormControlLabel

style={{marginLeft:"40px"}}
     
        control={
          <Switch
            checked={liveState}
            onChange={handleLiveChange}
            value={liveState}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        }
        label='Live?'
        labelPlacement="start"
      />
    </Grid>

      <Grid item xs={12} md={6} >
       <Button  startIcon={<SaveIcon/>} onClick={submitClick} style={{marginTop:40,marginLeft: 45,marginBottom:40}} className={classes.button} variant="contained" color="primary">
      
      
      {loading==0?<CircularProgress className={classes.circularProgress} size={20}/>:null}
      {loading==0?'Saving...':'Save'}
      </Button>
      
      
         <Button onClick={resetClick} style={{marginTop:40,marginLeft: 45,marginBottom:40 }} variant="contained">Reset</Button>

         </Grid>
         <Grid item xs={12} md={6} >
      
      
      
      <Button startIcon={<ArrowBackIcon/>} onClick={()=>{props.setState(4);}} style={{marginTop:40,marginLeft: 45,marginBottom:40 }} variant="contained" color="primary">Back</Button>
</Grid>
     


</Grid>
</div>

);


}
export default PostForm;