const adminSelectApplicantsReducer=(state=-1,action)=>{
    switch(action.type){
        case 'APPLICANT_SELECTED':
            return 1;
        case 'SELECTION_LOADING':
            return 0;
        case 'APPLICANT_NOT_SELECTED':
            return -1;
        default:
            return state;
    }
}

export default adminSelectApplicantsReducer
