import React, {useEffect, useState} from 'react'
import {verify} from "../action/applicant/auth";
import {showToast} from "../App";
import {Button, Dialog, DialogContent} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const Verification=props=>{

    var token=props.match.params.token

    const [ver,setVer]=useState(false)
    const [isVerified,setVerified]=useState(false)

    const successCallback=()=>{
        showToast('Verification Succeeded')
        setVer(true)
        setVerified(true)
    }

    const failureCallback=()=>{
        showToast('Verification Failed')
        setVer(true)
        setVerified(false)
    }

    useEffect(()=>{
        verify(token,successCallback,failureCallback)
    },[])

    return(
        <div>
            <Dialog open={ver}>
                {
                    isVerified?(
                        <DialogContent>
                            <center>
                            <Typography>
                                Your verification was successful, now login to your account
                            </Typography>
                            <Button
                                onClick={()=>{props.history.push('/dashboard');window.location.reload()}}
                                color={'primary'}
                                variant={'contained'}
                                >
                                Login
                            </Button>
                            </center>
                        </DialogContent>
                    ):(
                        <DialogContent>
                            <Typography>
                                Verification Unsuccessful
                            </Typography>
                            <Button
                                onClick={()=>{props.history.push('/dashboard');window.location.reload()}}
                                color={'primary'}
                                variant={'contained'}
                            >
                                Create a new account
                            </Button>
                        </DialogContent>
                    )
                }
            </Dialog>
        </div>
    )
}

export default Verification