import React, {forwardRef,useState, useEffect, useImperativeHandle, useRef} from 'react'
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {getDistricts,getSubDistricts} from "../util";

const Address=forwardRef((props,ref)=>{

    const areaRef=useRef()
    const disRef=useRef()
    const houseRef=useRef()
    const postRef=useRef()
    const postCodeRef=useRef()
    const subDisRef=useRef()
    const unionRef=useRef()

    const [district,setDistrict]=useState(props.data.dis)

    useImperativeHandle(ref, () => ({
        getData(){
            var data={
                area:areaRef.current.value,
                dis:disRef.current.value,
                house:houseRef.current.value,
                post:postRef.current.value,
                post_code:parseInt(postCodeRef.current.value),
                sub_dis:subDisRef.current.value,
                union:unionRef.current.value
            }
            if(isNaN(parseInt(postCodeRef.current.value)))data["post_code"]=0
            else data["post_code"]=parseInt(postCodeRef.current.value)
            return data
        }
    }));

    const handleDistrictChange=event=>{
        setDistrict(event.target.value)
    }

    return(
        <Grid container spacing={2}>
            <Grid style={{fontSize:'1.2em'}} item xs={12}>
                {props.title}
            </Grid>
            <Grid item xs={12}>
                <TextField
                    defaultValue={props.data.house}
                    inputRef={houseRef}
                    variant={'outlined'}
                    label={'House/Road no'}
                    fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    defaultValue={props.data.area}
                    inputRef={areaRef}
                    variant={'outlined'}
                    label={'Village/Area'}
                    fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    defaultValue={props.data.union}
                    inputRef={unionRef}
                    variant={'outlined'}
                    label={'Union'}
                    fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    defaultValue={props.data.post}
                    inputRef={postRef}
                    variant={'outlined'}
                    label={'Post'}
                    fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <TextField
                    defaultValue={props.data.post_code}
                    inputRef={postCodeRef}
                    variant={'outlined'}
                    label={'Postal Code'}
                    type={'number'}
                    fullWidth/>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">District</InputLabel>
                    <Select
                        inputRef={disRef}
                        onChange={handleDistrictChange}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={props.data.dis}
                        label="A">
                        <MenuItem value={0}>Select District</MenuItem>
                        {
                            getDistricts().map((d,i)=>{
                                return(
                                    <MenuItem value={i+1}>{d.name}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth variant="outlined">
                    <InputLabel id="demo-simple-select-outlined-label">Sub-District</InputLabel>
                    <Select
                        inputRef={subDisRef}
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        defaultValue={props.data.sub_dis}
                        label="Age">
                        <MenuItem value={0}>Select Sub-District</MenuItem>
                        {
                            getSubDistricts(district).map((sD,i)=>{
                                return(
                                    <MenuItem value={i+1}>{sD.name}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
})

export default Address
