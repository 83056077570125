import React,{useState,useRef,useEffect} from "react"
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment";
import {useSelector,useDispatch} from 'react-redux'
import {uploadCircularImage} from "../../action/admin/circular/createCircular"
import LinearProgress from "@material-ui/core/LinearProgress";
import { showToast } from "../../App";
import {update} from "../../action/admin/circular/updateCircular"
import SaveIcon from '@material-ui/icons/Save';
import {create} from "../../action/admin/circular/createCircular"
const useStyles = makeStyles((theme) => ({


    textField:{
         width:'85%',
        marginTop:30,
        marginLeft:40
        
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
   
    paper: {
        position: 'absolute',
        width: 708,
        height:550,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        
        borderRadius:20,
        padding: theme.spacing(2, 4, 3),
      },
      button:{
        marginTop:50,
        marginLeft:40
    },
    input: {
      display: 'none',
  },
  img: {
      marginTop: 20,
      marginLeft:40,
      height:200,
      width:200
  }
}))
const CircularModalBody = (props) => {

    const classes=useStyles()
    const dispatch=useDispatch()
    const circularLoadingState=useSelector(state=>state.adminCircularCreation)
    const circularUpdateLoadingState=useSelector(state=>state.adminCircularUpdate)
const [id,setId]=useState('')
    const [img,setImg]=useState('')
    const titleRef=useRef()
    const yearRef=useRef(); 
    const publishedDateRef=useRef()
    
    const dateRef=useRef()
    useEffect(()=>{
    if(props.circular){
      setImg(props.circular.image)
      setId(props.circular.circular_id)
    }

     },[])
    

    const fileHandler=(e)=>{
        console.log(e.target.files[0])
        setImg(e.target.files[0])
    }
    
    const submitClick=()=>{
console.log(id)
      const title= titleRef.current.value;
      const date =dateRef.current.value
      const published=publishedDateRef.current.value;
      const year= yearRef.current.value
if(!title || !date || !published  || (!year) || (!img)){
  showToast('All fields and circular image are required')
}
else if(isNaN(year)){
  showToast('year must be a number')
}
else if(year.length!=4){
showToast('year must be of length 4')
}
else{
if(typeof(img)==="string"){
console.log('creating',id)
update({circular_id:id,title,published,image:img,deadline:date,year:year},dispatch)
}else{
  uploadCircularImage(dispatch,{circular_id:id,title,published,image:img,deadline:date,text:props.text,year:year})
//create({circular_id:id,title,published,image:null,deadline:date,year:year},dispatch)
}
}
    }

    return (  

        <div  className={classes.paper}>
     
      {
        circularLoadingState===0 || circularUpdateLoadingState===0 ? <LinearProgress/>:null
      }
        <Grid container >
         <Grid item xs={12} > <h2>{props.text} Circular</h2></Grid>
        <Grid item xs={12} md={6} > 
      <TextField
      
      className={classes.textField}
      inputRef={titleRef}
      variant="outlined"
      defaultValue={props.circular?props.circular.title:''}
      label="Title"
      fullWidth
      required

      
      
      
      />
   
      
   </Grid>
   <Grid item xs={12} md={6}> 
      <TextField
                                
                                    type={'date'}
                                    inputRef={publishedDateRef}
                                  
                                    defaultValue={props.circular?props.circular.published:''}
                                    variant={'outlined'}
                                    className={classes.textField}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start" style={{marginTop:"2px"}}>Published Date : </InputAdornment>,
                                    }}
                                    fullWidth/>
      </Grid>

   <Grid item xs={12} md={6}> 
      <TextField
                                
                                    
                                    inputRef={yearRef}
                                  
                                    defaultValue={props.circular?props.circular.year:''}
                                    variant={'outlined'}
                                    className={classes.textField}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start" style={{marginTop:"2px"}}>Year : </InputAdornment>,
                                    }}
                                    fullWidth/>
      </Grid>


      <Grid item xs={12} md={6} > 
      <TextField
                                
                                    type={'date'}
                                    inputRef={dateRef}
                                
                                    defaultValue={props.circular?props.circular.deadline:''}
                                    variant={'outlined'}
                                    className={classes.textField}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start" style={{marginTop:"2px"}}>Deadline : </InputAdornment>,
                                    }}
                                    fullWidth/>
      </Grid>
      <Grid item xs={12} md={6}> 
      <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        onChange={fileHandler}
      />
      <label htmlFor="contained-button-file">
        <Button  className={classes.button}  variant="contained" color="primary" component="span">
          Upload Circular
        </Button>
      </label>
      </Grid>
      <Grid item xs={12} md={6}> 
      <Button startIcon={<SaveIcon/>} className={classes.button} onClick={submitClick} variant="contained" color="primary" component="span">
          Save
        </Button>
        <Button onClick={()=>props.method(false)} className={classes.button} color="primary" variant="contained">Exit</Button>

      </Grid>
      <Grid item xs={12} md={6}> 
       {img!='' && img!=null ?<img className={classes.img} src={typeof(img)==="string"?img:URL.createObjectURL(img)}/>:null}
      
      </Grid>
      </Grid>
      </div>
    );
}
 
export default CircularModalBody;