import axios from 'axios'
import Cookies from 'universal-cookie';
import {api_base_url} from "../../../index";
import {showToast} from "../../../App"
import {update} from "./updateCircular"
import { fetchCircularList } from './circularList';

const cookies = new Cookies();

export  const create=(data,dispatcher)=>{

    

    axios.post(api_base_url+'/admin/publish/circular/create',data,{headers:{authorization:'Bearer '+cookies.get('admin_token')}}).then(res=>{  
       dispatcher(createCircularDispatch());
       showToast("Successfully created a circular");
       fetchCircularList(dispatcher);

    }).catch(err=>{
        if(err.response!==undefined){
        switch(err.response.status){
            case 409:
                showToast('Circular already exists!')
                break
        
            case 500:
                showToast('Internal server error')
                break
            default:
                showToast('Unknown error');
                break;
        }}
        else{
            showToast('Connectivity problem');
        }
        dispatcher(creationFailedDispatch());

    })


}
export const uploadCircularImage=(dispatcher,circularData)=>{
    dispatcher(loadingDispatch())
    const data = new FormData()
    data.append('file', circularData.image, circularData.image.name)
    axios.post(api_base_url+'/file/upload',data,{
        headers: {
            'Content-Type': 'multipart/form-data',
            authorization:'Bearer '+cookies.get('admin_token')
        }
    }).then(res => {

        circularData['image']=res.data.url;
        if(circularData.text==='Create'){
        console.log("created")
            create(circularData,dispatcher);
        }
        else{
        dispatcher(createCircularDispatch());
        update(circularData,dispatcher)

        }

        
   
  
    }).catch(err=>{
    console.log(err)
        if(err.response!==undefined){
            switch(err.response.status){
                case 401:
                    showToast('Unauthorized')
                    break
            
                case 500:
                    showToast('Internal server error')
                    break
                default:
                    showToast('Unknown error');
                    break;
            }}
            else{
                showToast('Unknown error');
            }
            dispatcher(creationFailedDispatch());
    })
}
const loadingDispatch=()=>{
    return {
        type:'CIRCULAR_CREATION_LOADING'
    }
}
const createCircularDispatch=()=>{
     return {
        type:'CIRCULAR_CREATED'
    }
}

const creationFailedDispatch=()=>{
    return {
        type:'CIRCULAR_NOT_CREATED'
    }
}
