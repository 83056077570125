const adminPostUpdateReducer=(state=-1,action)=>{
    switch(action.type){
        case 'POST_UPDATED':
            return 1;
        case 'POST_UPDATE_LOADING':
            return 0;
        case 'POST_NOT_UPDATED':
            return -1;
        default:
            return state;
    }
}

export default adminPostUpdateReducer
