const adminScrutinyCreationReducer=(state=-1,action)=>{
    switch(action.type){
        case 'SCRUTINY_CREATED':
            return 1;
        case 'CREATION_LOADING':
            return 0;
        case 'SCRUTINY_NOT_CREATED':
            return -1;
        default:
            return state;
    }
}

export default adminScrutinyCreationReducer
