import React,{useState,useEffect} from "react"
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid"
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import Button from "@material-ui/core/Button";
import CircularModalBody from "../modals/CircularModalBody"
import Modal from '@material-ui/core/Modal';
import DeleteIcon from '@material-ui/icons/Delete';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton'
import CardContent from '@material-ui/core/CardContent';
import EditIcon from '@material-ui/icons/Edit';
import ImageIcon from '@material-ui/icons/Image';
import {useSelector,useDispatch} from 'react-redux'
import LinearProgress from "@material-ui/core/LinearProgress";
import { deleteCircular } from "../../action/admin/circular/deleteCircular";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { showToast } from "../../App";
import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles((theme) => ({
    root: {
    display:"flex",
    marginLeft:220,
    height:150,
    marginTop:60,
    marginBottom:50
  
    
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        
      },
      card:{
        width:'85%',
        
        marginLeft:60,
        marginBottom:30,
        marginTop:20,
        '&:hover': {
          backgroundColor: '#87CEFA',
        },
      }
    
 }) )
const CircularList  = (props) => {
    const classes=useStyles()
    const circularList=useSelector(state=>state.circularList)
    const [open,setOpen]=useState(false)
    const [openImageModal,setOpenImageModal]=useState(false);
    const [img,setImg]=useState('')
    const [data,setData]=useState('');
    const [openEditModal,setOpenEditModal]=useState(false)
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteId,setId]=useState(0);
    const [renderedData,setRenderedData]=useState([])

    const deletionState=useSelector(state=>state.circularDelete)
    const dispatch=useDispatch()

    useEffect(()=>{
     console.log(circularList);
      let data=circularList.filter(circular=>{
        if(circular.title.toLowerCase().includes(props.text.toLowerCase())){
          return circular;
        }
      })
      setRenderedData(data)
     },[props.text,circularList]) 
      const deleteClick=(id)=>{

        setId(id);
        setOpenDialog(true);

      }

      const yesClick=()=>{
   
 
        deleteCircular(deleteId,dispatch,showToast);
       
       setOpenDialog(false);
     
   
     }
    return ( 
<div className={classes.root}>

<Grid container >
      <Grid item xs={12} md={3} >
      <h2 style={{marginLeft:60,fontWeight:"bold",marginTop:20}}>Circular List</h2>  
      </Grid> 
      <Grid item xs={12} md={9}  style={{marginTop:10}}>
      <Button  onClick={()=>setOpen(true)} startIcon={<ControlPointIcon/>} style={{marginTop:'10px'}}  color='primary' variant='outlined'>
                                    Create
                                </Button>
      </Grid>
      {
       circularList.length===0?(
        <Grid item xs={12}> 
          <h2 style={{marginLeft:20}} >There is no circular right now</h2>
        </Grid>):null
      }
{renderedData && renderedData.map(circular=>(
  <Grid key={circular.circular_id} item xs={12} md={12} >
    <Card   className={classes.card} >
    <CardHeader
       
      title={circular.title}
      subheader={'Created by : '+ circular.admin_name}
    />
    <div style={{display:"block",marginLeft:20 }}>
    <div >  Published : {circular.published} </div>
    <div style={{marginTop:10}} >  Deadline : {circular.deadline} </div>

     <div style={{marginTop:10}} >  Year : {circular.year} </div>
    </div>

      
<CardContent className={classes.content}>

<Tooltip title="Show image">
<IconButton aria-label="img" >
 <ImageIcon onClick={()=>{setImg(circular.image); setOpenImageModal(true)}} color="primary"/>
 </IconButton>
</Tooltip>
<Tooltip title="Edit">
<IconButton aria-label="edit" onClick={()=>{setData(circular);setOpenEditModal(true)} }>
 <EditIcon color="primary"/>
 </IconButton>
</Tooltip>
<Tooltip title="Delete">
<IconButton aria-label="delete"  onClick={()=>deleteClick(circular.circular_id)} >
 <DeleteIcon color="primary"/>
 </IconButton>

</Tooltip>


</CardContent>
    </Card>
 
</Grid>

))}
      </Grid>

<Modal
className={classes.modal}
 open={open}
 onClose={()=>setOpen(false)}
 aria-labelledby="create-modal"
 aria-describedby="create-modal"
>

<CircularModalBody text ={'Create'} method={setOpen} />

</Modal>

<Modal
className={classes.modal}
 open={openEditModal}
 onClose={()=>setOpenEditModal(false)}
 aria-labelledby="edit-modal"
 aria-describedby="edit-modal"
>

<CircularModalBody text ={'Edit'} method={setOpenEditModal} circular={data}  />

</Modal>

<Modal
className={classes.modal}
 open={openImageModal}
 onClose={()=>setOpenImageModal(false)}
 aria-labelledby="image-modal"
 aria-describedby="image-modal"
>

<img src={img} style={{width:600,height:600}} />

</Modal>
<Dialog
        open={openDialog}
        onClose={()=>setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {deletionState===0?<LinearProgress/>:null}
        <DialogTitle id="alert-dialog-title">{"Do you want to delete this circular?"}</DialogTitle>
      
        <DialogActions>
          <Button onClick={()=>setOpenDialog(false)} color="primary">
            No
          </Button>
          <Button onClick={yesClick} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
</div>

     );
}
 
export default CircularList;