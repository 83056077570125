import React, { useState,useRef,useEffect } from 'react'
import Button from '@material-ui/core/Button';
import { TextField } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";

import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid"
import { update } from "../../action/admin/post/updatePost";
import {useSelector,useDispatch} from 'react-redux'
import { showToast } from "../../App";
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FormControlLabel, Switch } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    root: {
        display:"flex",
        marginLeft:220,
        height:150,
        marginTop:60,
        marginBottom:50
      
        
        },
        header:{
paddingLeft:40,
marginTop:30
        },
        circularProgress: {
            marginLeft: 0,
            marginRight: theme.spacing.unit,
             color:'white'
          },
        
    textField:{
        width:'85%',
          marginTop:40,
          marginLeft:40,
          
          
      },
     
}))

const UpdatePostForm = (props) => {
    const classes=useStyles()
    const dispatch=useDispatch();
    const [circular,setCircular]=useState({});
    const  loading=useSelector(state=>state.adminPostUpdate);
    const [liveState,setLiveState]=useState(props.post.islive);
const days=["রবিবার","সোমবার ","মঙ্গলবার","বুধবার ","বৃহস্পতিবার ","শুক্রবার","শনিবার"]
    const titleRef=useRef()
    const circularTextRef=useRef()
    const salaryScaleRef=useRef()
    const quantityRef=useRef()
    const eduReqRef=useRef()
    const expReqRef=useRef()
    const restrictionRef=useRef()
    const extraRef=useRef()
    const examDateRef=useRef()
    const examVenueRef=useRef()
    const feeRef=useRef()
  const codeRef=useRef();
const timeRef=useRef();
var finalEnlishToBanglaNumber={'0':'০','1':'১','2':'২','3':'৩','4':'৪','5':'৫','6':'৬','7':'৭','8':'৮','9':'৯'};
 
  String.prototype.getDigitBanglaFromEnglish = function () {
    var str = this;
    for (var x in finalEnlishToBanglaNumber) {
        str = str.replace(new RegExp(x, 'g'), finalEnlishToBanglaNumber[x]);
    }
    return str;
};
const handleLiveChange=()=>{

  let v=liveState;
  setLiveState(!v);
  
  }
    const submitClick=()=>{
if(!examDateRef.current.value || !titleRef.current.value || !examVenueRef.current.value  || ! codeRef.current.value ){
showToast("You can't keep the fields empty ")
}
else if(codeRef.current.value.length !=3){
showToast("Code must be of length 3 ")

}
      
else{
let date=new Date(examDateRef.current.value);
var day = date.getUTCDay();
var dayNo=String(date.getUTCDate());
var month=String(date.getMonth()+1);
var f_year=String(date.getUTCFullYear());
var b_date=dayNo.getDigitBanglaFromEnglish()+"/"+month.getDigitBanglaFromEnglish()+ "/"+f_year.getDigitBanglaFromEnglish();
const c_date=examDateRef.current.value+";"+ b_date+","+days[day]+","+timeRef.current.value;
console.log(b_date); 
console.log(dayNo,month,f_year); 
      const data={
          title:titleRef.current.value,
          post_id:props.post.post_id,
          
          circular_id:props.post.circular_id,
          circular_text:circularTextRef.current.value,
          salary_scale:"",
          quantity:"",
          edu_req:"",
          exp_req:"",
          restriction:"",
          extra_info:"",
          date:c_date,
          venue:examVenueRef.current.value,
          fee:feeRef.current.value,
	code:codeRef.current.value,
  isLive:liveState
          
      }
      
  console.log(data);
       update(data,dispatch);
}
      }
    const resetClick=()=>{
        titleRef.current.value=''
        circularTextRef.current.value=''
        // salaryScaleRef.current.value=''
        // quantityRef.current.value=''
        // eduReqRef.current.value=''
        // expReqRef.current.value=''
        // restrictionRef.current.value=''
        examDateRef.current.value=''
        examVenueRef.current.value=''
        feeRef.current.value=''
 codeRef.current.value=''
        
        // extraRef.current.value=''
   
      }
return (
<div className={classes.root}>


<Grid container >
<Grid item xs={12} > <h2 className={classes.header}>Edit Post- {props.post.title} </h2></Grid>

<Grid item  xs={12} md={6} > 
      <TextField
    inputRef={titleRef}
      className={classes.textField}
      variant="outlined"
      label="Title"
      defaultValue={props.post?props.post.title:''}
      fullWidth
  
      
      
      
      />
      </Grid>
      
       
      {/* <Grid item  xs={12} md={6}> 
      <TextField
       inputRef={quantityRef}
      className={classes.textField}
      variant="outlined"
      defaultValue={props.post?props.post.quantity:''}
      label="Quantity"
    
      fullWidth
      
      
      />
      
      </Grid> */}
      
      {/* <Grid item  xs={12} md={6}> 
      <TextField
        multiline
        rows={5}
        rowsMax={5}
       inputRef={eduReqRef}
      className={classes.textField}
      variant="outlined"
      label="Education Required"
      defaultValue={props.post?props.post.edu_req:''}

      fullWidth
      
      
      />
      
      </Grid> */}
      {/* <Grid item  xs={12} md={6}> 
      <TextField
        multiline
        rows={5}
        rowsMax={5}
       inputRef={expReqRef}
      className={classes.textField}
      variant="outlined"
      label="Experience Required"
      defaultValue={props.post?props.post.exp_req:''}

      fullWidth
      
      
      />
      
      </Grid> */}
      {/* <Grid item  xs={12} md={6}> 
      <TextField
        multiline
        rows={5}
        rowsMax={5}
       inputRef={restrictionRef}
      className={classes.textField}
      variant="outlined"
      label="Restrictions"
      defaultValue={props.post?props.post.restriction:''}

      fullWidth
      
      
      />
      
      </Grid> */}
      {/* <Grid item  xs={12} md={6}> 
      <TextField
        multiline
        rows={5}
        rowsMax={5}
       inputRef={salaryScaleRef}
      className={classes.textField}
      variant="outlined"
      label="Salary Scale"
      defaultValue={props.post?props.post.salary_scale:''}

      fullWidth
      
      
      />
      
      </Grid>

       */}
{/* <Grid item  xs={12} md={6} > 
      <TextField
      multiline
      rows={5}
      rowsMax={5}
       inputRef={extraRef}
      className={classes.textField}
      variant="outlined"
      label="Extra Info"
      fullWidth
      defaultValue={props.post?props.post.extra_info:''}

      
      
      
      />
      </Grid> */}

      <Grid item  xs={12} md={6} >  
      <TextField
                                
                                type={'date'}
                                inputRef={examDateRef}
                             
                                defaultValue={props.post && props.post.date.length>0?props.post.date.split(';')[0]:''}
                                variant={'outlined'}
                                className={classes.textField}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start" style={{marginTop:"20px"}}>Exam Date : </InputAdornment>,
                                }}
                                fullWidth/></Grid>
      
      <Grid item  xs={12} md={6}> 
      <TextField
      multiline
      rows={5}
      rowsMax={5}
       inputRef={examVenueRef}
      className={classes.textField}
      variant="outlined"
      defaultValue={props.post?props.post.venue:''}
      label="Exam Venue"
    
      fullWidth
      
      
      />
      
      </Grid>


      <Grid item  xs={12} md={6}> 
      <TextField
        multiline
        rows={5}
        rowsMax={5}
       inputRef={circularTextRef}
      className={classes.textField}
      variant="outlined"
      label="Circular Text"
      defaultValue={props.post?props.post.circular_text:''}
      fullWidth
      
      
      />
      
      </Grid>



<Grid item  xs={12} md={6} > 
      <TextField
    inputRef={feeRef}
      className={classes.textField}
      variant="outlined"
      label="Fee"
      defaultValue={props.post?props.post.fee:''}
      fullWidth
  
      
      
      
      />
      </Grid>
<Grid item  xs={12} md={6} > 
      <TextField
     inputRef={codeRef}
      className={classes.textField}
      variant="outlined"
      label="code"
   defaultValue={props.post?props.post.code:''}
      fullWidth
  
      
      
      
      />
      </Grid>
<Grid item  xs={12} md={12} > 
      <TextField
      inputRef={timeRef}
      className={classes.textField}
     defaultValue={props.post && props.post.date.length && props.post.date.split(';')[1]?props.post.date.split(';')[1].split(',')[2]:''}
      variant="outlined"
      label="Time"
      fullWidth
  
      
      
      
      />
    </Grid>
    <Grid item  xs={12} md={12} > 

<FormControlLabel

style={{marginLeft:"40px",marginTop:"30px"}}
     
        control={
          <Switch
            checked={liveState}
            onChange={handleLiveChange}
            value={liveState}
            color="primary"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        }
        label='Live?'
        labelPlacement="start"
      />
    </Grid>


      
      <Grid item xs={12} md={6} >
       <Button onClick={submitClick} style={{marginTop:40,marginLeft: 45,marginBottom:40}} className={classes.button} variant="contained" color="primary">
      
      
      {loading==0?<CircularProgress className={classes.circularProgress} size={20}/>:null}
      {loading==0?'Updating...':'Update'}
      </Button>
      
      
         <Button onClick={resetClick} style={{marginTop:40,marginLeft: 45,marginBottom:40 }} variant="contained">Reset</Button>

         </Grid>

          <Grid item xs={12} md={6} >
      
      
      
         <Button startIcon={<ArrowBackIcon/>} onClick={()=>props.setState(4)} style={{marginTop:40,marginLeft: 45,marginBottom:40 }} variant="contained" color="primary">Back</Button>

         </Grid>
    </Grid>


</div>

);


}
export default UpdatePostForm;