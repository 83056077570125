import React from 'react'

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import WorkIcon from '@material-ui/icons/Work';
import DashboardIcon from '@material-ui/icons/Dashboard';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
}));

const HomeAppBar=props=>{
    const classes = useStyles();

    const dashboardClick=()=>{
        props.history.push('/dashboard')
    }

    const homeClick=()=>{
        props.history.push('/')
    }

    return (
        <div className={classes.grow}>
            <AppBar position="static" style={{backgroundColor:'#D92A2A'}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={homeClick}
                    >
                        <WorkIcon />
                    </IconButton>
                    <Typography className={classes.title} variant="h6" noWrap>
                        BUET Job Portal
                    </Typography>

                    <div className={classes.grow} />
                    <div>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={dashboardClick}
                        >
                            <DashboardIcon/>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default HomeAppBar
