import axios from 'axios'
import Cookies from 'universal-cookie';
import {api_base_url} from "../../../index";
import {showToast} from "../../../App"

const cookies = new Cookies();

export const selectApplicants=(data,dispatcher)=>{
    
    dispatcher(loadingDispatch())

    
console.log("ss",data)
    axios.post(api_base_url+'/admin/publish/post/select',data,{headers:{authorization:'Bearer '+cookies.get('admin_token')}}).then(res=>{  
       dispatcher(selectApplicantsDispatch());
       showToast("Successfully updated selectionlist");
       

    }).catch(err=>{
        console.log(err)
        if(err.response!==undefined){
        switch(err.response.status){
         
        
            case 500:
                showToast('Internal server error')
                break
            default:
                showToast('Unknown error');
                break;
        }}
        else{
            showToast('Connectivity problem');
        }
        dispatcher(selectionFailedDispatch());

    })



}
const loadingDispatch=()=>{
    return {
        type:'SELECTION_LOADING'
    }
}
const selectApplicantsDispatch=()=>{
     return {
        type:'APPLICANT_SELECTED'
    }
}

const selectionFailedDispatch=()=>{
    return {
        type:'APPLICANT_NOT_SELECTED'
    }
}