import axios from 'axios'
import Cookies from 'universal-cookie';
import {api_base_url} from "../../index";
import {profileDispatch} from "./profile";
import {setLoading, showToast} from "../../App";
const cookies = new Cookies();

const COOKIE_AGE=31536000

export const checkAuthApplicant=(dispatcher)=>{
    if(cookies.get('token')==undefined || cookies.get('token')==null)
        dispatcher(logoutDispatch())
    else
        dispatcher(loginDispatch())
}

export const loginApplicant=(data,dispatcher)=>{
    dispatcher(loadingDispatch())
    axios.post(api_base_url+'/applicant/auth/login',data).then(res=>{  //dummy URL currently
        cookies.set('token',res.data.access_token,{ path: '/', maxAge: COOKIE_AGE }) //setting token
        checkAuthApplicant(dispatcher)
        console.log(res.data)
    }).catch(err=>{
        console.log(err)
        switch(err.response.status){
            case 404:
                showToast('No user exists with this email')
                break
            case 401:
                showToast('Invalid password')
                break
            case 500:
                showToast('Internal server error')
                break
            default:
                showToast('Connectvity problem')
        }
        checkAuthApplicant(dispatcher)
    })
}

export const registerApplicant=(data,dispatcher,successCallback)=> {
    dispatcher(loadingDispatch())
    console.log("########req data########")
    console.log(data)
    axios.post(api_base_url+'/applicant/auth/registration',data).then(res=>{  //dummy URL currently
        console.log(res.data)
        successCallback()
        checkAuthApplicant(dispatcher)
    }).catch(err=>{
        switch(err.response.status){
            case 409:
                showToast('User already exists')
                break
            case 500:
                showToast('Internal server error')
                break
            default:
                showToast('Connectvity problem')
        }
        checkAuthApplicant(dispatcher)
    })
}

export const requestForgotEmail=async email=>{
    try{
        const response=await axios.post(`${api_base_url}/applicant/auth/requestForgotPassword`,{
            login:email
        })
        return response.data
    }catch(e){
        return false
    }
}

export const confirmForgotEmail=async (email,code,password)=>{
    try{
        const response=await axios.post(`${api_base_url}/applicant/auth/forgotPassword`,{
            "login": email,
            "OTP": code,
            "new_password": password
        })
        return true
    }catch(e){
        return false
    }
}

export const changePassword=(data,dispatcher)=> {
    setLoading(true)
    console.log(data)
    axios.put(api_base_url+'/applicant/profile/changepassword',data,{headers:{authorization:'Bearer '+cookies.get('token')}}).then(res=>{  //dummy URL currently
        logout(dispatcher)
        setLoading(false)
        showToast('Password updated successfully, please login with the new password.')
    }).catch(err=>{
        setLoading(false)
        switch(err.response.status){
            case 401:
                showToast('Incorrect old password')
                break
            case 404:
                showToast('User not found')
                break
            case 500:
                showToast('Internal server error')
                break
            default:
                showToast('Connectvity problem')
        }
    })
}

export const verify=(token,successCallback,failureCallback)=>{
    setLoading(true)
    axios.post(`${api_base_url}/applicant/auth/verify/${token}`).then(res=>{
        console.log(res.data)
        successCallback()
        setLoading(false)
    }).catch(err=>{
        console.log(err)
        console.log(err.response.status)
        setLoading(false)
        failureCallback()
    })
}



export const logout=(dispatcher)=>{
    dispatcher(profileDispatch(null))
    cookies.remove('token',{ path: '/' })
    checkAuthApplicant(dispatcher)
}



const loginDispatch=()=>{
    return {
        type:'APPLICANT_SIGNED_IN'
    }
}
const logoutDispatch=()=>{
    return {
        type:'APPLICANT_SIGNED_OUT'
    }
}
const loadingDispatch=()=>{
    return {
        type:'APPLICANT_AUTH_LOADING'
    }
}
