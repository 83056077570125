import axios from 'axios'
import Cookies from 'universal-cookie';
import {api_base_url} from "../../../index";
import { fetchCircularList } from './circularList';
import {showToast} from "../../../App"
const cookies = new Cookies();

export const update =(data,dispatcher)=>{

    dispatcher(loadingDispatch())

    axios.put(api_base_url+'/admin/publish/circular/update',data,{headers:{authorization:'Bearer '+cookies.get('admin_token')}}).then(res=>{  
       dispatcher(updateCircularDispatch());
       showToast("Successfully updated the circular");

       fetchCircularList(dispatcher);
       
    }).catch(err=>{
        if(err.response!==undefined){
        switch(err.response.status){
            case 404:
                showToast('Circular does not exist!')
                break
        
            case 500:
                showToast('Internal server error')
                break
            default:
                showToast('Unknown error');
                break;
        }}
        else{
            showToast('Connectivity problem');
        }
        dispatcher(updateFailedDispatch());

    })


}

const loadingDispatch=()=>{
    return {
        type:'CIRCULAR_UPDATE_LOADING'
    }
}
const updateCircularDispatch=()=>{
     return {
        type:'CIRCULAR_UPDATED'
    }
}

const updateFailedDispatch=()=>{
    return {
        type:'CIRCULAR_NOT_UPDATED'
    }
}
