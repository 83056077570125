import React, {useEffect} from 'react'
import {useSelector,useDispatch} from 'react-redux'
import Auth from "./Auth";
import Console from "./Console";
import { checkAuthApplicant} from "../action/applicant/auth";
import Homepage from "./Homepage/Homepage";

const Dashboard=props=>{

    const applicantAuth=useSelector(state=>state.applicantAuth)
    const dispatch=useDispatch()

    useEffect(()=>{
        checkAuthApplicant(dispatch)
    },[])

    return (
        <div>
            {
                applicantAuth===1?(
                    <Console history={props.history}/>
                ):(
                    <Homepage applicantAuth history={props.history}/>
                )
            }
        </div>
    );
}

export default Dashboard
