import axios from 'axios'
import Cookies from 'universal-cookie';
import {api_base_url} from "../../index";
import {logout} from "./auth";
import {setLoading, showToast} from "../../App";

const cookies = new Cookies();

export const fetchProfile=(dispatcher)=>{
    setLoading(true)
    axios.get(api_base_url+'/applicant/profile',{headers:{authorization:'Bearer '+cookies.get('token')}}).then(res=>{
        setLoading(false)
        dispatcher(profileDispatch(res.data))
        console.log(res.data)
    }).catch(err=>{
        setLoading(false)
        if(err!==undefined && err.response!==undefined && err.response.status!==undefined && (err.response.status===401 || err.response.status===403)){
            logout(dispatcher)
        }else{
            showToast('Connectvity problem')
        }
    })
}

export const updateProfile=(dispatcher,data)=>{
    setLoading(true)
    axios.put(api_base_url+'/applicant/profile',data,{headers:{authorization:'Bearer '+cookies.get('token')}}).then(res=>{
        setLoading(false)
        dispatcher(profileDispatch(data))
        showToast('Profile Updated Successfully')
    }).catch(err=>{
        setLoading(false)
        if(err!==undefined && err.response!==undefined && err.response.status!==undefined && (err.response.status===401 || err.response.status===403)){
            logout(dispatcher)
        }else{
            showToast('Connectvity problem')
        }
    })
}

export const uploadImage=(dispatcher,file,profile)=>{
    setLoading(true)
    const data = new FormData()
    data.append('file', file, file.name)
    axios.post(api_base_url+'/file/upload',data,{
        headers: {
            'Content-Type': 'multipart/form-data',
            authorization:'Bearer '+cookies.get('token')
        }
    }).then(res => {
        setLoading(false)
        var updatedProfile={...profile}
        updatedProfile['image']=res.data.url
        updateProfile(dispatcher,updatedProfile)
        showToast('Image updated successfully');
    }).catch(err=>{
        setLoading(false)
        if(err!==undefined && err.response!==undefined && err.response.status!==undefined && (err.response.status===401 || err.response.status===403)){
            logout(dispatcher)
        }else{
            showToast('Connectvity problem')
        }
    })
}

export const profileDispatch=data=>{
    return{
        type:'APPLICANT_PROFILE_UPDATE',
        data:data
    }
}
