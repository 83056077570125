import React, {useEffect} from 'react'
import {showToast} from "../App";
import { useHistory } from "react-router-dom";
import {BrowserRouter,Route} from 'react-router-dom';

import Dashboard from "./Dashboard";
import Landing from "./Homepage/Homepage";
import Auth from "./Auth";

const ApplicantHome=props=>{

    const history=useHistory()

    return (
        <BrowserRouter history={props.history}>
            <Route path = "/" exact component={Landing}/>
            <Route path = "/dashboard" component={Dashboard}/>

        </BrowserRouter>
    );
}

export default ApplicantHome;

