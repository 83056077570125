import axios from 'axios'
import Cookies from 'universal-cookie';
import {api_base_url} from "../../../index";

import {showToast} from "../../../App"
import { fetchPostList } from './postList';
const cookies = new Cookies();

export const update =(data,dispatcher)=>{

    dispatcher(loadingDispatch())

    axios.put(api_base_url+'/admin/publish/post',data,{headers:{authorization:'Bearer '+cookies.get('admin_token')}}).then(res=>{  
       dispatcher(updatePostDispatch());
       showToast("Successfully updated the post");

       fetchPostList(dispatcher)
       
    }).catch(err=>{
        if(err.response!==undefined){
        switch(err.response.status){
            case 404:
                showToast('Post does not exist!')
                break
        
            case 500:
                showToast('Internal server error')
                break
            default:
                showToast('Unknown error');
                break;
        }}
        else{
            showToast('Connectivity problem');
        }
        dispatcher(updateFailedDispatch());

    })


}

const loadingDispatch=()=>{
    return {
        type:'POST_UPDATE_LOADING'
    }
}
const updatePostDispatch=()=>{
     return {
        type:'POST_UPDATED'
    }
}

const updateFailedDispatch=()=>{
    return {
        type:'POST_NOT_UPDATED'
    }
}
