import React, {useEffect, useRef, useState} from 'react'
import HomeAppBar from "./HomeAppBar";
import {useSelector,useDispatch} from 'react-redux'
import LinearProgress from "@material-ui/core/LinearProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import EmailIcon from '@material-ui/icons/Email';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import {showToast} from "../App";
import ReCAPTCHA from "react-google-recaptcha";
import {login, loginApplicant, register, registerApplicant} from "../action/applicant/auth";
import HomeIcon from '@material-ui/icons/Home';
import uuid from 'react-uuid'

import axios from 'axios'
import {api_base_url} from "../index";
import {DialogActions} from "@material-ui/core";
import {validateEmail} from "../util";
import Grid from "@material-ui/core/Grid";


const Auth=props=>{

    const applicantAuth=useSelector(state=>state.applicantAuth)
    const dispatch=useDispatch()

    const emailRef=useRef();
    const passwordRef=useRef();
    const nameRef=useRef();
    const repasswordRef=useRef();






    const [regCaptcha, setRegCaptcha]=useState('')
    const [loginCaptcha, setLoginCaptcha]=useState('')

    const [verifyDialog,setVerifyDialog]=useState(false)

    function onRegCaptchaChange(value) {
        setRegCaptcha(value)
    }

    function onLoginCaptchaChange(value) {
        setLoginCaptcha(value)
    }

    const [dialogState,setDialogState]=useState(2)

    const validatePhone=phone=>{
        // if(phone.length!==11)
        //     return false
        // if(phone.substr(0,2)!=='01')
        //     return false
        return true
    }

    const clearTextFields=()=>{
        emailRef.current.value=""
        nameRef.current.value=""
        passwordRef.current.value=""
        repasswordRef.current.value=""
    }

    const loginClick=()=>{
        const email=emailRef.current.value
        const password=passwordRef.current.value
        if(!validateEmail(email))
            showToast('Please enter a valid email address')
        else if (password.length<6)
            showToast('Password length must be greater or equals to 6')
        else{
            loginApplicant({
                login:email,
                password:password,
                type:'applicant',
                captcha:loginCaptcha
            },dispatch)
        }
    }

    const regSuccess=()=>{
        showToast('Registration successful ! Now verify your new account')
        setDialogState(2)
        setVerifyDialog(true)
    }

    const regClick=()=>{
        const email=emailRef.current.value
        const password=passwordRef.current.value
        const name=nameRef.current.value
        const repass=repasswordRef.current.value
        if(name.length===0)
            showToast('Please enter a name')
        else if(!validateEmail(email))
            showToast('Please enter a valid email address')
        else if (password.length<6)
            showToast('Password length must be greater or equals to 6')
        else if(password!==repass)
            showToast('Please re enter password correctly')
        else{
            registerApplicant({
                name:name,
                password:password,
                login:email,
                captcha:regCaptcha
            },dispatch,regSuccess)
        }
    }

    const homeClick=()=>{
        props.history.push('/')
    }


    return (
        <div>
            
            
            <Dialog open={true} aria-labelledby="form-dialog-title">
                {
                    applicantAuth===0?(
                        <LinearProgress />
                    ):(
                        <div/>
                    )
                }
                <DialogTitle id="form-dialog-title">
                    {
                        dialogState===1?(<div>Register</div>):(dialogState===2?(<div>Login</div>):(<div>Enter OTP</div>))
                    }
                </DialogTitle>
                <DialogContent >
                    {
                        dialogState==1?(
                            <div >
                                <TextField
                                    inputRef={nameRef}
                                    style={{marginTop:'12px'}}
                                    margin="dense"
                                    label="Name"
                                    variant="outlined"
                                    autoComplete='off'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircleIcon color='primary'/>
                                            </InputAdornment>
                                        ),
                                        style: {
                                            padding: 2
                                        }
                                    }}
                            /><br/></div>
                        ):(
                            <div/>
                        )
                    }
                    {
                        dialogState<3 || dialogState===4?(
                            <TextField

                                inputRef={emailRef}
                                style={{marginTop:'12px'}}
                                margin="dense"
                                label="Email Address"
                                variant="outlined"
                                autoComplete='off'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <EmailIcon color='primary'/>
                                        </InputAdornment>
                                    ),
                                    style: {
                                        padding: 2
                                    },
                                    autocomplete: 'off',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                            />
                        ):(
                            <div/>
                        )
                    }
                        <br/>

                    {
                        dialogState<3?(
                            <TextField

                                inputRef={passwordRef}
                                style={{marginTop:'12px'}}
                                margin="dense"
                                label="Password"
                                type="password"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockIcon color='primary'/>
                                        </InputAdornment>
                                    ),
                                    style: {
                                        padding: 2
                                    },
                                    autocomplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                            />
                        ):(
                            <div/>
                        )
                    }

                    <br/>

                    {
                        dialogState==1?(
                            <TextField

                                inputRef={repasswordRef}
                                style={{marginTop:'12px'}}
                                margin="dense"
                                label="Retype Password"
                                type="password"
                                variant="outlined"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LockOpenIcon color='primary'/>
                                        </InputAdornment>
                                    ),
                                    style: {
                                        padding: 2
                                    },
                                    autocomplete: 'new-password',
                                    form: {
                                        autocomplete: 'off',
                                    },
                                }}
                            />
                        ):(
                            <div/>
                        )
                    }
                    {
                        dialogState==2?(
                            <Button color={'primary'}>
                                Forgot Password ?
                            </Button>
                        ):(
                            <div/>
                        )
                    }

                    <Divider style={{marginTop:'5px'}}/>

                    {
                        dialogState==1?(
                            <div>
                                <center>

                                    {/*<ReCAPTCHA
                                        sitekey="6LejnMIcAAAAAK7JEhofX7c-fedw58BI-AnTYz2u"
                                        onChange={onRegCaptchaChange}
                                    />*/}
                                    <Button  disabled={applicantAuth===0} onClick={regClick}  style={{marginTop:'8px'}} variant="outlined" color="primary">
                                        Register
                                    </Button>
                                </center>
                                <center style={{marginTop:'8px'}}>or Already Have An Account ?</center>
                                <center><Button  disabled={applicantAuth===0}  onClick={()=>{setDialogState(2)}} style={{marginTop:'8px',marginBottom:'8px'}} variant="outlined" color="secondary" >
                                    Login to existing account
                                </Button></center>
                            </div>
                        ):dialogState==2?
                            (
                            <div>
                                <center>
                                    {/*<ReCAPTCHA
                                        sitekey="6LejnMIcAAAAAK7JEhofX7c-fedw58BI-AnTYz2u"
                                        onChange={onLoginCaptchaChange}
                                    />*/}
                                    <Button  disabled={applicantAuth===0} onClick={loginClick}  style={{marginTop:'8px'}} variant="outlined" color="primary">
                                     Login
                                    </Button>
                                </center>
                                <center style={{marginTop:'8px'}}>or Need An Account ?</center>
                                <center><Button  disabled={applicantAuth===0}  onClick={()=>{setDialogState(1)}} style={{marginTop:'8px',marginBottom:'8px'}} variant="outlined" color="secondary" >
                                    Create A New Account
                                </Button></center>
                            </div>
                            ):(
                                <div/>
                        )
                    }

                    <Divider style={{marginBottom:'5px',marginTop:'5px'}}/>

                    <center>
                        <center>
                            <Button  disabled={applicantAuth===0} onClick={homeClick} startIcon={<HomeIcon/>}  style={{marginTop:'8px',marginBottom:'8px'}} variant="outlined" >
                                Return to home
                            </Button>
                        </center>
                    </center>

                </DialogContent>

            </Dialog>

            <Dialog open={verifyDialog} aria-labelledby="form-dialog-title">
                <DialogTitle>
                    Email Verification
                </DialogTitle>
                <DialogContent>
                    Please check your inbox (also spam), we've sent a verification link there, after successful verification, you can log in to your newly created account.
                </DialogContent>
                <DialogActions>
                    <Button color={'primary'} onClick={()=>{setVerifyDialog(false)}}>Close</Button>
                </DialogActions>
            </Dialog>
            
            
            

        </div>
    );
}


export default Auth
