import React,{useState,useEffect} from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import Grid from "@material-ui/core/Grid"
import IconButton from '@material-ui/core/IconButton'
import Modal from '@material-ui/core/Modal';
import Button from "@material-ui/core/Button";
import DeleteIcon from '@material-ui/icons/Delete';
import ModalBody from "../modals/ModalBody";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import {deleteScrutineer} from "../../action/admin/deleteScrutineer"
import DialogTitle from '@material-ui/core/DialogTitle';
import {useSelector,useDispatch} from 'react-redux'
import LinearProgress from "@material-ui/core/LinearProgress";
import { showToast } from "../../App";
import { fetchScrutineerList } from "../../action/admin/scrutineerList";
const useStyles = makeStyles((theme) => ({
    root: {
    display:"flex",
    marginLeft:220,
    height:150,
    marginTop:60,
    marginBottom:50,
  
    
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
   
    card:{
      width:'85%',
      
      marginLeft:60,
      marginBottom:30,
      marginTop:20,
      '&:hover': {
        backgroundColor: '#87CEFA',
      },
    }
    ,
    
   
    }));
   

 

const ScrutineerList = (props) => {
    const classes=useStyles()

    const [open,setOpen]=useState(false);
    const [renderedData,setRenderedData]=useState([])
    const [openDialog, setOpenDialog] = useState(false);
    const [deleteId,setId]=useState(0);

    const deletionState=useSelector(state=>state.scrutineerDelete)
 
   
    const scrutineerList =useSelector(state=>state.scrutineerList);

    const dispatch=useDispatch()    

   

    useEffect(()=>{
     
   let data=scrutineerList.filter(scrutiny=>{
     if(scrutiny.name.toLowerCase().includes(props.text.toLowerCase())){
       return scrutiny;
     }
   })
   setRenderedData(data)
  },[props.text,scrutineerList]) 

  
 const deleteClick=(id)=>{
setId(id);
setOpenDialog(true);
  }

  const yesClick=()=>{
 
     deleteScrutineer(deleteId,dispatch,showToast);
    
    setOpenDialog(false);
  

  }
  
 
    const createClick=()=>{


setOpen(true)

    }
    const handleClose=()=>{
        setOpen(false)
    }
    return (
      <div  className={classes.root}>

      <Grid container >
      <Grid item xs={12} md={3} >
      <h2 style={{marginLeft:60}}>Scrutineer List</h2>  
      </Grid> 
      <Grid item xs={12} md={9}  style={{marginTop:10}}>
      <Button  onClick={createClick} startIcon={<ControlPointIcon/>} style={{marginTop:'10px'}}  color='primary' variant='outlined'>
                                    Create
                                </Button>
      </Grid>
      {
       renderedData.length===0?(
        <Grid item xs={12}> 
          <h2 style={{marginLeft:20}} >There is no scrutineer right now</h2>
        </Grid>):null
      }
{renderedData && renderedData.map(scrutiny=>(
  <Grid key={scrutiny.scrutiny_id} item xs={12} md={6} >
    <Card   className={classes.card}>
    <CardHeader
       avatar={
        <Avatar style={{ height: '50px', width: '50px' }} alt={scrutiny.name} src={scrutiny.image===null?scrutiny.name[0]:scrutiny.image} />
        
      }
      title={<h3>{scrutiny.name} </h3>}
      subheader={'Created by : '+scrutiny.admin_name}
    />
    <div style={{display:"block",marginLeft:70 }}>
    <div >  Department : {scrutiny.department} </div>
    <div style={{marginTop:10}}>Designation : {scrutiny.designation}</div>

    {scrutiny.verified?  <div style={{marginTop:10,color:'green'}}>VERIFIED</div>:(
  <div style={{marginTop:10,color:'red'}}>NOT VERIFIED</div>

    )}
    </div>

      
<CardContent className={classes.content}>


 <IconButton aria-label="delete" onClick={()=>deleteClick(scrutiny.scrutiny_id)}>
 <DeleteIcon color="primary"/>
 </IconButton>


</CardContent>
    </Card>
 
</Grid>

))}




</Grid>

<Dialog
        open={openDialog}
        onClose={()=>setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {deletionState===0?<LinearProgress/>:null}
        <DialogTitle id="alert-dialog-title">{"Do you want to delete this scrutineer?"}</DialogTitle>
      
        <DialogActions>
          <Button onClick={()=>setOpenDialog(false)} color="primary">
            No
          </Button>
          <Button onClick={yesClick} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>



<Modal
className={classes.modal}
 open={open}
 onClose={handleClose}
 aria-labelledby="edit-modal"
 aria-describedby="edit-modal"
>

<ModalBody text ={'Create'} method={setOpen} />

</Modal>


       </div>


     );
}
 
export default ScrutineerList;
