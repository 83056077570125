import React, {useEffect} from 'react'
import Avatar from "@material-ui/core/Avatar";
import {Button} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {useDispatch, useSelector} from "react-redux";
import makeStyles from "@material-ui/core/styles/makeStyles";
import deepPurple from "@material-ui/core/colors/deepPurple";
import {showToast} from "../App";
import { ImagePicker } from 'react-file-picker'
import {dataURLtoFile} from "../util";
import {uploadImage} from "../action/applicant/profile";


const useStyles = makeStyles((theme) => ({
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },
}));

const ProfileImage=props=>{

    const classes = useStyles();
    const applicantProfile=useSelector(state=>state.applicantProfile)
    const dispatch=useDispatch()

    return(
        <center>
            <Avatar src={applicantProfile.image} style={{width:'80px',height:'80px',marginTop:'20px'}} className={classes.purple}>
                {applicantProfile.name.substr(0,1)}
            </Avatar>
            <ImagePicker
                extensions={['jpg', 'jpeg', 'png']}
                dims={{minWidth: 100, minHeight: 100}}
                onChange={base64 =>{
                    uploadImage(dispatch,dataURLtoFile(base64,'image.'+base64.substring("data:image/".length, base64.indexOf(";base64"))),applicantProfile)
                }}
                onError={errMsg => {
                    showToast(errMsg)
                }}
            >
                <Button
                    style={{marginTop:'10px'}}
                    variant={'outlined'}
                    startIcon={<EditIcon/>}
                >
                    Update Image
                </Button>
            </ImagePicker>
        </center>
    )
}

export default ProfileImage
