import React,{useState,useRef} from "react"
import { TextField } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button"
import {useSelector,useDispatch} from 'react-redux'
import { showToast } from "../../App";
import {create} from "../../action/admin/post/createPost"
import LinearProgress from "@material-ui/core/LinearProgress";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Grid from "@material-ui/core/Grid"
import { update } from "../../action/admin/post/updatePost";
const useStyles = makeStyles((theme) => ({


    textField:{
      width:'85%',
        marginTop:30,
        marginLeft:40
        
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
      },
   
    paper: {
        position: 'absolute',
        width: 708,
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #000',
        
        borderRadius:20,
        padding: theme.spacing(2, 4, 3),
      },
      button:{
        marginTop:30
    },
    input: {
      display: 'none',
  },
  img: {
      marginTop: 2,
      height:400,
      width:400
  }
}))

const PostModalBody = (props) => {

    const classes=useStyles()
    const dispatch=useDispatch();
    const adminPostCreation=useSelector(state=>state.adminPostCreation);
  const  adminPostUpdateState=useSelector(state=>state.adminPostUpdate)
    const titleRef=useRef()
    const circularTextRef=useRef()
    const salaryScaleRef=useRef()
    const quantityRef=useRef()
    const eduReqRef=useRef()
    const expReqRef=useRef()
    const restrictionRef=useRef()
    const extraRef=useRef()
const submitClick=()=>{
  if(props.val==='' && !props.post){
    showToast('You must choose a circular')
  }
  else{
const data={
    title:titleRef.current.value,
    post_id:props.post?props.post.post_id:'',
    circular_id:props.post?props.post.circular_id:props.val,
    circular_text:circularTextRef.current.value,
    salary_scale:salaryScaleRef.current.value,
    quantity:quantityRef.current.value,
    edu_req:eduReqRef.current.value,
    exp_req:expReqRef.current.value,
    restriction:restrictionRef.current.value,
    extra_info:extraRef.current.value,
    


    
}
if(props.post){

update(data,dispatch)
}
else
create(data,dispatch,showToast);}
}
const resetClick=()=>{
  titleRef.current.value=''
  circularTextRef.current.value=''
  salaryScaleRef.current.value=''
  quantityRef.current.value=''
  eduReqRef.current.value=''
  expReqRef.current.value=''
  restrictionRef.current.value=''
  
  extraRef.current.value=''

}
    return ( 
        <div  className={classes.paper}>
 
      { (adminPostCreation===0 ||  adminPostUpdateState===0 )?<LinearProgress/>:null}
            <Grid container >
         <Grid item xs={12} > <h2>{props.text} Post</h2></Grid>
        <Grid item  xs={6} > 
      <TextField
       inputRef={titleRef}
      className={classes.textField}
      variant="outlined"
      defaultValue={props.post?props.post.title:''}
      label="Title"
      fullWidth
  
      
      
      
      />
      </Grid>
      
       
      <Grid item  xs={6}> 
      <TextField
       inputRef={circularTextRef}
      className={classes.textField}
      variant="outlined"
      defaultValue={props.post?props.post.circular_text:''}
      label="Circular Text"
    
      fullWidth
      
      
      />
      </Grid>
      
      
      <Grid item xs={6}> 
      <TextField
      inputRef={salaryScaleRef}
      className={classes.textField}
      variant="outlined"
      defaultValue={props.post?props.post.salary_scale:''}
      label="Salary Scale"
      fullWidth
      
      
      />
      </Grid>
      <Grid item xs={6}> 
      <TextField
    inputRef={quantityRef}
      className={classes.textField}
      variant="outlined"
      label="Quantity"
      defaultValue={props.post?props.post.quantity:''}
      fullWidth
      
      
      />
      </Grid>
      <Grid item  xs={6}> 
      <TextField
        multiline
        rows={1}
        rowsMax={5}
      inputRef={eduReqRef}
      className={classes.textField}
      variant="outlined"
      label="Education Required"
      defaultValue={props.post?props.post.edu_req:''}
      fullWidth
      
      
      />
          </Grid>
       <Grid item  xs={6}> 
       
      <TextField
      multiline
      rows={1}
      rowsMax={5}
      inputRef={expReqRef}
      className={classes.textField}
      variant="outlined"
      label="Experience Required"
      defaultValue={props.post?props.post.exp_req:''}
      fullWidth
      
      
      />
  
      </Grid>
      <Grid item xs={6}> 
      <TextField
      multiline
      rows={1}
      rowsMax={5}
      inputRef={restrictionRef}
      className={classes.textField}
      variant="outlined"
      label="Restriction"
      defaultValue={props.post?props.post.restriction:''}
      fullWidth
      
      
      />
  
      </Grid>
      <Grid item  xs={6}> 
      <TextField
      multiline
      rows={1}
      rowsMax={5}
      inputRef={extraRef}
      className={classes.textField}
      variant="outlined"
      label="Extra Info"
      defaultValue={props.post?props.post.extra_info:''}
      fullWidth
      
      
      />
  
      </Grid>
       
                  <Grid item xs={12} md={6} >
       <Button onClick={submitClick} style={{marginTop:40,marginLeft: 45}} className={classes.button} variant="contained" color="primary">
       Submit
      </Button>
      
      
         <Button  onClick={resetClick} style={{marginTop:35,marginLeft: 45}} variant="contained">Reset</Button>
      
         </Grid>
         <Grid item xs={12} md={6}>
         <Button onClick={()=>props.method(false)} style={{marginTop:35,marginLeft: 45}} color="primary" variant="contained">Close</Button>
      </Grid>
      
      </Grid>
      
      </div>
     );
}
 
export default PostModalBody;