import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Button, Grid} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';
import {ImagePicker} from "react-file-picker";
import {uploadImage} from "../action/applicant/profile";
import {dataURLtoFile} from "../util";
import {showToast} from "../App";
import { FilePicker } from 'react-file-picker'
import {uploadFile} from "../action/applicant/edu";

const Certificate=forwardRef((props,ref)=>{

    const subjectRef=useRef()
    const insRef=useRef()
    const boardRef=useRef()
    const yearRef=useRef()
    const gradeRef=useRef()

    const [file,setFile]=useState(null)
    const [link,setLink]=useState(null)

    useImperativeHandle(ref, () => ({
        getData(){
            var data={
                type:props.type,
                subject:subjectRef.current.value,
                institute:insRef.current.value,
                board:boardRef.current.value,
                grade:gradeRef.current.value,
                file:link===null?props.data(props.type).file:link
            }
            if(isNaN(parseInt(yearRef.current.value)))data["year"]=0
            else data["year"]=parseInt(yearRef.current.value)
            return data
        },
        saveDone(){
            setFile(null)
            setLink(null)
        }
    }));

    return(
        <Grid container spacing={2}>
            <Grid style={{fontSize:'1.2em'}} item xs={12}>
                {props.title}
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    defaultValue={props.data(props.type).subject}
                    inputRef={subjectRef}
                    variant={'outlined'}
                    label={'Subject'}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    defaultValue={props.data(props.type).institute}
                    fullWidth
                    inputRef={insRef}
                    variant={'outlined'}
                    label={'Institution'}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    defaultValue={props.data(props.type).board}
                    fullWidth
                    inputRef={boardRef}
                    variant={'outlined'}
                    label={'Board/University'}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    defaultValue={props.data(props.type).year}
                    inputRef={yearRef}
                    type={'number'}
                    fullWidth
                    variant={'outlined'}
                    label={'Year'}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    defaultValue={props.data(props.type).grade}
                    inputRef={gradeRef}
                    fullWidth
                    variant={'outlined'}
                    label={'Grade/GPA'}
                />
            </Grid>
            <Grid item xs={12}>
                <center>
                    {
                        props.data(props.type).file!==null && props.data(props.type).file.length>0?(
                            <div style={{marginBottom:'5px'}}>
                                <a target="_blank" href={props.data(props.type).file}>
                                    <u>Currently Uploaded File</u>
                                </a>
                            </div>
                        ):(
                            <div/>
                        )
                    }
                    <FilePicker
    
                        extensions={['pdf']}
                        onChange={file =>{
                            uploadFile(file).then(url=>{
                                setLink(url)
                            })
                            setFile(file)
                        }}
                        onError={errMsg => {
                            showToast(errMsg)
                        }}
                    >
                        <Button
                            variant={'outlined'}
                            color='primary'
                            startIcon={<PublishIcon/>}>
                            Upload New PDF
                        </Button>
                    </FilePicker>
                    {
                        file!==null?(
                            <div style={{marginTop:'5px'}}>
                                {file.name} <u style={{color:'#bb0000',cursor:'pointer'}} onClick={()=>{setFile(null);setLink(null);}}>remove</u>
                            </div>
                        ):(
                            <div/>
                        )
                    }
                </center>
            </Grid>
        </Grid>
    )
})

export default Certificate
