import axios from 'axios'
import Cookies from 'universal-cookie';
import {api_base_url} from "../../index";
import {logout} from "./auth"
import {showToast} from "../../App";


const cookies = new Cookies();

export const fetchScrutineerData=(dispatcher)=>{
    axios.get(api_base_url+'/scrutiny/profile',{headers:{authorization:'Bearer '+cookies.get('scrutiny_token')}}).then(res=>{
        dispatcher(scrutineerDataDispatch(res.data.data[0]))
    }).catch(err=>{
        if(err.response!==undefined){
        switch(err.response.status){
         

    
            case 500:
                showToast('Internal server error')
                break
            default:
                showToast('Unknown error');
                break;
        }
    }else{
        showToast('Unknown error');  
        
    }
        
    })
}
export const scrutineerDataDispatch=data=>{
    return{
        type:'SCRUTINEER_DATA',
        data:data
    }
}
