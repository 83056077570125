import axios from 'axios'
import Cookies from 'universal-cookie';
import {api_base_url} from "../../../index";
import { fetchPostList } from './postList';


const cookies = new Cookies();

export const create=(data,dispatcher,callBack)=>{

    dispatcher(loadingDispatch())

    axios.post(api_base_url+'/admin/publish/post',data,{headers:{authorization:'Bearer '+cookies.get('admin_token')}}).then(res=>{  
       dispatcher(createPostDispatch());
       callBack("Successfully created a post");
       fetchPostList(dispatcher)

    }).catch(err=>{
        if(err.response!==undefined){
        switch(err.response.status){
            case 409:
                callBack('Post already exists!')
                break
        
            case 500:
                callBack('Internal server error')
                break
            default:
                callBack('Unknown error');
                break;
        }}
        else{
            callBack('Unknown error');
        }
        dispatcher(creationFailedDispatch());

    })


}

const loadingDispatch=()=>{
    return {
        type:'POST_CREATION_LOADING'
    }
}
const createPostDispatch=()=>{
     return {
        type:'POST_CREATED'
    }
}

const creationFailedDispatch=()=>{
    return {
        type:'POST_NOT_CREATED'
    }
}
