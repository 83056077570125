const scrutineerDataReducer=(state=null,action)=>{
    switch(action.type){
        case 'SCRUTINEER_DATA':
            return action.data;
        default:
            return state;
    }
}

export default scrutineerDataReducer;
