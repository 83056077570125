import React, {useEffect, useState} from 'react'
import "./Circulars.css"
import CircularList from "../CircularList";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useDispatch} from "react-redux";
import {fetchCirculars} from "../../action/applicant/application";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    containerRoot:{
        flexGrow:1,
        display:"flex",
        padding: theme.spacing(1),
        justifyContent:"center",
        alignItems:"center"
       

    }
}))

const Circulars = () => {

    const classes = useStyles()

    const dispatch=useDispatch()
    const [circulars,setCirculars]=useState(null)

    const loadCirculars=body=>{
        setCirculars(body.data)
    }

    useEffect(()=>{
        fetchCirculars(dispatch,loadCirculars)
    },[])

  return (
      <div style={{padding:'10px'}}>
          <h2 id="circ" style={{marginLeft:'5px',marginTop:"100px"}}>
              Active Circulars
          </h2>
          <div className="cont">
          <div className={classes.containerRoot}>
              {
                  circulars===null?(
                      <LinearProgress/>
                  ):(
                      <Grid direction='row' alignItems="center"  justify="center" container spacing={1}>
                          {
                              circulars.map(circular=>{
                                  return(
                                      <Grid  style={{minHeight:'100%'}} item xs={6} md={3} lg={2}>
                                          <a  href={'/dashboard/circular/'+circular.circular_id}>
                                              <Card style={{height:'100%'}} className={classes.root}>
                                                  <CardActionArea>
                                                      <center>
                                                          <img src={'buet_logo.png'} class="circular_logo"/>
                                                      </center>
                                                      <CardContent>
                                                          <Typography gutterBottom variant="h5" component="h2">
                                                              {circular.title}
                                                          </Typography>
                                                          <Typography variant="body2" color="textSecondary" component="p">
                                                              Published on : {circular.published}<br/>
                                                              Application deadline : {circular.deadline}
                                                          </Typography>
                                                      </CardContent>
                                                  </CardActionArea>
                                              </Card>
                                          </a>
                                      </Grid>
                                  )
                              })
                          }
                      </Grid>
                  )
              }
          </div>
          </div>

      </div>
  )
}

export default Circulars
