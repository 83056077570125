import React, {useRef} from 'react'
import {Grid} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {useDispatch, useSelector} from "react-redux";
import LinearProgress from "@material-ui/core/LinearProgress";
import {updateProfile} from "../action/scrutiny/profile";

const EditProfile=props=>{

    const scrutineerProfile=useSelector(state=>state.scrutineerProfile)
    const dispatch=useDispatch()

    const nameRef=useRef()
    const deptRef=useRef()
    const desRef=useRef()


    const saveClick=()=>{
        var profile={
            name:nameRef.current.value,
            department:deptRef.current.value,
            designation:desRef.current.value,
            image:scrutineerProfile.image
        }
        updateProfile(dispatch,profile)
    }

    return(
        <div>
            {
                scrutineerProfile===null?(
                    <div/>
                ):(
                    <Paper style={{'padding':'20px'}}>
                        <div style={{fontSize:'1.5em',marginBottom:'10px'}}>
                            Edit Profile
                        </div>
                        <div style={{position:'absolute',right:'20px',top:'73px'}}>
                            <Button
                                variant='outlined'
                                color={'primary'}
                                onClick={saveClick}
                            >
                                Save Changes
                            </Button>
                        </div>
                        <Grid container spacing={2} >
                            <Grid item xs={12} md={4}>
                                <TextField
                                    required
                                    defaultValue={scrutineerProfile.name}
                                    inputRef={nameRef}
                                    variant={'outlined'}
                                    label={'Your Name'}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    defaultValue={scrutineerProfile.department}
                                    inputRef={deptRef}
                                    variant={'outlined'}
                                    label={'Department'}
                                    fullWidth/>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField
                                    defaultValue={scrutineerProfile.designation}
                                    inputRef={desRef}
                                    variant={'outlined'}
                                    label={'Designation'}
                                    fullWidth/>
                            </Grid>
                        </Grid>
                    </Paper>
                )
            }
        </div>
    )
}

export default EditProfile
