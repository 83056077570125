import axios from 'axios'
import Cookies from 'universal-cookie';
import {api_base_url} from "../../index";
import { fetchScrutineerList } from "./scrutineerList";

const cookies = new Cookies();

export const create=(data,dispatcher,callBack)=>{

    dispatcher(loadingDispatch())

    axios.post(api_base_url+'/admin/scrutiny/create',data,{headers:{authorization:'Bearer '+cookies.get('admin_token')}}).then(res=>{  
       dispatcher(createScrutinyDispatch());
       callBack("Successfully created a scrutineer");
       fetchScrutineerList(dispatcher);

    }).catch(err=>{
        if(err.response!==undefined){
        switch(err.response.status){
            case 409:
                callBack('Scrutineer already exists!')
                break
        
            case 500:
                callBack('Internal server error')
                break
            default:
                callBack('Unknown error');
                break;
        }}
        else{
            callBack('Unknown error');
        }
        dispatcher(creationFailedDispatch());

    })


}

const loadingDispatch=()=>{
    return {
        type:'CREATION_LOADING'
    }
}
const createScrutinyDispatch=()=>{
     return {
        type:'SCRUTINY_CREATED'
    }
}

const creationFailedDispatch=()=>{
    return {
        type:'SCRUTINY_NOT_CREATED'
    }
}
