
import React,{useState,useRef} from "react"
import { TextField } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button"
import {useSelector,useDispatch} from 'react-redux'
import { showToast } from "../../App";
import {create} from "../../action/admin/createScrutiny"
import Grid from "@material-ui/core/Grid"
import {schema} from "../validation/scrutinyValidation"

import LinearProgress from "@material-ui/core/LinearProgress";

  const useStyles = makeStyles((theme) => ({


        textField:{
          width:'85%',
            marginTop:30,
            marginLeft:40
            
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
          },
       
        paper: {
            position: 'absolute',
            width: 708,
            backgroundColor: theme.palette.background.paper,
            border: '1px solid #000',
            
            borderRadius:20,
            padding: theme.spacing(2, 4, 3),
          },
          button:{
            marginTop:30
        },
        input: {
          display: 'none',
      },
      img: {
          marginTop: 2,
          height:400,
          width:400
      }
    }))
const  ModalBody= (props) => {


  
  

  const nameRef=useRef();

  const emailRef=useRef();
  const deptRef=useRef();
  const desgRef=useRef();
  const adminScrutinyCreation=useSelector(state=>state.adminScrutinyCreation);

    const dispatch=useDispatch()
  const classes=useStyles()


  const submitClick=()=>{
console.log(adminScrutinyCreation)
    const nameVal=nameRef.current.value;
    const emailVal= emailRef.current.value;
    const deptVal=deptRef.current.value;
    const desgVal=desgRef.current.value;
if(!nameVal || ! emailVal || !deptVal || !desgVal){
    showToast("All fields are required")

}
    else{
    schema.validate({

        
        
        name:nameVal ,
        email:emailVal,
        department:deptVal,
        designation: desgVal
          })
          .then(function(data){
         console.log(data)
 
                create(
                  data,
                  dispatch,
                  showToast
                )    
           
          })
         .catch(function (err) {
            
             console.log(err.errors)
            showToast(err.errors[0]);
            
      });

    }
   
   
    
   
  }

const resetClick=()=>{

nameRef.current.value='';
emailRef.current.value='';

deptRef.current.value='';
desgRef.current.value='';

}

    return ( 
<div  className={classes.paper}>
  { adminScrutinyCreation===0?<LinearProgress/>:null}

      <Grid container >
   <Grid item xs={12} > <h2>{props.text} Scrutineer Profile</h2></Grid>
  <Grid item xs={12} md={6} > 
<TextField
inputRef={nameRef}
className={classes.textField}
variant="outlined"
label="Full name"
fullWidth
required



/>
</Grid>

 
<Grid item xs={12} md={6}> 
<TextField
inputRef={emailRef}
className={classes.textField}
variant="outlined"
label="Email"
type="email"
required
fullWidth


/>
</Grid>


<Grid item xs={12} md={6}> 
<TextField
 inputRef={deptRef}
className={classes.textField}
variant="outlined"
label="Department"
required
fullWidth


/>
</Grid>
<Grid item xs={12} md={6}> 
<TextField
 inputRef={desgRef}
className={classes.textField}
variant="outlined"
label="Designation"
required
fullWidth


/>
</Grid>
     
            <Grid item xs={12} md={6} >
 <Button  onClick={submitClick} style={{marginTop:40,marginLeft: 45}} className={classes.button} variant="contained" color="primary">
 Submit
</Button>


   <Button onClick={resetClick} style={{marginTop:35,marginLeft: 45}} variant="contained">Reset</Button>

   </Grid>
   <Grid item xs={12} md={6}>
   <Button onClick={()=>props.method(false)} style={{marginTop:35,marginLeft: 45}} color="primary" variant="contained">Close</Button>
</Grid>

</Grid>

</div>
     );
}
 
export default ModalBody ;